import { Grid, useTheme } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import EditorWrapper from "./EditorWrapper";
import { useCallback, useEffect, useMemo, useRef, useState, type CSSProperties } from "react";

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      {
        color: ["white", "grey", "red", "blue", "yellow", "green", "orange"],
      },
    ],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    ["link", "image"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

interface HtmlEditorProps {
  id: string;
  value?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  editorStyle?: CSSProperties;
}

const HtmlEditor = ({ id, value, onChange, placeholder, editorStyle }: HtmlEditorProps) => {
  const theme = useTheme();
  const [content, setContent] = useState<string>(value ?? "");
  const quillRef = useRef<ReactQuill>(null);
  const quill = useMemo(() => quillRef.current?.getEditor(), [quillRef]);

  const setHTMLContent = useCallback(
    (htmlString: string) => {
      setContent(htmlString);
    },
    [setContent],
  );

  const handleChange = useCallback(
    (htmlString: string) => {
      setContent(htmlString);
      onChange(htmlString);
      if (!quill) return;
      const selection = quill.getSelection();
      if (selection) {
        quill.setSelection(selection);
      }
    },
    [onChange, quill],
  );

  useEffect(() => {
    const valueContent = value ?? "";
    setHTMLContent(valueContent);
  }, [value, setHTMLContent]);

  return (
    <Grid container className="editor">
      <EditorWrapper style={editorStyle} id={id}>
        <ReactQuill
          ref={quillRef}
          style={{
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.white,
          }}
          value={content}
          onChange={handleChange}
          modules={modules}
          placeholder={placeholder}
        />
      </EditorWrapper>
    </Grid>
  );
};
export default HtmlEditor;
