import { ProjectPermission } from "dataLayer/types/projectEndpoints";
import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { coreSelector } from "store/slices/core";
import { dashboardSelector } from "store/slices/dashboard";

const projectSettingsPermissions: ProjectPermission[] = [ProjectPermission.OWNER, ProjectPermission.EDIT];
const projectDeletePermissions: ProjectPermission[] = [ProjectPermission.OWNER, ProjectPermission.DELETE];

const useProject = () => {
  const params = useParams();
  const userProjects = useAppSelector(dashboardSelector.userProjects);
  const authentication = useAppSelector(coreSelector.authentication);
  const { projectId } = params;
  const userId = authentication.user?.id;
  const projects = userProjects.list;

  const PROJECT = useMemo(
    () => (projects ? projects.find((project) => project.id === projectId) : null),
    [projects, projectId],
  );

  const PROJECT_SETTINGS = useMemo(() => {
    const userPermissionsData = PROJECT?.allowedUsers.find((allowedUser) => allowedUser.userId === userId);
    const allowPermission = userPermissionsData?.permissions.find((permission) =>
      projectSettingsPermissions.includes(permission),
    );
    return !!allowPermission;
  }, [userId, PROJECT]);

  const PROJECT_DELETE = useMemo(() => {
    const userPermissionsData = PROJECT?.allowedUsers.find((allowedUser) => allowedUser.userId === userId);
    const allowPermission = userPermissionsData?.permissions.find((permission) =>
      projectDeletePermissions.includes(permission),
    );
    return !!allowPermission;
  }, [userId, PROJECT]);

  const getProjectById = useCallback(
    (projectId: string) => (projects ? projects.find((project) => project.id === projectId) : null),
    [projects],
  );

  return { PROJECT, PROJECT_SETTINGS, PROJECT_DELETE, getProjectById };
};

export default useProject;
