import { type PropsWithChildren, useState } from "react";
import AccordionListContext from "./AccordionListContext";

interface AccordionListContextProviderProps extends PropsWithChildren {
  defaultValue?: string;
}

const AccordionListContextProvider = ({ defaultValue, children }: AccordionListContextProviderProps) => {
  const [expandedItemId, setExpandedItemId] = useState<string | null>(defaultValue ?? null);

  return (
    <AccordionListContext.Provider
      value={{
        expandedItemId,
        setExpandedItemId,
      }}
    >
      {children}
    </AccordionListContext.Provider>
  );
};

export default AccordionListContextProvider;
