import { Box, Tabs, Tab } from "@mui/material";
import { Translations } from "constants/translations";
import { type IUnique } from "dataLayer/types";
import { useState, type SyntheticEvent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import StoreGeneralSettings from "./general";
import useQuery from "hooks/useQuery";
import StoreLocalizationSettings from "./localization";
import StoreServerConfigurationSettings from "./server";

const tabs: IUnique[] = [
  {
    id: "general",
    title: Translations.PAGE_PROJECT_STORE_SETTINGS_TAB_GENERAL,
  },
  {
    id: "localization",
    title: Translations.PAGE_PROJECT_STORE_SETTINGS_TAB_LOCALIZATION,
  },
  {
    id: "server",
    title: Translations.PAGE_PROJECT_STORE_SETTINGS_TAB_SERVER,
  },
];

const getTabContent = (tab: string | null) => {
  switch (tab) {
    case "general":
      return <StoreGeneralSettings />;
    case "localization":
      return <StoreLocalizationSettings />;
    case "server":
      return <StoreServerConfigurationSettings />;
    default:
      return null;
  }
};

const StoreContentTabs = () => {
  const [t] = useTranslation();
  const query = useQuery();
  const tabValue = query.get("tab");
  const [tab, setTab] = useState<string>(tabValue ?? tabs[0].id);
  const navigate = useNavigate();

  const onTabChange = useCallback(
    (event: SyntheticEvent, tabId: string) => {
      navigate(`?tab=${tabId}`);
      setTab(tabId);
    },
    [navigate],
  );

  const tabContent = useMemo(() => getTabContent(tab), [tab]);

  return (
    <Box
      sx={{
        borderRadius: "4px",
        mt: "10px",
      }}
    >
      <Tabs
        value={tab ?? false}
        onChange={onTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="language tabs"
      >
        {tabs.map((tab) => (
          <Tab label={t(tab.title)} value={tab.id} key={tab.id} />
        ))}
      </Tabs>
      <Box sx={{ p: "10px" }}>{tabContent}</Box>
    </Box>
  );
};

export default StoreContentTabs;
