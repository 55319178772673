import ProjectNavigation from "./NavigationLists/ProjectNavigation";
import StoreNavigation from "./NavigationLists/StoreNavigation";
import DashboardNavigation from "./NavigationLists/DashboardNavigation";

const DashboardDrawerList = () => {
  return (
    <>
      <DashboardNavigation />
      <ProjectNavigation />
      <StoreNavigation />
    </>
  );
};

export default DashboardDrawerList;
