import { type PropsWithChildren, useMemo, useReducer, useState } from "react";
import StoreSettingsContext, { type ISetServerDetailsPayload, type ServerDetails } from "./StoreSettingsContext";
import { useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";
import multiLingualStringReducer from "utils/multilingualUtils";
import { type Language } from "dataLayer/types";

interface StoreSettingsContextProviderProps extends PropsWithChildren {}

const getDefaultServerDetails = (): ServerDetails => ({
  host: "",
  port: 0,
  password: "",
  encoding: "utf8",
});

const serverDetailsReducer = (state: ServerDetails | null, action: ISetServerDetailsPayload): ServerDetails => {
  const rest = state ?? getDefaultServerDetails();
  return {
    ...rest,
    [action.property]: action.value,
  };
};

const StoreSettingsContextProvider = ({ children }: StoreSettingsContextProviderProps) => {
  const storeDetails = useAppSelector(storeSelector.store)!;
  const storeTypes = useAppSelector(storeSelector.storeTypes);
  const storeType = useMemo(() => {
    return storeTypes?.find((type) => type.id === storeDetails?.type)?.key ?? null;
  }, [storeTypes, storeDetails]);
  const [url, setUrl] = useState<string | null>(storeDetails.settings.customDomain);
  const [icon, setIcon] = useState<string | null>(storeDetails.icon);
  const [subdomain, setSubdomain] = useState<string | null>(storeDetails.settings.subdomain);
  const [storeTitle, setStoreTitle] = useReducer(multiLingualStringReducer, storeDetails.title);
  const [serverDetails, setServerDetails] = useReducer(
    serverDetailsReducer,
    storeDetails.settings.serverConfiguration?.details ?? getDefaultServerDetails(),
  );
  const [supportedLanguages, setSupportedLanguages] = useState<Language[]>(storeDetails.settings.supportedLanguages);
  const [fallbackLanguage, setFallbackLanguage] = useState<Language | null>(storeDetails.settings.fallbackLanguage);
  const [timeZone, setTimeZone] = useState<string>(storeDetails.settings.timeZone);
  const storeId = storeDetails.id;

  return (
    <StoreSettingsContext.Provider
      value={{
        storeId,
        storeType,
        url,
        icon,
        subdomain,
        storeTitle,
        supportedLanguages,
        fallbackLanguage,
        timeZone,
        serverDetails,
        setStoreTitle,
        setUrl,
        setSubdomain,
        setIcon,
        setSupportedLanguages,
        setFallbackLanguage,
        setTimeZone,
        setServerDetails,
      }}
    >
      {children}
    </StoreSettingsContext.Provider>
  );
};

export default StoreSettingsContextProvider;
