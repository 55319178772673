import { Typography, IconButton, Divider, styled, type CSSObject, type Theme } from "@mui/material";
import { drawerWidth } from "constants/style";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTranslation } from "react-i18next";
import MuiDrawer from "@mui/material/Drawer";
import DashboardDrawerList from "./DrawerList";
import useDashboardNavigationTitle from "pages/Dashboard/hooks/useDashboardNavigationTitle";
import DrawerContext from "./context/DrawerContext";
import { useContext } from "react";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DashboardDrawer = () => {
  const [t] = useTranslation();
  const navigationTitle = useDashboardNavigationTitle();
  const { drawerOpen, fixedAsOpen, toggleDrawer, setMouseOver } = useContext(DrawerContext);

  return (
    <Drawer
      variant="permanent"
      open={drawerOpen}
      onMouseOver={() => {
        setMouseOver(true);
      }}
      onMouseOut={() => {
        setMouseOver(false);
      }}
    >
      <DrawerHeader>
        <Typography component="h1" variant="h6" color="inherit">
          {t(navigationTitle)}
        </Typography>
        <IconButton
          onClick={() => {
            toggleDrawer();
          }}
        >
          {fixedAsOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <DashboardDrawerList />
    </Drawer>
  );
};

export default DashboardDrawer;
