import React, { useCallback } from "react";
import { Select, MenuItem, FormControl, InputLabel, type SelectChangeEvent } from "@mui/material";
import useLanguage from "hooks/useLanguage";
import { type IUnique, Language } from "dataLayer/types";
import { useTranslation } from "react-i18next";
import { Translations } from "constants/translations";

const languageOptions: IUnique[] = Object.values(Language).map((lang) => ({
  id: lang,
  title: `${Translations.LANGUAGE_TITLE}.${lang}`,
}));

const LanguageSelector = () => {
  const { language, changeLanguage } = useLanguage();
  const [t] = useTranslation();

  const handleChange = useCallback(
    (event: SelectChangeEvent<Language>) => {
      changeLanguage(event.target.value);
    },
    [changeLanguage],
  );

  return (
    <FormControl variant="outlined" style={{ paddingRight: "10px" }}>
      <InputLabel id="language-selector-label">Language</InputLabel>
      <Select
        labelId="language-selector-label"
        value={language}
        onChange={(event) => {
          handleChange(event);
        }}
        label="Language"
      >
        {languageOptions.map((languageOption) => (
          <MenuItem value={languageOption.id} key={languageOption.id}>
            {t(languageOption.title)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LanguageSelector;
