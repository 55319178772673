import { Box } from "@mui/material";
import ProjectBreadcrumb from "components/Dashboard/Project/ProjectBreadcrumb";
import { Outlet } from "react-router-dom";

const ProjectLayout = () => {
  return (
    <Box>
      <ProjectBreadcrumb />
      <Outlet />
    </Box>
  );
};

export default ProjectLayout;
