import { LoadingButton } from "@mui/lab";
import { Button, DialogActions } from "@mui/material";
import { Translations } from "constants/translations";
import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { dispatch, useAppSelector } from "store/hooks";
import { storeMiddleware, storeSelector } from "store/slices/store";
import StoreProductContext from "./context/StoreProductContext";
import { type ConfirmationModalProps } from "components/modals/shared/ConfirmationModal";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";
import useProjectStore from "pages/Dashboard/hooks/useProjectStore";

interface FormActionsProps {
  onClose: () => void;
}

const FormActions = ({ onClose }: FormActionsProps) => {
  const [t] = useTranslation();
  const { storeId } = useProjectStore();
  const { productId } = useContext(StoreProductContext);
  const isStoreCreateLoading = useAppSelector(storeSelector.isStoreCreateLoading);
  const isStoreUpdateLoading = useAppSelector(storeSelector.isStoreUpdateLoading);

  const isLoading = useMemo(() => {
    return productId ? isStoreUpdateLoading : isStoreCreateLoading;
  }, [productId, isStoreCreateLoading, isStoreUpdateLoading]);

  const submitLabel = useMemo(
    () =>
      productId ? Translations.MODAL_UPDATE_PRODUCT_BUTTON_SUBMIT : Translations.MODAL_CREATE_PRODUCT_BUTTON_SUBMIT,
    [productId],
  );

  const onRemove = useCallback(() => {
    const props: ConfirmationModalProps = {
      title: t(Translations.MODAL_CONFIRMATION_TITLE_REMOVE_PRODUCT),
      subtitle: t(Translations.MODAL_CONFIRMATION_SUBTITLE_REMOVE_PRODUCT),
      confirmationCallback: () => {
        dispatch(
          storeMiddleware.removeStoreProduct({
            storeId: storeId!,
            productId: productId!,
          }),
        );
      },
    };
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.ConfirmationModal,
        props,
      }),
    );
  }, [storeId, productId, t]);

  return (
    <DialogActions>
      {productId ? (
        <Button onClick={onRemove} color="error">
          {t(Translations.CONTEXT_ACTION_PRODUCT_REMOVE)}
        </Button>
      ) : null}
      <LoadingButton loading={isLoading} onClick={onClose}>
        {t(Translations.MODAL_CREATE_PRODUCT_BUTTON_CANCEL)}
      </LoadingButton>
      <LoadingButton loading={isLoading} type="submit">
        {t(submitLabel)}
      </LoadingButton>
    </DialogActions>
  );
};

export default FormActions;
