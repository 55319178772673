import { contextSetterImitator } from "constants/context";
import { type Language, type MultilingualString } from "dataLayer/types";
import { type Dispatch, type SetStateAction, createContext } from "react";
import { getCurrentTimeZone } from "utils/date";
import { getEmptyMultilingualString } from "utils/language";
import { type ISetMultilingualContentPayload } from "utils/multilingualUtils";

export interface IStoreModalContext {
  supportedLanguages: Language[];
  fallbackLanguage: Language | null;
  step: number;
  storeType: string | null;
  storeTitle: MultilingualString;
  url: string | null;
  icon: string | null;
  subdomain: string | null;
  timeZone: string;
  setStep: Dispatch<SetStateAction<number>>;
  setStoreTitle: Dispatch<ISetMultilingualContentPayload>;
  setStoreType: Dispatch<SetStateAction<string | null>>;
  setUrl: Dispatch<SetStateAction<string | null>>;
  setIcon: Dispatch<SetStateAction<string | null>>;
  setSubdomain: Dispatch<SetStateAction<string | null>>;
  setSupportedLanguages: Dispatch<SetStateAction<Language[]>>;
  setFallbackLanguage: Dispatch<SetStateAction<Language | null>>;
  setTimeZone: Dispatch<SetStateAction<string>>;
}

const StoreModalContext = createContext<IStoreModalContext>({
  supportedLanguages: [],
  fallbackLanguage: null,
  step: 0,
  storeType: null,
  storeTitle: getEmptyMultilingualString(),
  url: null,
  icon: null,
  subdomain: null,
  timeZone: getCurrentTimeZone(),
  setStep: contextSetterImitator,
  setStoreTitle: contextSetterImitator,
  setStoreType: contextSetterImitator,
  setUrl: contextSetterImitator,
  setIcon: contextSetterImitator,
  setSubdomain: contextSetterImitator,
  setSupportedLanguages: contextSetterImitator,
  setFallbackLanguage: contextSetterImitator,
  setTimeZone: contextSetterImitator,
});

export default StoreModalContext;
