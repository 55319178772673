import { useCallback, useMemo } from "react";
import { useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";

const useProductsHook = () => {
  const store = useAppSelector(storeSelector.store);
  const products = useMemo(() => store?.entities.products ?? [], [store]);

  const getProductById = useCallback(
    (id: string) => {
      return products.find((product) => product.id === id) ?? null;
    },
    [products],
  );

  const getProductsByIds = useCallback(
    (productIds: string[]) => {
      return products.filter((product) => productIds.includes(product.id));
    },
    [products],
  );

  return { products, getProductById, getProductsByIds };
};

export default useProductsHook;
