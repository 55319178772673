import { Grid } from "@mui/material";
import ProductAlternativeProductsField from "../fields/ProductAlternativeProductsField";
import FieldToggleWrapper from "../../../../common/FieldToggleWrapper";
import { useContext } from "react";
import StoreProductContext from "../../context/StoreProductContext";
import { Translations } from "constants/translations";
import ProductAdditionalProductFields from "../fields/ProductAdditionalProductFields";
import { type AdditionalProductConfig } from "dataLayer/types/storeProductEndpoints";

const defaultAlternativeProducts: string[] = [];
const defaultAdditionalProductConfig: AdditionalProductConfig = {
  id: "",
  discount: 0,
};
const ProductUpSellingSection = () => {
  const { alternativeProducts, setAlternativeProducts, additionalProduct, setAdditionalProduct } =
    useContext(StoreProductContext);
  return (
    <Grid container item xs={12} direction={"column"} spacing={2}>
      <Grid item>
        <FieldToggleWrapper
          state={alternativeProducts}
          setState={setAlternativeProducts}
          defaultEnabledState={defaultAlternativeProducts}
          defaultDisabledState={null}
          label={Translations.MODAL_CREATE_PRODUCT_FIELD_ALTERNATIVE_PRODUCTS}
        >
          <ProductAlternativeProductsField />
        </FieldToggleWrapper>
      </Grid>
      <Grid item>
        <FieldToggleWrapper
          state={additionalProduct}
          setState={setAdditionalProduct}
          defaultEnabledState={defaultAdditionalProductConfig}
          defaultDisabledState={null}
          label={Translations.MODAL_CREATE_PRODUCT_FIELD_ADDITIONAL_PRODUCT}
        >
          <ProductAdditionalProductFields />
        </FieldToggleWrapper>
      </Grid>
    </Grid>
  );
};

export default ProductUpSellingSection;
