import { LoadingButton } from "@mui/lab";
import { DialogActions } from "@mui/material";
import { Translations } from "constants/translations";
import { t } from "i18next";
import { useContext } from "react";
import { useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";
import StoreCategoryContext from "./context/StoreCategoryContext";

interface FormActionsProps {
  onClose: () => void;
}
const FormActions = ({ onClose }: FormActionsProps) => {
  const isCategoryLoading = useAppSelector(storeSelector.isCategoryLoading);
  const { categoryId } = useContext(StoreCategoryContext);

  const submitLabel = categoryId
    ? Translations.MODAL_EDIT_CATEGORY_BUTTON_SUBMIT
    : Translations.MODAL_CREATE_CATEGORY_BUTTON_SUBMIT;

  return (
    <DialogActions>
      <LoadingButton onClick={onClose} loading={isCategoryLoading}>
        {t(Translations.MODAL_CREATE_CATEGORY_BUTTON_CANCEL)}
      </LoadingButton>
      <LoadingButton type="submit" loading={isCategoryLoading}>
        {t(submitLabel)}
      </LoadingButton>
    </DialogActions>
  );
};

export default FormActions;
