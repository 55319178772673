import { Autocomplete, TextField } from "@mui/material";
import { Translations } from "constants/translations";
import { type IUnique, Language } from "dataLayer/types";
import { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import StoreModalContext from "../../../context/StoreModalContext";

const StoreFallbackLanguageField = () => {
  const [t] = useTranslation();

  const { supportedLanguages, fallbackLanguage, setFallbackLanguage } = useContext(StoreModalContext);

  const supportedLanguagesOptions: IUnique[] = useMemo(
    () =>
      Object.values(Language).map((key) => ({
        id: key,
        title: t(`${Translations.LANGUAGE_TITLE}.${key}`),
      })),
    [t],
  );

  const fallbackLanguageOptions = useMemo(
    () => supportedLanguagesOptions.filter((option) => supportedLanguages.includes(option.id as Language)),
    [supportedLanguages, supportedLanguagesOptions],
  );

  const fallbackLanguageValue = useMemo(
    () => fallbackLanguageOptions.find((option) => option.id === fallbackLanguage) ?? null,
    [fallbackLanguage, fallbackLanguageOptions],
  );

  useEffect(() => {
    if (!fallbackLanguageValue) {
      setFallbackLanguage(null);
    }
  }, [fallbackLanguageValue, setFallbackLanguage]);

  return (
    <Autocomplete
      options={fallbackLanguageOptions}
      getOptionLabel={(option) => option.title}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          label={t(Translations.MODAL_CREATE_STORE_FORM_FIELD_FALLBACK_LANGUAGE)}
          variant="outlined"
        />
      )}
      value={fallbackLanguageValue}
      onChange={(_, value) => {
        setFallbackLanguage(value ? (value.id as Language) : null);
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.title}
        </li>
      )}
    />
  );
};

export default StoreFallbackLanguageField;
