import { type MutableRefObject, useContext, useMemo } from "react";
import StoreProductContext from "../../context/StoreProductContext";
import HtmlEditor from "components/common/HtmlEditor";
import { useTranslation } from "react-i18next";
import { Translations } from "constants/translations";
import MultilingualFieldContext from "components/common/MultilingualFieldWrapper/context/MultilingualFieldContext";
import { getMultilingualContentValue } from "utils/language";

interface ProductDescriptionFieldProps {
  imageRef: MutableRefObject<HTMLImageElement | null>;
}

const ProductDescriptionField = ({ imageRef }: ProductDescriptionFieldProps) => {
  const [t] = useTranslation();
  const { description, setDescription } = useContext(StoreProductContext);
  const { language } = useContext(MultilingualFieldContext);

  const height = useMemo(() => {
    if (imageRef.current?.clientHeight) {
      return `${imageRef.current.clientHeight - 43}px`;
    } else {
      return "inherit";
    }
  }, [imageRef]);

  const descriptionValue = useMemo(() => getMultilingualContentValue(language, description), [description, language]);

  return (
    <HtmlEditor
      id={Translations.MODAL_CREATE_PRODUCT_FIELD_DESCRIPTION}
      value={descriptionValue}
      onChange={(value) => {
        setDescription({ language, value });
      }}
      editorStyle={{ height, maxHeight: height }}
      placeholder={t(Translations.MODAL_CREATE_PRODUCT_FIELD_DESCRIPTION)}
    />
  );
};

export default ProductDescriptionField;
