import { TextField } from "@mui/material";
import { Translations } from "constants/translations";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import StoreModalContext from "../../../context/StoreModalContext";

const StoreIconField = () => {
  const [t] = useTranslation();
  const { icon, setIcon } = useContext(StoreModalContext);

  return (
    <TextField
      margin="dense"
      key={Translations.MODAL_CREATE_STORE_FORM_FIELD_ICON}
      name="icon"
      label={t(Translations.MODAL_CREATE_STORE_FORM_FIELD_ICON)}
      type="text"
      fullWidth
      variant="outlined"
      defaultValue={icon}
      onChange={(_) => {
        setIcon(_.target.value);
      }}
    />
  );
};

export default StoreIconField;
