import { type PortalUpdateStoreBody } from "dataLayer/types/storeEndpoints";
import useProject from "pages/Dashboard/hooks/useProject";
import React, { useCallback, useContext, useMemo } from "react";
import { Form } from "react-router-dom";
import { dispatch, useAppSelector } from "store/hooks";
import { storeMiddleware, storeSelector } from "store/slices/store";
import StoreSettingsContext from "../context/StoreSettingsContext";
import StoreContentTabs from "./tabs";
import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";
import { Translations } from "constants/translations";
import { type ConfirmationModalProps } from "components/modals/shared/ConfirmationModal";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";
import { useTranslation } from "react-i18next";

const StoreSettingsContent = () => {
  const [t] = useTranslation();
  const context = useContext(StoreSettingsContext);
  const { PROJECT } = useProject();
  const currentStore = useAppSelector(storeSelector.store);
  const isStoreRemoveLoading = useAppSelector(storeSelector.isStoreRemoveLoading);
  const isStoreUpdateLoading = useAppSelector(storeSelector.isStoreUpdateLoading);
  const isLoading = isStoreRemoveLoading || isStoreUpdateLoading;

  const oldState: PortalUpdateStoreBody = useMemo(() => {
    return {
      projectId: PROJECT!.id,
      id: currentStore!.id,
      title: currentStore!.title ?? null,
      icon: currentStore!.icon ?? null,
      settings: {
        ...currentStore!.settings,
        customDomain: currentStore!.settings.customDomain ?? null,
        subdomain: currentStore!.settings.subdomain,
        supportedLanguages: currentStore!.settings.supportedLanguages,
        fallbackLanguage: currentStore!.settings.fallbackLanguage,
        timeZone: currentStore!.settings.timeZone,
      },
    };
  }, [currentStore, PROJECT]);

  const newState: PortalUpdateStoreBody = useMemo(
    () => ({
      projectId: PROJECT!.id,
      id: currentStore!.id,
      title: context.storeTitle,
      icon: context.icon ?? null,
      settings: {
        ...currentStore!.settings,
        subdomain: context.subdomain!,
        customDomain: context.url,
        supportedLanguages: context.supportedLanguages,
        fallbackLanguage: context.fallbackLanguage!,
        timeZone: context.timeZone,
        serverConfiguration: {
          ...currentStore!.settings.serverConfiguration,
          details: context.serverDetails,
        },
      },
    }),
    [PROJECT, context, currentStore],
  );

  const settingsChanged = useMemo(() => {
    return JSON.stringify(oldState) !== JSON.stringify(newState);
  }, [oldState, newState]);

  const updateStore = useCallback(() => {
    dispatch(storeMiddleware.updateStore(newState));
  }, [newState]);

  const onSubmitClick = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();
      if (!settingsChanged) {
        return;
      }
      updateStore();
    },
    [updateStore, settingsChanged],
  );

  const onRemoveCLick = useCallback(() => {
    if (!PROJECT || !currentStore) {
      return;
    }
    const props: ConfirmationModalProps = {
      title: t(Translations.MODAL_CONFIRMATION_TITLE_REMOVE_STORE),
      subtitle: t(Translations.MODAL_CONFIRMATION_SUBTITLE_REMOVE_STORE, {
        storeTitle: currentStore.title,
      }),
      confirmationCallback: () => {
        dispatch(
          storeMiddleware.removeStore({
            storeId: currentStore.id,
            projectId: PROJECT.id,
          }),
        );
      },
    };
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.ConfirmationModal,
        props,
      }),
    );
  }, [currentStore, PROJECT, t]);

  return (
    <Form onSubmit={onSubmitClick}>
      <StoreContentTabs />
      <Grid container justifyContent={"space-between"} sx={{ p: "10px" }}>
        <Grid item>
          <LoadingButton disabled={isLoading} loading={isLoading} color={"error"} onClick={onRemoveCLick}>
            {t(Translations.PAGE_PROJECT_STORE_SETTINGS_BUTTON_REMOVE)}
          </LoadingButton>
        </Grid>
        <Grid item>
          <LoadingButton disabled={!settingsChanged || isLoading} loading={isLoading} type="submit">
            {t(Translations.PAGE_PROJECT_STORE_SETTINGS_BUTTON_UPDATE)}
          </LoadingButton>
        </Grid>
      </Grid>
    </Form>
  );
};

export default StoreSettingsContent;
