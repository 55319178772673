import { useParams } from "react-router-dom";
import useProject from "./useProject";
import { useCallback } from "react";
const useProjectStore = () => {
  const params = useParams();
  const { storeType, storeId } = params;
  const { PROJECT, getProjectById } = useProject();

  const STORE = PROJECT?.stores.find((store) => store.id === storeId);

  const getStoreById = useCallback(
    (storeId: string, projectId?: string) => {
      const project = projectId ? getProjectById(projectId) : PROJECT;
      return project?.stores.find((store) => store.id === storeId);
    },
    [PROJECT, getProjectById],
  );

  return { STORE_TYPE: storeType, STORE, storeId, getStoreById };
};

export default useProjectStore;
