import { useContext, useMemo } from "react";
import { Translations } from "constants/translations";
import { Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import StoreModalContext from "../../../context/StoreModalContext";
import { type IUnique, Language } from "dataLayer/types";

const StoreLanguagesField = () => {
  const [t] = useTranslation();
  const { supportedLanguages, setSupportedLanguages } = useContext(StoreModalContext);

  const supportedLanguagesOptions: IUnique[] = useMemo(
    () =>
      Object.values(Language).map((key) => ({
        id: key,
        title: t(`${Translations.LANGUAGE_TITLE}.${key}`),
      })),
    [t],
  );

  const supportedLanguagesValues = useMemo(
    () => supportedLanguagesOptions.filter((option) => supportedLanguages.includes(option.id as Language)),
    [supportedLanguages, supportedLanguagesOptions],
  );

  return (
    <Autocomplete
      multiple
      options={supportedLanguagesOptions}
      getOptionLabel={(option) => option.title}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          label={t(Translations.MODAL_CREATE_STORE_FORM_FIELD_LANGUAGE)}
          variant="outlined"
        />
      )}
      value={supportedLanguagesValues}
      onChange={(_, values) => {
        setSupportedLanguages(values.map((value) => value.id as Language));
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.title}
        </li>
      )}
    />
  );
};

export default StoreLanguagesField;
