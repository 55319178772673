import { type IClientStoreType } from "dataLayer/types/storeTypeEndpoints";
import { useCallback, useMemo } from "react";
import { useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";

const useStoreTypes = () => {
  const storeTypes = useAppSelector(storeSelector.storeTypes);

  const portalStoreTypes: IClientStoreType[] = useMemo(() => storeTypes ?? [], [storeTypes]);

  const getStoreTypeByKey = useCallback(
    (key: string | null) => {
      return portalStoreTypes.find((storeType) => storeType.key === key);
    },
    [portalStoreTypes],
  );
  const getStoreTypeById = useCallback(
    (id: string | null) => {
      return portalStoreTypes.find((storeType) => storeType.id === id);
    },
    [portalStoreTypes],
  );

  const getStoreTypeByKeys = useCallback(
    (keys: string[]) => {
      return portalStoreTypes.filter((storeType) => keys.includes(storeType.key));
    },
    [portalStoreTypes],
  );

  return {
    portalStoreTypes,
    getStoreTypeByKey,
    getStoreTypeByKeys,
    getStoreTypeById,
  };
};

export default useStoreTypes;
