import { Typography } from "@mui/material";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";

const NotFoundPage = () => {
  const [t] = useTranslation();

  return (
    <>
      <header title={t(Translations.PAGE_NOT_FOUND_DOCUMENT_TITLE)} />
      <Typography variant="h3">{t(Translations.PAGE_NOT_FOUND_TITLE)}</Typography>
      <Typography variant="h2">{t(Translations.PAGE_NOT_FOUND_MESSAGE)}</Typography>
    </>
  );
};

export default NotFoundPage;
