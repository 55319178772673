import { IconButton, Toolbar, Typography } from "@mui/material";
import AppBar from "components/Dashboard/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import { dispatch, useAppSelector } from "store/hooks";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";
import { coreSelector } from "store/slices/core";
import { Translations } from "constants/translations";
import { t } from "i18next";
import TextButton from "shared/TextButton";
import { useLocation, useNavigate } from "react-router-dom";
import AppBarAvatar from "../AppBarAvatar";
import { useCallback, useContext } from "react";
import DashboardDrawerContext from "../DashboardDrawer/context/DrawerContext";
import LanguageSelector from "components/common/LanguageSelector";

interface PageAppBarProps {
  hasMenu: boolean;
  title: string;
}

const PageAppBar = ({ hasMenu, title }: PageAppBarProps) => {
  const { user } = useAppSelector(coreSelector.authentication);
  const location = useLocation();
  const navigate = useNavigate();
  const { drawerOpen, fixedAsOpen, setFixedAsOpen } = useContext(DashboardDrawerContext);

  const onSignInClick = () => {
    dispatch(viewsMiddleware.openModal({ name: ModalName.SignInModal, props: null }));
  };

  const onSignUpClick = () => {
    dispatch(viewsMiddleware.openModal({ name: ModalName.SignUpModal, props: null }));
  };

  const onDashboardClick = () => {
    navigate("/dashboard");
  };

  const toggleDrawer = useCallback(() => {
    setFixedAsOpen?.(!fixedAsOpen);
  }, [fixedAsOpen, setFixedAsOpen]);

  return (
    <AppBar position="absolute" open={drawerOpen && hasMenu}>
      <Toolbar
        sx={{
          pr: "24px", // keep right padding when drawer closed
        }}
      >
        {user ? (
          <>
            {hasMenu ? (
              <>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawer}
                  sx={{
                    marginRight: "36px",
                    ...(drawerOpen && { display: "none" }),
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </>
            ) : null}

            <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
              {drawerOpen ? "" : t(title)}
            </Typography>
            {!location.pathname.includes("/dashboard") ? (
              <TextButton onClick={onDashboardClick} title={t(Translations.LAYOUT_ROOT_BUTTON_DASHBOARD)} />
            ) : null}
            <LanguageSelector />
            <AppBarAvatar />
          </>
        ) : (
          <>
            <TextButton onClick={onSignInClick} title={t(Translations.LAYOUT_ROOT_BUTTON_SIGN_IN)} />
            <TextButton onClick={onSignUpClick} title={t(Translations.LAYOUT_ROOT_BUTTON_SIGN_UP)} />
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default PageAppBar;
