import { type IDashboardSliceState } from "store/types/dashboard";

export const getInitialState = (): IDashboardSliceState => ({
  userProjects: {
    list: null,
    isListLoading: false,
    isCreateLoading: false,
    isUpdateLoading: false,
    isRemoveLoading: false,
  },
});
