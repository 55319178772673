import { DialogContent, DialogContentText, TextField, FormControlLabel, Checkbox, Link } from "@mui/material";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { coreSelector } from "store/slices/core";

interface FormBodyProps {
  confirmPasswordError: string | null;
  setConfirmPasswordError: (value: string | null) => void;
}
const FormBody = ({ confirmPasswordError, setConfirmPasswordError }: FormBodyProps) => {
  const [t] = useTranslation();
  const { isLoading } = useAppSelector(coreSelector.authentication);

  return (
    <>
      <DialogContent>
        <DialogContentText>{t(Translations.MODAL_SIGN_UP_SUBTITLE)}</DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          key={Translations.MODAL_SIGN_UP_FIELD_FIRST_NAME}
          name="firstName"
          label={t(Translations.MODAL_SIGN_UP_FIELD_FIRST_NAME)}
          type="text"
          fullWidth
          variant="outlined"
          disabled={isLoading}
        />
        <TextField
          autoFocus
          required
          margin="dense"
          key={Translations.MODAL_SIGN_UP_FIELD_LAST_NAME}
          name="lastName"
          label={t(Translations.MODAL_SIGN_UP_FIELD_LAST_NAME)}
          type="text"
          fullWidth
          variant="outlined"
          disabled={isLoading}
        />
        <TextField
          autoFocus
          required
          margin="dense"
          key={Translations.MODAL_SIGN_UP_FIELD_EMAIL}
          name="email"
          label={t(Translations.MODAL_SIGN_UP_FIELD_EMAIL)}
          type="email"
          fullWidth
          variant="outlined"
          disabled={isLoading}
        />
        <TextField
          autoFocus
          required
          margin="dense"
          key={Translations.MODAL_SIGN_UP_FIELD_PASSWORD_PRIMARY}
          name="password"
          label={t(Translations.MODAL_SIGN_UP_FIELD_PASSWORD_PRIMARY)}
          type="password"
          fullWidth
          variant="outlined"
          inputProps={{
            minLength: 8,
            maxLength: 20,
          }}
          disabled={isLoading}
        />
        <TextField
          autoFocus
          required
          margin="dense"
          key={Translations.MODAL_SIGN_UP_FIELD_PASSWORD_CONFIRMATION}
          name="confirmPassword"
          label={t(Translations.MODAL_SIGN_UP_FIELD_PASSWORD_CONFIRMATION)}
          type="password"
          fullWidth
          variant="outlined"
          inputProps={{
            minLength: 8,
            maxLength: 20,
          }}
          helperText={confirmPasswordError}
          onChange={() => {
            setConfirmPasswordError(null);
          }}
          disabled={isLoading}
        />
        <FormControlLabel
          required
          disabled={isLoading}
          control={<Checkbox name={Translations.MODAL_SIGN_UP_CHECKBOX_TERMS_CONDITIONS} />}
          label={
            <>
              {t(Translations.MODAL_SIGN_UP_DOCS_READ_PREFIX)}
              <Link href="/documentation/terms-and-conditions" target="_blank">
                {t(Translations.MODAL_SIGN_UP_CHECKBOX_TERMS_CONDITIONS)}
              </Link>
            </>
          }
        />
        <FormControlLabel
          required
          disabled={isLoading}
          control={<Checkbox name={Translations.MODAL_SIGN_UP_CHECKBOX_PRIVACY_POLICY} />}
          label={
            <>
              {t(Translations.MODAL_SIGN_UP_DOCS_READ_PREFIX)}
              <Link href="/documentation/privacy-policy" target="_blank">
                {t(Translations.MODAL_SIGN_UP_CHECKBOX_PRIVACY_POLICY)}
              </Link>
            </>
          }
        />
        <FormControlLabel
          required
          disabled={isLoading}
          control={<Checkbox name={Translations.MODAL_SIGN_UP_CHECKBOX_AGE} />}
          label={t(Translations.MODAL_SIGN_UP_CHECKBOX_AGE)}
        />
      </DialogContent>
    </>
  );
};

export default FormBody;
