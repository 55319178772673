import { useCallback, useContext, useState } from "react";
import StoreModalContext from "../../context/StoreModalContext";
import { Checkbox, DialogActions, FormControlLabel, Grid, Link } from "@mui/material";
import { Translations } from "constants/translations";
import { LoadingButton } from "@mui/lab";

import { useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";
import SubDomainField from "./fields/SubDomainField";
import useStoreModalHooks from "./hooks/useStoreModalHooks";
import MultilingualFieldWrapper from "components/common/MultilingualFieldWrapper";
import StoreTitleField from "./fields/StoreTitleField";
import StoreIconField from "./fields/StoreIconField";
import StoreLanguagesField from "./fields/StoreLanguagesField";
import StoreTimeZoneField from "./fields/StoreTimeZoneField";
import StoreFallbackLanguageField from "./fields/StoreFallbackLanguageField";
import { useTranslation } from "react-i18next";

const FormFillStep = () => {
  const [t] = useTranslation();
  const { step, setStep, supportedLanguages } = useContext(StoreModalContext);

  const [termsAgreed, setTermsAgreed] = useState<boolean>(false);
  const { onSubmit, isSubmitEnabled } = useStoreModalHooks(termsAgreed);

  const isStoreCreateLoading = useAppSelector(storeSelector.isStoreCreateLoading);

  const onBackClick = useCallback(() => {
    setStep(step - 1);
  }, [step, setStep]);

  return (
    <>
      <Grid item container>
        <Grid
          container
          sx={{
            mb: "10px",
          }}
        ></Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={4}>
            <StoreLanguagesField />
          </Grid>
          <Grid item xs={4}>
            <StoreFallbackLanguageField />
          </Grid>
          <Grid item xs={4}>
            <StoreTimeZoneField />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <MultilingualFieldWrapper supportedLanguages={supportedLanguages}>
            <StoreTitleField />
          </MultilingualFieldWrapper>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <SubDomainField />
        </Grid>
        <Grid item xs={12}>
          <StoreIconField />
        </Grid>
        <FormControlLabel
          required
          control={
            <Checkbox
              name={Translations.MODAL_SIGN_UP_CHECKBOX_TERMS_CONDITIONS}
              checked={termsAgreed}
              onChange={(_, checked) => {
                setTermsAgreed(checked);
              }}
            />
          }
          label={
            <>
              {t(Translations.MODAL_SIGN_UP_DOCS_READ_PREFIX)}
              <Link href="/documentation/terms-and-conditions" target="_blank">
                {t(Translations.MODAL_SIGN_UP_CHECKBOX_TERMS_CONDITIONS)}
              </Link>
            </>
          }
        />
      </Grid>
      <DialogActions>
        <LoadingButton loading={isStoreCreateLoading} onClick={onBackClick}>
          {t(Translations.MODAL_CREATE_STORE_FORM_BUTTON_BACK)}
        </LoadingButton>
        <LoadingButton loading={isStoreCreateLoading} onClick={onSubmit} disabled={!isSubmitEnabled}>
          {t(Translations.MODAL_CREATE_STORE_FORM_BUTTON_NEXT)}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default FormFillStep;
