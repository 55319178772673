import { Grid } from "@mui/material";
import useProductsHook from "hooks/dashboardDataHooks/useProductsHook";
import ProductCard from "./ProductCard";

const ProductsGridView = () => {
  const { products } = useProductsHook();
  return (
    <Grid container item xs={12} spacing={2}>
      {products.map((product) => (
        <Grid key={product.id} item xs={2}>
          <ProductCard data={product} withButtons />
        </Grid>
      ))}
    </Grid>
  );
};

export default ProductsGridView;
