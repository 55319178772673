import { useContext, useMemo } from "react";
import StoreProductContext from "../../context/StoreProductContext";
import { Translations } from "constants/translations";
import { t } from "i18next";
import { useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { convertProductsIntoOptions } from "utils/convertingUtils";
import MultilingualFieldContext from "components/common/MultilingualFieldWrapper/context/MultilingualFieldContext";

const ProductAlternativeProductsField = () => {
  const { language } = useContext(MultilingualFieldContext);
  const { alternativeProducts, setAlternativeProducts, productId } = useContext(StoreProductContext);
  const items = useAppSelector(storeSelector.products);

  const itemsArray = useMemo(() => items ?? [], [items]);

  const options = useMemo(
    () => convertProductsIntoOptions(language, itemsArray, productId),
    [itemsArray, productId, language],
  );

  const selectedOptions = useMemo(
    () => options.filter((option) => alternativeProducts?.includes(option.id)) ?? [],
    [options, alternativeProducts],
  );

  return (
    <Grid item xs={12}>
      <Autocomplete
        multiple
        options={options}
        getOptionLabel={(option) => option.title}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t(Translations.MODAL_CREATE_PRODUCT_FIELD_ALTERNATIVE_PRODUCTS)}
            variant="outlined"
          />
        )}
        value={selectedOptions}
        onChange={(_, values) => {
          setAlternativeProducts(values.map((value) => value.id));
        }}
      />
    </Grid>
  );
};

export default ProductAlternativeProductsField;
