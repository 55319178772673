import { LoadingButton } from "@mui/lab";
import { DialogActions } from "@mui/material";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";

interface FormActionsProps {
  onClose: () => void;
}
const FormActions = ({ onClose }: FormActionsProps) => {
  const [t] = useTranslation();

  return (
    <DialogActions>
      <LoadingButton onClick={onClose}>{t(Translations.MODAL_SELECT_STORE_PRODUCT_BUTTON_CANCEL)}</LoadingButton>
      <LoadingButton type="submit">{t(Translations.MODAL_SELECT_STORE_PRODUCT_BUTTON_SUBMIT)}</LoadingButton>
    </DialogActions>
  );
};

export default FormActions;
