import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

interface DrawerListItemProps {
  icon?: React.JSX.Element | null;
  title: string;
  path?: string;
  selected?: boolean;
  onClick?: () => void;
}
const DrawerListItem = ({ title, icon, path, selected, onClick }: DrawerListItemProps) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isSelected = path === location.pathname;

  const onListItemClick = () => {
    if (!!path) {
      navigate(path);
    } else {
      onClick?.();
    }
  };

  return (
    <ListItemButton onClick={onListItemClick} selected={isSelected}>
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      <ListItemText primary={t(title)} />
    </ListItemButton>
  );
};

export default DrawerListItem;
