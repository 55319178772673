import { Autocomplete, TextField } from "@mui/material";
import { Translations } from "constants/translations";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import StoreModalContext from "../../../context/StoreModalContext";
import { getCurrentTimeZone, timeZoneOptions } from "utils/date";

const StoreTimeZoneField = () => {
  const [t] = useTranslation();
  const { timeZone, setTimeZone } = useContext(StoreModalContext);

  const timeZoneValue = timeZoneOptions.find((option) => option.id === timeZone) ?? null;

  return (
    <Autocomplete
      options={timeZoneOptions}
      getOptionLabel={(option) => option.title}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          label={t(Translations.MODAL_CREATE_STORE_FORM_FIELD_TIME_ZONE)}
          variant="outlined"
        />
      )}
      value={timeZoneValue}
      onChange={(_, value) => {
        setTimeZone(value ? value.id : getCurrentTimeZone());
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.title}
        </li>
      )}
    />
  );
};

export default StoreTimeZoneField;
