import { TextField } from "@mui/material";
import { Translations } from "constants/translations";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import StoreCategoryContext from "../../context/StoreCategoryContext";
import MultilingualFieldContext from "components/common/MultilingualFieldWrapper/context/MultilingualFieldContext";
import { getMultilingualContentValue } from "utils/language";

const CategoryTitleField = () => {
  const [t] = useTranslation();
  const { title, setTitle } = useContext(StoreCategoryContext);
  const { language } = useContext(MultilingualFieldContext);

  const value = useMemo(() => getMultilingualContentValue(language, title), [language, title]);

  return (
    <TextField
      autoFocus
      required
      margin="dense"
      key={Translations.MODAL_CREATE_CATEGORY_FIELD_NAME}
      name={Translations.MODAL_CREATE_CATEGORY_FIELD_NAME}
      label={t(Translations.MODAL_CREATE_CATEGORY_FIELD_NAME)}
      type="text"
      fullWidth
      variant="outlined"
      value={value}
      onChange={(event) => {
        setTitle({ language, value: event.target.value });
      }}
    />
  );
};

export default CategoryTitleField;
