import { type HttpsCallable } from "firebase/functions";

export type ApiModule<N extends string, B = any, R = any> = Record<N, HttpsCallable<B, R>>;

export enum PortalEndpoint {
  createUser = "createUser",
  getUser = "getUser",
  getUserProjects = "getUserProjects",
  updateUserDisplayInformation = "updateUserDisplayInformation",
  createProject = "createProject",
  createStore = "createStore",
  updateStore = "updateStore",
  getPortalStoreTypes = "getPortalStoreTypes",
  getStore = "getStore",
  removeStore = "removeStore",
  getDnsRecords = "getDnsRecords",
  createStoreCategory = "createStoreCategory",
  updateStoreCategory = "updateStoreCategory",
  removeStoreCategory = "removeStoreCategory",
  createStoreTag = "createStoreTag",
  updateStoreTag = "updateStoreTag",
  removeStoreTag = "removeStoreTag",
  createStoreProduct = "createStoreProduct",
  updateStoreProduct = "updateStoreProduct",
  removeStoreProduct = "removeStoreProduct",
  checkSubdomain = "checkSubdomain",
}

export enum ApiModules {
  Portal = "Portal",
}

export type EndpointNameType = PortalEndpoint;

export type TypeAPI = Record<ApiModules, ApiModule<EndpointNameType>>;

export interface AllowedUsers<T> {
  allowedUsers: Array<UserWithPermissions<T>>;
}
export interface UserWithPermissions<T> {
  userId: string;
  permissions: T[];
}

export interface DB_Record {
  id: string;
}

export interface IUnique extends DB_Record {
  title: string;
}

export enum ConnectionType {
  RCON = "RCON",
}

export type SupportedEncoding = "ascii" | "utf8";

export interface IDnsRecordsMap {
  DNS?: IDnsRecord[];
  DNS_IP?: IDnsRecord[];
  CNAME?: IDnsRecord[];
  A?: IDnsRecord[];
  MX?: IDnsRecord[];
  TXT?: IDnsRecord[];
}

interface IDnsRecord {
  value: string | null;
  priority: number | string; // numeric string
}

export interface EmptyObject {}

export enum Language {
  en = "en",
  ru = "ru",
  cn = "cn",
  sp = "sp",
  ar = "ar",
  fr = "fr",
}

export type MultilingualString = Record<Language, string>;

export interface StoreEntity {
  storeId: string;
}
