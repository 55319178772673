import { contextSetterImitator } from "constants/context";
import { Language } from "dataLayer/types";
import { type Dispatch, type SetStateAction, createContext } from "react";

interface IMultilingualFieldContext {
  language: Language;
  setLanguage: Dispatch<SetStateAction<Language>>;
  supportedLanguages?: Language[];
}

const MultilingualFieldContext = createContext<IMultilingualFieldContext>({
  language: Language.en,
  setLanguage: contextSetterImitator,
});

export default MultilingualFieldContext;
