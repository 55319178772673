import { type PropsWithChildren, useState } from "react";
import ProductsOverviewContext, { DisplayMode } from "./ProductsOverviewContext";

const ProductsOverviewContextProvider = ({ children }: PropsWithChildren) => {
  const [displayMode, setDisplayMode] = useState<DisplayMode>(DisplayMode.LIST);

  return (
    <ProductsOverviewContext.Provider
      value={{
        displayMode,
        setDisplayMode,
      }}
    >
      {children}
    </ProductsOverviewContext.Provider>
  );
};

export default ProductsOverviewContextProvider;
