import { Box, Paper, Typography } from "@mui/material";
import ProductsWrapper from "./components/ProductsWrapper";
import { useTranslation } from "react-i18next";
import { Translations } from "constants/translations";
import ProductsListView from "./components/ProductsListView";
import { useContext } from "react";
import ProductsOverviewContext, { DisplayMode } from "./context/ProductsOverviewContext";
import ProductsGridView from "./components/ProductsGridView";

const ProductsOverview = () => {
  const [t] = useTranslation();
  const { displayMode } = useContext(ProductsOverviewContext);
  return (
    <Box>
      <Paper sx={{ p: "10px", mb: "10px" }}>
        <Typography variant="h6">{t(Translations.PAGE_PROJECT_STORE_PRODUCTS_TITLE)}</Typography>
      </Paper>
      <ProductsWrapper>
        {displayMode === DisplayMode.LIST ? <ProductsListView enableActions /> : <ProductsGridView />}
      </ProductsWrapper>
    </Box>
  );
};

export default ProductsOverview;
