import useProjectStore from "pages/Dashboard/hooks/useProjectStore";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useAppSelector, dispatch } from "store/hooks";
import { storeSelector, storeMiddleware } from "store/slices/store";

const StoreLayout = () => {
  const { storeId } = useProjectStore();
  const storeData = useAppSelector(storeSelector.store);
  const isStoreLoading = useAppSelector(storeSelector.isStoreLoading);

  useEffect(() => {
    if (!isStoreLoading && storeId && storeId !== storeData?.id) {
      dispatch(storeMiddleware.getStoreDocument({ id: storeId }));
    }
  }, [storeData, isStoreLoading, storeId]);

  return <Outlet />;
};

export default StoreLayout;
