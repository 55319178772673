import { dispatch, useAppSelector } from "store/hooks";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";
import FormBody from "./FormBody";
import FormActions from "./FormActions";
import FormHeader from "./FormHeader";
import { coreMiddleware, coreSelector } from "store/slices/core";
import { getFormData } from "utils/form";
import React from "react";
import BootstrapDialog from "components/modals/common/BootstrapDialog";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";

export interface ProfileDetailsModalProps {}

const ProfileDetailsModal = () => {
  const [t] = useTranslation();
  const { user } = useAppSelector(coreSelector.authentication);

  const handleClose = () => {
    dispatch(viewsMiddleware.closeModal(ModalName.ProfileDetails));
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { firstName, lastName } = getFormData(event);
    dispatch(
      coreMiddleware.updateProfile({
        firstName,
        lastName,
      }),
    );
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby={t(Translations.MODAL_PROFILE_DETAILS_TITLE)}
      PaperProps={{
        component: "form",
        onSubmit,
      }}
      open
    >
      <FormHeader onClose={handleClose} />
      <FormBody user={user!} />
      <FormActions onClose={handleClose} />
    </BootstrapDialog>
  );
};

export default ProfileDetailsModal;
