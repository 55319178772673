import ProjectsList from "../components/ProjectsList";
import useProject from "pages/Dashboard/hooks/useProject";
import NavigationListWrapper from "./NavigationListWrapper";

const DashboardNavigation = () => {
  const { PROJECT } = useProject();
  return !PROJECT ? (
    <NavigationListWrapper>
      <ProjectsList />
    </NavigationListWrapper>
  ) : null;
};

export default DashboardNavigation;
