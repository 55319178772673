import { Backdrop, Box, CircularProgress } from "@mui/material";

interface GlobalLoadingProps {
  show: boolean;
  handleClose?: () => void;
}
const GlobalLoading = ({ show, handleClose }: GlobalLoadingProps) => {
  return (
    <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={show} onClick={handleClose}>
      <Box justifyItems="center" alignContent="center">
        <CircularProgress color="inherit" />
      </Box>
    </Backdrop>
  );
};

export default GlobalLoading;
