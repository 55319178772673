import { DialogTitle, IconButton } from "@mui/material";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { useContext } from "react";
import StoreTagContext from "./context/StoreTagContext";
import { useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";

interface FormHeaderProps {
  onClose: () => void;
}
const FormHeader = ({ onClose }: FormHeaderProps) => {
  const [t] = useTranslation();
  const isLoading = useAppSelector(storeSelector.isTagLoading);
  const { tagId } = useContext(StoreTagContext);

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {t(tagId ? Translations.MODAL_EDIT_TAG_TITLE : Translations.MODAL_CREATE_TAG_TITLE)}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
        disabled={isLoading}
      >
        <CloseIcon />
      </IconButton>
    </>
  );
};

export default FormHeader;
