import { type PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { type UserState, type Permission } from "store/types/core";

interface ProtectedRouteProps extends PropsWithChildren {
  user: UserState | null;
  permissions: Permission[];
}

const ProtectedRoute = ({ user, permissions, children }: ProtectedRouteProps) => {
  return user ? <>{children}</> : <Navigate to={"/home"} replace />;
};

export default ProtectedRoute;
