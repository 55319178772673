import { useCallback, useContext, useMemo } from "react";
import StoreProductContext from "../../context/StoreProductContext";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { Translations } from "constants/translations";
import { t } from "i18next";
import CodeIcon from "@mui/icons-material/Code";
interface CommandFieldProps {
  index: number;
}

const options = [
  {
    id: true,
    title: t(Translations.MODAL_CREATE_PRODUCT_FIELD_COMMAND_ONLINE_OPTION_TRUE),
  },
  {
    id: false,
    title: t(Translations.MODAL_CREATE_PRODUCT_FIELD_COMMAND_ONLINE_OPTION_FALSE),
  },
];

const CommandFields = ({ index }: CommandFieldProps) => {
  const { commands, setCommands } = useContext(StoreProductContext);
  const { command, userOnlineRequired } = useMemo(() => commands[index], [commands, index]);

  const selectedOption = useMemo(
    () => options.find((option) => option.id === userOnlineRequired) ?? options[0],
    [userOnlineRequired],
  );
  const updateCommand = useCallback(
    (updatedValue: string) => {
      const newCommands = [...commands];
      const targetCommand = newCommands[index];
      targetCommand.command = updatedValue;
      setCommands(newCommands);
    },
    [index, commands, setCommands],
  );

  const updateOnlineType = useCallback(
    (onlineType: boolean) => {
      const newCommands = [...commands];
      const targetCommand = newCommands[index];
      targetCommand.userOnlineRequired = onlineType;
      setCommands(newCommands);
    },
    [index, commands, setCommands],
  );

  return (
    <Grid container item xs={12}>
      <Grid item xs={4}>
        <Autocomplete
          options={options}
          getOptionLabel={(option) => option.title}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t(Translations.MODAL_CREATE_PRODUCT_FIELD_COMMAND_ONLINE)}
              variant="outlined"
            />
          )}
          value={selectedOption}
          onChange={(_, newValue) => {
            updateOnlineType(!!newValue);
          }}
          sx={{ mt: 1 }}
        />
      </Grid>
      <Grid item xs={8}>
        <TextField
          autoFocus
          required
          margin="dense"
          key={Translations.MODAL_CREATE_PRODUCT_FIELD_COMMAND}
          name={Translations.MODAL_CREATE_PRODUCT_FIELD_COMMAND}
          label={t(Translations.MODAL_CREATE_PRODUCT_FIELD_COMMAND)}
          fullWidth
          variant="outlined"
          value={command}
          InputProps={{
            startAdornment: <CodeIcon sx={{ mr: 1 }} />,
          }}
          onChange={(_) => {
            updateCommand(_.target.value);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default CommandFields;
