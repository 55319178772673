import { Dialog, DialogTitle } from "@mui/material";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";

import { dispatch, useAppSelector } from "store/hooks";
import { coreMiddleware, coreSelector } from "store/slices/core";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";
import { getFormData } from "utils/form";
import FormBody from "./FormBody";
import FormActions from "./FormActions";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export interface SignUpModalProps {}

const SignUpModal = (props: SignUpModalProps) => {
  const [t] = useTranslation();
  const [confirmPasswordError, setConfirmPasswordError] = useState<string | null>(null);
  const { isLoading, user } = useAppSelector(coreSelector.authentication);
  const navigate = useNavigate();

  const handleClose = useCallback(
    (_?: any, reason?: string) => {
      if (isLoading && (reason === "backdropClick" || reason === "escapeKeyDown")) {
        return;
      }
      dispatch(viewsMiddleware.closeModal(ModalName.SignUpModal));
    },
    [isLoading],
  );

  useEffect(() => {
    if (!!user) {
      navigate("/dashboard");
      handleClose();
    }
  }, [handleClose, user, navigate]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { firstName, lastName, password, confirmPassword, email } = getFormData(event);
    if (password !== confirmPassword) {
      setConfirmPasswordError(`Passwords doesn't match`);
    } else {
      dispatch(
        coreMiddleware.signUp({
          firstName,
          lastName,
          password,
          email,
        }),
      );
    }
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit,
      }}
    >
      <DialogTitle>{t(Translations.MODAL_SIGN_UP_TITLE)}</DialogTitle>
      <FormBody confirmPasswordError={confirmPasswordError} setConfirmPasswordError={setConfirmPasswordError} />
      <FormActions onClose={handleClose} />
    </Dialog>
  );
};

export default SignUpModal;
