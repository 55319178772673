import DashboardIcon from "@mui/icons-material/Dashboard";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import List from "@mui/material/List";
import { Translations } from "constants/translations";
import DrawerListItem from "../common/DrawerListItem";
import { type PropsWithChildren } from "react";

interface NavigationListWrapperProps extends PropsWithChildren {
  previousNavigationDetails?: PreviousNavigationDetails;
}

interface PreviousNavigationDetails {
  title: string;
  path: string;
}

const NavigationListWrapper = ({ previousNavigationDetails, children }: NavigationListWrapperProps) => {
  return (
    <List
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <DrawerListItem
        icon={<DashboardIcon />}
        title={Translations.LAYOUT_DASHBOARD_NAVIGATION_DASHBOARD}
        path="/dashboard"
      />
      {previousNavigationDetails ? (
        <DrawerListItem
          icon={<KeyboardBackspaceIcon />}
          title={previousNavigationDetails.title}
          path={previousNavigationDetails.path}
        />
      ) : null}
      <>{children}</>
    </List>
  );
};

export default NavigationListWrapper;
