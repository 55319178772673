import React, { type PropsWithChildren } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline, createTheme } from "@mui/material";
import { Provider } from "react-redux";
import store from "store/store";

const host = window.location.host;
const isLocalHost = host.includes("localhost") || host.includes("127.0.0.1");

const StrictModeWrapper = ({ children }: PropsWithChildren) => {
  return isLocalHost ? <React.StrictMode>{children}</React.StrictMode> : <>{children}</>;
};

const getRootDiv = () => {
  const rootId = "root";
  const existingRoot = document.getElementById(rootId);
  if (!!existingRoot) {
    return existingRoot;
  } else {
    const newRoot = document.createElement("div");
    newRoot.setAttribute("id", rootId);
    document.append(newRoot);
    return newRoot;
  }
};

const root = ReactDOM.createRoot(getRootDiv());

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

root.render(
  <StrictModeWrapper>
    <ThemeProvider theme={darkTheme}>
      <Provider store={store}>
        <CssBaseline enableColorScheme />
        <App />
      </Provider>
    </ThemeProvider>
  </StrictModeWrapper>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
