import { dispatch } from "store/hooks";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";
import BootstrapDialog from "../../common/BootstrapDialog";
import FormBody from "./FormBody";
import FormActions from "./FormActions";
import FormHeader from "./FormHeader";
import React, { useCallback } from "react";

export interface ConfirmationModalProps {
  title: string;
  subtitle: string;
  confirmationCallback: () => void;
  cancellationCallback?: () => void;
}

const ConfirmationModal = ({ title, subtitle, confirmationCallback, cancellationCallback }: ConfirmationModalProps) => {
  const handleClose = useCallback(() => {
    cancellationCallback?.();
    dispatch(viewsMiddleware.closeModal(ModalName.ConfirmationModal));
  }, [cancellationCallback]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(viewsMiddleware.closeModal(ModalName.ConfirmationModal));
    confirmationCallback();
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby={title}
      PaperProps={{
        component: "form",
        onSubmit,
        sx: { minWidth: "30%" },
      }}
      open
    >
      <FormHeader title={title} onClose={handleClose} />
      <FormBody subtitle={subtitle} />
      <FormActions onClose={handleClose} />
    </BootstrapDialog>
  );
};

export default ConfirmationModal;
