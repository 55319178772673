import { Language } from "dataLayer/types";
import { type PropsWithChildren, useState } from "react";
import MultilingualFieldContext from "./MultilingualFieldContext";

interface MultilingualFieldContextProviderProps extends PropsWithChildren {
  supportedLanguages?: Language[];
}

const MultilingualFieldContextProvider = ({ children, supportedLanguages }: MultilingualFieldContextProviderProps) => {
  const [language, setLanguage] = useState<Language>(Language.en);

  return (
    <MultilingualFieldContext.Provider value={{ language, setLanguage, supportedLanguages }}>
      {children}
    </MultilingualFieldContext.Provider>
  );
};

export default MultilingualFieldContextProvider;
