import { type PropsWithChildren, useReducer, useState } from "react";
import StoreTagContext, { type IStoreTagContextFields } from "./StoreTagContext";
import { getEmptyMultilingualString } from "utils/language";
import multiLingualStringReducer from "utils/multilingualUtils";

interface StoreTagContextProviderProps extends PropsWithChildren {
  storeId: string;
  tagId: string | null;
  defaultValues: IStoreTagContextFields | null;
}

const StoreTagContextProvider = ({ storeId, tagId, defaultValues, children }: StoreTagContextProviderProps) => {
  const [title, setTitle] = useReducer(multiLingualStringReducer, defaultValues?.title ?? getEmptyMultilingualString());
  const [items, setItems] = useState<string[]>(defaultValues?.items ?? []);

  return (
    <StoreTagContext.Provider
      value={{
        storeId,
        tagId,
        title,
        setTitle,
        items,
        setItems,
      }}
    >
      {children}
    </StoreTagContext.Provider>
  );
};

export default StoreTagContextProvider;
