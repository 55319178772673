import { Typography, Link } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

export interface BreadCrumbItemProps {
  key: string;
  title: string;
  href?: string;
  selected?: boolean;
}

const BreadCrumbItem = ({ title, href }: BreadCrumbItemProps) => {
  const [t] = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const isActive = href === location.pathname;
  const isLink = href && !isActive;

  const onLinkClick = useCallback(() => {
    navigate(href!, {
      relative: "path",
    });
  }, [href, navigate]);

  return isLink ? (
    <Link underline="hover" color="inherit" href={href ? "" : undefined} onClick={href ? onLinkClick : undefined}>
      {t(title)}
    </Link>
  ) : (
    <Typography color={isActive ? "text.primary" : "inherit"}>{t(title)}</Typography>
  );
};

export default BreadCrumbItem;
