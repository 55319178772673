import { TextField } from "@mui/material";
import { Translations } from "constants/translations";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import MultilingualFieldContext from "components/common/MultilingualFieldWrapper/context/MultilingualFieldContext";
import StoreSettingsContext from "components/Dashboard/ProjectStore/SettingsOverview/context/StoreSettingsContext";
import { getMultilingualContentValue } from "utils/language";

const SettingsStoreTitleField = () => {
  const [t] = useTranslation();
  const { storeTitle, setStoreTitle } = useContext(StoreSettingsContext);
  const { language } = useContext(MultilingualFieldContext);

  const storeTitleValue = useMemo(() => getMultilingualContentValue(language, storeTitle), [language, storeTitle]);

  return (
    <TextField
      autoFocus
      required
      margin="dense"
      key={Translations.MODAL_CREATE_STORE_FORM_FIELD_TITLE}
      name="storeTitle"
      label={t(Translations.MODAL_CREATE_STORE_FORM_FIELD_TITLE)}
      type="text"
      fullWidth
      variant="outlined"
      defaultValue={storeTitleValue}
      onChange={(_) => {
        setStoreTitle({ language, value: _.target.value });
      }}
    />
  );
};

export default SettingsStoreTitleField;
