import { type PropsWithChildren, useState, useCallback, useMemo } from "react";
import DashboardDrawerContext from "./DrawerContext";

const DrawerContextProvider = ({ children }: PropsWithChildren) => {
  const [fixedAsOpen, setFixedAsOpen] = useState<boolean>(true);
  const [mouseOver, setMouseOver] = useState<boolean>(false);

  const drawerOpen = useMemo(() => fixedAsOpen || mouseOver, [fixedAsOpen, mouseOver]);

  const toggleDrawer = useCallback(() => {
    setFixedAsOpen(!fixedAsOpen);
  }, [fixedAsOpen]);

  return (
    <DashboardDrawerContext.Provider
      value={{
        drawerOpen,
        fixedAsOpen,
        setFixedAsOpen,
        mouseOver,
        setMouseOver,
        toggleDrawer,
      }}
    >
      {children}
    </DashboardDrawerContext.Provider>
  );
};

export default DrawerContextProvider;
