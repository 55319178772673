const ipV4Regex = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
const portRegex = /^([1-9][0-9]{0,4}|[1-5][0-9]{5})$/;

export const ipV4Checker = (value: string) => {
  return ipV4Regex.test(value);
};

export const portChecker = (value: string) => {
  return portRegex.test(value);
};
