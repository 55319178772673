import { type ConfirmationModalProps } from "components/modals/shared/ConfirmationModal";
import { Translations } from "constants/translations";
import { type DB_Record } from "dataLayer/types";
import { type IContextActionBinding } from "hooks/useContextMenuAction";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { dispatch } from "store/hooks";
import { storeMiddleware } from "store/slices/store";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";

enum CategoryAction {
  REMOVE = "remove",
  EDIT = "edit",
}

const useCategoryListActions = (storeId: string) => {
  const [t] = useTranslation();

  const onRemove = useCallback(
    (categoryId: string) => {
      const props: ConfirmationModalProps = {
        title: t(Translations.MODAL_CONFIRMATION_TITLE_REMOVE_CATEGORY),
        subtitle: t(Translations.MODAL_CONFIRMATION_SUBTITLE_REMOVE_CATEGORY),
        confirmationCallback: () => {
          dispatch(
            storeMiddleware.removeStoreCategory({
              storeId,
              categoryId,
            }),
          );
        },
      };
      dispatch(
        viewsMiddleware.openModal({
          name: ModalName.ConfirmationModal,
          props,
        }),
      );
    },
    [storeId, t],
  );

  const onEdit = useCallback(
    (categoryId: string) => {
      dispatch(
        viewsMiddleware.openModal({
          name: ModalName.StoreCategoryModal,
          props: {
            storeId,
            categoryId,
          },
        }),
      );
    },
    [storeId],
  );

  return (rowData: DB_Record): IContextActionBinding[] => {
    return [
      {
        id: CategoryAction.REMOVE,
        title: t(Translations.CONTEXT_ACTION_CATEGORY_REMOVE),
        actionCallback: () => {
          onRemove(rowData.id);
        },
      },
      {
        id: CategoryAction.EDIT,
        title: t(Translations.CONTEXT_ACTION_CATEGORY_EDIT),
        actionCallback: () => {
          onEdit(rowData.id);
        },
      },
    ];
  };
};

export default useCategoryListActions;
