import { Grid, Typography } from "@mui/material";
import StoreTypeCard from "./StoreTypeCard";
import StoreTypeSoonCard from "./StoreTypeSoonCard";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";

const GameSelectionStep = () => {
  const [t] = useTranslation();
  const storeTypes = useAppSelector(storeSelector.storeTypes);

  const activeStoreTypes = storeTypes?.filter((storeType) => !storeType.upcoming) ?? [];
  const upcomingStoreTypes = storeTypes?.filter((storeType) => storeType.upcoming) ?? [];

  return (
    <Grid container rowSpacing={5}>
      <Grid item container>
        <Grid item xs={12}>
          <Typography variant="h5">{t(Translations.MODAL_CREATE_STORE_TYPE_SELECTION_TITLE)}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">{t(Translations.MODAL_CREATE_STORE_TYPE_SELECTION_SUBTITLE)}</Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12} rowSpacing={5} spacing={3}>
        {activeStoreTypes.map((storeTypeCard) => (
          <Grid item container xs={3} key={storeTypeCard.id}>
            <StoreTypeCard config={storeTypeCard} />
          </Grid>
        ))}
        {upcomingStoreTypes.map((storeTypeCard) => (
          <Grid item container xs={3} key={storeTypeCard.id}>
            <StoreTypeSoonCard config={storeTypeCard} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default GameSelectionStep;
