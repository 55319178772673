import { type AppDispatch } from "store/store";
import slice from "./slice";
import { type PortalCreateProjectBody } from "dataLayer/types/projectEndpoints";
import FirebaseManager from "dataLayer/FirebaseManager";
import { viewsMiddleware } from "../views";
import { ModalName, SeveritiesType } from "store/types/views";
import { Translations } from "constants/translations";

const { setIsProjectCreateLoading, setUserProjects, updateUserProjects, updateUserSingleProject } = slice.actions;

const createProject = (data: PortalCreateProjectBody) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsProjectCreateLoading(true));
    const project = await FirebaseManager.API.Portal.createProject(data);
    dispatch(updateUserProjects([project.data]));
    dispatch(viewsMiddleware.closeModal(ModalName.CreateProjectModal));
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.success,
          message: Translations.TOAST_SUCCESS_PROJECT_CREATE,
        },
      }),
    );
  } catch (error) {
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.error,
          message: Translations.TOAST_ERROR_PROJECT_CREATE,
        },
      }),
    );
  } finally {
    dispatch(setIsProjectCreateLoading(false));
  }
};

export default { setUserProjects, createProject, updateUserSingleProject };
