import { Button } from "@mui/material";
import { type ReactNode } from "react";

interface TextButtonProps {
  title: string;
  onClick: () => void;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
}
const TextButton = ({ onClick, title, ...rest }: TextButtonProps) => {
  return (
    <Button variant="text" onClick={onClick} {...rest}>
      {title}
    </Button>
  );
};

export default TextButton;
