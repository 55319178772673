import { contextSetterImitator } from "constants/context";
import { type MultilingualString } from "dataLayer/types";
import {
  type ProductCommandConfig,
  type AdditionalProductConfig,
  type StoreProductLimit,
  type DB_StoreProductDocument,
} from "dataLayer/types/storeProductEndpoints";
import { type Dispatch, type SetStateAction, createContext } from "react";
import { getEmptyMultilingualString } from "utils/language";
import { type ISetMultilingualContentPayload } from "utils/multilingualUtils";

export interface IStoreProductContextFields {
  title: MultilingualString;
  description: MultilingualString;
  image: string | null;
  subItems: string[];
  commands: ProductCommandConfig[];
  categories: string[];
  price: string;
  visibility: boolean;
  globalLimit: StoreProductLimit | null;
  userLimit: StoreProductLimit | null;
  gifting: boolean;
  alternativeProducts: string[] | null;
  additionalProduct: AdditionalProductConfig | null;
}

export interface IStoreProductContext extends IStoreProductContextFields {
  productId: string | null;
  setTitle: Dispatch<ISetMultilingualContentPayload>;
  setDescription: Dispatch<ISetMultilingualContentPayload>;
  setImage: Dispatch<SetStateAction<string | null>>;
  setSubItems: Dispatch<SetStateAction<string[]>>;
  setCommands: Dispatch<SetStateAction<ProductCommandConfig[]>>;
  setCategories: Dispatch<SetStateAction<string[]>>;
  setPrice: Dispatch<SetStateAction<string>>;
  setVisibility: Dispatch<SetStateAction<boolean>>;
  setGlobalLimit: Dispatch<SetStateAction<StoreProductLimit | null>>;
  setUserLimit: Dispatch<SetStateAction<StoreProductLimit | null>>;
  setGifting: Dispatch<SetStateAction<boolean>>;
  setAlternativeProducts: Dispatch<SetStateAction<string[] | null>>;
  setAdditionalProduct: Dispatch<SetStateAction<AdditionalProductConfig | null>>;
  prefillFields: (product: DB_StoreProductDocument | null) => void;
}

const StoreProductContext = createContext<IStoreProductContext>({
  productId: null,
  title: getEmptyMultilingualString(),
  description: getEmptyMultilingualString(),
  image: null,
  subItems: [],
  commands: [],
  categories: [],
  price: "0",
  visibility: false,
  globalLimit: null,
  userLimit: null,
  gifting: false,
  alternativeProducts: [],
  additionalProduct: null,
  setTitle: contextSetterImitator,
  setDescription: contextSetterImitator,
  setImage: contextSetterImitator,
  setSubItems: contextSetterImitator,
  setCommands: contextSetterImitator,
  setCategories: contextSetterImitator,
  setPrice: contextSetterImitator,
  setVisibility: contextSetterImitator,
  setGlobalLimit: contextSetterImitator,
  setUserLimit: contextSetterImitator,
  setGifting: contextSetterImitator,
  setAlternativeProducts: contextSetterImitator,
  setAdditionalProduct: contextSetterImitator,
  prefillFields: contextSetterImitator,
});

export default StoreProductContext;
