import { DialogContent, DialogContentText, Grid } from "@mui/material";
import DataTable, { type ColumnData } from "components/common/DataTable";
import { Translations } from "constants/translations";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { coreSelector } from "store/slices/core";

interface FormBodyProps {
  isDomain: boolean;
  url: string;
}

interface ITableRowData {
  id: string;
  host: string;
  value: string;
  priority: string;
}

const columns: Array<ColumnData<ITableRowData>> = [
  {
    dataKey: "id",
    label: Translations.MODAL_DNS_RECORDS_DNS_TABLE_LABEL_TYPE,
  },
  {
    dataKey: "host",
    label: Translations.MODAL_DNS_RECORDS_DNS_TABLE_LABEL_HOST,
  },
  {
    dataKey: "value",
    label: Translations.MODAL_DNS_RECORDS_DNS_TABLE_LABEL_VALUE,
  },
  {
    dataKey: "priority",
    label: Translations.MODAL_DNS_RECORDS_DNS_TABLE_LABEL_PRIORITY,
  },
];

const FormBody = ({ isDomain, url }: FormBodyProps) => {
  const [t] = useTranslation();
  const dnsRecords = useAppSelector(coreSelector.dnsRecords);

  const tableRows: ITableRowData[] = useMemo(() => {
    if (dnsRecords.isLoading) {
      return [];
    }
    if (isDomain) {
      const recordA: ITableRowData = {
        id: Translations.MODAL_DNS_RECORDS_DNS_TYPE_A,
        host: url,
        value: dnsRecords.domain?.A?.[0].value ?? "-",
        priority: `${dnsRecords.domain?.A?.[0].priority}` ?? "-",
      };
      const recordTXT: ITableRowData = {
        id: Translations.MODAL_DNS_RECORDS_DNS_TYPE_TXT,
        host: url,
        value: dnsRecords.domain?.TXT?.[0].value ?? "-",
        priority: `${dnsRecords.domain?.TXT?.[0].priority}` ?? "-",
      };
      return [recordA, recordTXT];
    } else {
      const recordCNAME: ITableRowData = {
        id: Translations.MODAL_DNS_RECORDS_DNS_TYPE_CNAME,
        host: url,
        value: dnsRecords.subdomain?.CNAME?.[0].value ?? "-",
        priority: `${dnsRecords.subdomain?.CNAME?.[0].priority}` ?? "-",
      };
      return [recordCNAME];
    }
  }, [isDomain, dnsRecords, url]);

  return (
    <DialogContent dividers>
      <Grid container>
        <Grid item sx={{ pb: "14px" }}>
          <DialogContentText>{t(Translations.MODAL_DNS_RECORDS_SUBTITLE)}</DialogContentText>
        </Grid>
        <Grid item sx={{ pb: "14px" }}>
          <DialogContentText>{t(Translations.MODAL_DNS_RECORDS_WARNING)}</DialogContentText>
        </Grid>
        <Grid item sx={{ pb: "14px" }}>
          <DialogContentText>{t(Translations.MODAL_DNS_RECORDS_INFO)}</DialogContentText>
        </Grid>
        <Grid container>
          <DataTable data={tableRows} columns={columns} />
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default FormBody;
