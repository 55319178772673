import { Dialog } from "@mui/material";

import { dispatch, useAppSelector } from "store/hooks";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";
import FormBody from "./FormBody";
import React, { useCallback, useEffect } from "react";
import FormHeader from "./FormHeader";
import StoreModalContextProvider from "./context/StoreModalContextProvider";
import { storeMiddleware, storeSelector } from "store/slices/store";

export interface StoreModalProps {}

const StoreModal = (props: StoreModalProps) => {
  const isStoreCreateLoading = useAppSelector(storeSelector.isStoreCreateLoading);
  const isStoreUpdateLoading = useAppSelector(storeSelector.isStoreUpdateLoading);

  const isLoading = isStoreCreateLoading || isStoreUpdateLoading;

  const handleClose = useCallback(
    (_: React.SyntheticEvent, reason?: string) => {
      if (isLoading) {
        return;
      }
      dispatch(viewsMiddleware.closeModal(ModalName.StoreModal));
    },
    [isLoading],
  );

  const onSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  }, []);

  useEffect(() => {
    dispatch(storeMiddleware.resetSubdomainCheck());
    return () => {
      dispatch(storeMiddleware.resetSubdomainCheck());
    };
  }, []);

  return (
    <StoreModalContextProvider>
      <Dialog
        open
        fullWidth
        maxWidth="lg"
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit,
        }}
      >
        <FormHeader onClose={handleClose} />
        <FormBody />
      </Dialog>
    </StoreModalContextProvider>
  );
};

export default StoreModal;
