import { Paper } from "@mui/material";
import { type PropsWithChildren } from "react";

const FieldPaper = ({ children }: PropsWithChildren) => {
  return (
    <Paper sx={{ width: "100%", pl: 2, pr: 1, py: 2 }} variant={"outlined"}>
      {children}
    </Paper>
  );
};

export default FieldPaper;
