import { type DB_StoreCategoryDocument } from "dataLayer/types/storeCategoryEndpoints";
import { useCallback, useMemo } from "react";
import { useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";

const useCategoriesHook = () => {
  const store = useAppSelector(storeSelector.store);
  const categories: DB_StoreCategoryDocument[] = useMemo(() => store?.entities.categories ?? [], [store?.entities]);

  const getCategoryById = useCallback(
    (categoryId: string) => categories.find((category) => category.id === categoryId) ?? null,
    [categories],
  );

  const getProductCategories = useCallback(
    (productId: string | null) => {
      return productId ? categories.filter((category) => category.items.includes(productId)) : [];
    },
    [categories],
  );

  return {
    categories,
    getProductCategories,
    getCategoryById,
  };
};

export default useCategoriesHook;
