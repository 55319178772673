import { contextSetterImitator } from "constants/context";
import { type Dispatch, type SetStateAction, createContext } from "react";

export interface IAccordionListContextFields {
  expandedItemId: string | null;
}
export interface IAccordionListContext extends IAccordionListContextFields {
  setExpandedItemId: Dispatch<SetStateAction<string | null>>;
}

const AccordionListContext = createContext<IAccordionListContext>({
  expandedItemId: null,
  setExpandedItemId: contextSetterImitator,
});

export default AccordionListContext;
