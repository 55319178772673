import { useCallback, useContext } from "react";
import CommandFields from "../fields/CommandFields";
import FieldPaper from "../../../../common/FieldPaper";
import StoreProductContext from "../../context/StoreProductContext";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { Translations } from "constants/translations";

const ProductCommandsSection = () => {
  const [t] = useTranslation();
  const { commands, setCommands, subItems } = useContext(StoreProductContext);

  const isEnabled = !subItems.length;

  const onRemoveCommand = useCallback(
    (index: number) => {
      const newCommands = [...commands];
      newCommands.splice(index, 1);
      setCommands(newCommands);
    },
    [commands, setCommands],
  );

  const onAddCommand = useCallback(() => {
    setCommands([
      ...commands,
      {
        command: "",
        userOnlineRequired: true,
      },
    ]);
  }, [commands, setCommands]);

  return (
    <FieldPaper>
      {isEnabled ? (
        <Grid container item xs={12} direction={"column"} spacing={1}>
          {commands.map((value, index) => (
            <Grid key={`${value.command}-${index}`} container item alignItems={"center"}>
              <Grid container item xs={11}>
                <CommandFields key={`${value.command}-${index}`} index={index} />
              </Grid>
              <Grid container item xs={1} justifyContent={"flex-end"}>
                <IconButton
                  key={`${value.command}-${index}`}
                  onClick={() => {
                    onRemoveCommand(index);
                  }}
                >
                  <CloseIcon color="error" />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Grid item justifyContent={"center"}>
            <Button fullWidth onClick={onAddCommand}>
              Add Command
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid container item xs={12}>
          <Typography>{t(Translations.MODAL_CREATE_PRODUCT_FIELD_COMMAND_DISABLED)}</Typography>
        </Grid>
      )}
    </FieldPaper>
  );
};

export default ProductCommandsSection;
