import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Grid, Paper } from "@mui/material";
import ProjectBreadcrumbTitle from "./ProjectBreadcrumbTitle";
import useDashboardBreadcrumbItems from "hooks/useDashboardBreadcrumbItems";
import BreadCrumbItem from "./ProjectBreadcrumbItem";

const ProjectBreadcrumb = () => {
  const breadCrumbItems = useDashboardBreadcrumbItems();

  return (
    <>
      <Paper sx={{ width: "100%", p: "10px", mb: "10px" }}>
        <Grid container justifyContent={"space-between"}>
          <ProjectBreadcrumbTitle />
          <Grid item role="presentation" alignContent={"center"} sx={{ my: "auto" }}>
            <Breadcrumbs aria-label="breadcrumb">
              {breadCrumbItems.map((breadcrumbItemData) => (
                <BreadCrumbItem
                  key={breadcrumbItemData.key}
                  title={breadcrumbItemData.title}
                  href={breadcrumbItemData.href}
                />
              ))}
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default ProjectBreadcrumb;
