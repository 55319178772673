import { dispatch, useAppSelector } from "store/hooks";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";
import BootstrapDialog from "../../common/BootstrapDialog";
import FormBody from "./FormBody";
import FormActions from "./FormActions";
import FormHeader from "./FormHeader";
import React, { useCallback, useEffect, useMemo } from "react";
import { isRootDomain } from "utils/urlUtils";
import { coreMiddleware, coreSelector } from "store/slices/core";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";

export interface ShowDnsRecordsModalProps {
  url: string;
}

const ShowDnsRecordsModal = ({ url }: ShowDnsRecordsModalProps) => {
  const [t] = useTranslation();
  const dnsRecords = useAppSelector(coreSelector.dnsRecords);
  const isDomain = useMemo(() => isRootDomain(url), [url]);

  useEffect(() => {
    const shouldFetchDomainRecords = isDomain && !dnsRecords.domain;
    const shouldFetchSubdomainRecords = !isDomain && !dnsRecords.subdomain;
    if (shouldFetchDomainRecords || shouldFetchSubdomainRecords) {
      dispatch(coreMiddleware.getDnsRecords(url));
    }
  }, [url, isDomain, dnsRecords]);

  const handleClose = useCallback(() => {
    dispatch(viewsMiddleware.closeModal(ModalName.ShowDnsRecordsModal));
  }, []);

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby={t(Translations.MODAL_DNS_RECORDS_TITLE)}
      PaperProps={{
        component: "form",
        sx: { minWidth: "50%" },
      }}
      open
    >
      <FormHeader onClose={handleClose} />
      <FormBody isDomain={isDomain} url={url} />
      <FormActions onClose={handleClose} />
    </BootstrapDialog>
  );
};

export default ShowDnsRecordsModal;
