import { Grid, Chip } from "@mui/material";
import { Translations } from "constants/translations";
import { ServerConnectionStatus } from "dataLayer/types/storeEndpoints";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";

const getColorByStatus = (status: ServerConnectionStatus) => {
  switch (status) {
    case ServerConnectionStatus.CONNECTED:
      return "success";
    case ServerConnectionStatus.DISCONNECTED:
      return "error";
    case ServerConnectionStatus.PENDING:
      return "warning";
    default:
      return "default";
  }
};

const StoreServerConnection = () => {
  const [t] = useTranslation();
  const storeDetails = useAppSelector(storeSelector.store)!;
  const connectionStatus =
    storeDetails.settings.serverConfiguration?.connectionStatus ?? ServerConnectionStatus.DISCONNECTED;
  const connectionStatusKey = connectionStatus.toLowerCase();

  const onClick = useCallback(() => {
    console.log("connectionStatus", connectionStatus);
  }, [connectionStatus]);

  useEffect(() => {
    onClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container sx={{ p: "10px" }} justifyContent={"flex-end"}>
      <Grid item xs={2} alignContent={"end"}>
        <Chip
          label={t(`${Translations.PAGE_PROJECT_STORE_SETTINGS_TAB_SERVER_CONNECTION_STATUS}.${connectionStatusKey}`)}
          color={getColorByStatus(connectionStatus)}
          variant="outlined"
          onClick={onClick}
        />
      </Grid>
    </Grid>
  );
};

export default StoreServerConnection;
