import { dispatch, useAppSelector } from "store/hooks";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";
import BootstrapDialog from "../../common/BootstrapDialog";
import FormBody from "./FormBody";
import FormActions from "./FormActions";
import FormHeader from "./FormHeader";
import { getFormData } from "utils/form";
import { dashboardMiddleware, dashboardSelector } from "store/slices/dashboard";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Translations } from "constants/translations";

export interface CreateProjectModalProps {}

const CreateProjectModal = () => {
  const [t] = useTranslation();
  const { isCreateLoading } = useAppSelector(dashboardSelector.userProjects);

  const handleClose = useCallback(
    (_: React.SyntheticEvent) => {
      if (isCreateLoading) {
        return;
      }
      dispatch(viewsMiddleware.closeModal(ModalName.CreateProjectModal));
    },
    [isCreateLoading],
  );

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { title, icon } = getFormData(event);
    dispatch(
      dashboardMiddleware.createProject({
        title,
        icon: icon ?? null,
      }),
    );
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby={t(Translations.MODAL_CREATE_PROJECT_TITLE)}
      PaperProps={{
        component: "form",
        onSubmit,
      }}
      open
    >
      <FormHeader onClose={handleClose} />
      <FormBody />
      <FormActions onClose={handleClose} />
    </BootstrapDialog>
  );
};

export default CreateProjectModal;
