import React from "react";
import { CheckCircleOutlineTwoTone, ReportTwoTone } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, IconButton, Typography } from "@mui/material";

import Toast from "./styled/Toast";
import { useAppSelector, dispatch } from "store/hooks";
import { viewsSelector, viewsMiddleware } from "store/slices/views";
import { useTranslation } from "react-i18next";

export const ToastNotification = () => {
  const [t] = useTranslation();
  const toastNotificationPopUp = useAppSelector(viewsSelector.toastNotificationPopUp);

  const { severityType, message, messageObject } = toastNotificationPopUp.props!;

  const onClose = () => {
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: false,
        props: null,
      }),
    );
  };

  return (
    <Toast
      open
      autoHideDuration={severityType === "error" ? 6000 : 3000}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <div>
        <Alert
          variant="filled"
          severity={severityType}
          icon={severityType === "error" ? <ReportTwoTone /> : <CheckCircleOutlineTwoTone />}
          className="alert-banner"
          action={
            <IconButton
              aria-label="close"
              sx={{
                fontSize: (theme) => theme.typography.pxToRem(24),
              }}
              onClick={onClose}
            >
              <CloseIcon fontSize="inherit" sx={{ color: (theme) => theme.palette.common.white }} />
            </IconButton>
          }
        >
          <Typography sx={{ fontWeight: 500, color: (theme) => theme.palette.common.white }} component="div">
            {t(message, messageObject)}
          </Typography>
        </Alert>
      </div>
    </Toast>
  );
};
