import { combineReducers } from "redux";

import { coreSlice } from "./slices/core";
import { viewsSlice } from "./slices/views";
import { dashboardSlice } from "./slices/dashboard";
import { storeSlice } from "./slices/store";

const reducer = combineReducers({
  core: coreSlice.reducer,
  views: viewsSlice.reducer,
  dashboard: dashboardSlice.reducer,
  store: storeSlice.reducer,
});

export default reducer;
