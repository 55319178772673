import React, { type PropsWithChildren } from "react";
import { type IUnique, Language } from "dataLayer/types";
import MultilingualFieldContextProvider from "./context/MultilingualFieldContextProvider";
import MultilingualFieldWrapperTabs from "./MultilingualFieldWrapperTabs";
import { Translations } from "constants/translations";

const tabs: IUnique[] = [
  { id: Language.en, title: Translations.LANGUAGE_TITLE_EN },
  { id: Language.ru, title: Translations.LANGUAGE_TITLE_RU },
  { id: Language.cn, title: Translations.LANGUAGE_TITLE_CN },
  { id: Language.sp, title: Translations.LANGUAGE_TITLE_SP },
  { id: Language.ar, title: Translations.LANGUAGE_TITLE_AR },
  { id: Language.fr, title: Translations.LANGUAGE_TITLE_FR },
];

const getTabs = (supportedLanguages: Language[] = Object.values(Language)) => {
  return tabs.filter((tab) => supportedLanguages.includes(tab.id as Language));
};

interface MultilingualFieldWrapperProps extends PropsWithChildren {
  supportedLanguages?: Language[];
}

const MultilingualFieldWrapper = ({ supportedLanguages, children }: MultilingualFieldWrapperProps) => {
  const tabs = getTabs(supportedLanguages);
  return (
    <MultilingualFieldContextProvider>
      <MultilingualFieldWrapperTabs tabs={tabs}>{children}</MultilingualFieldWrapperTabs>
    </MultilingualFieldContextProvider>
  );
};

export default MultilingualFieldWrapper;
