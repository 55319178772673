import { Box, Paper, Typography } from "@mui/material";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";
import StoreSettingsWrapper from "./components/StoreSettingsWrapper";
import StoreSettingsContent from "./components/StoreSettingsContent";

const StoreSettingsOverview = () => {
  const [t] = useTranslation();
  return (
    <Box>
      <Paper sx={{ p: "10px", mb: "10px" }}>
        <Typography variant="h6">{t(Translations.PAGE_PROJECT_STORE_SETTINGS_TITLE)}</Typography>
      </Paper>
      <StoreSettingsWrapper>
        <StoreSettingsContent />
      </StoreSettingsWrapper>
    </Box>
  );
};

export default StoreSettingsOverview;
