import { DialogTitle, IconButton } from "@mui/material";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

interface FormHeaderProps {
  onClose: () => void;
}
const FormHeader = ({ onClose }: FormHeaderProps) => {
  const [t] = useTranslation();

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }}>{t(Translations.MODAL_CLONE_PRODUCT_TITLE)}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </>
  );
};

export default FormHeader;
