import { type ConfirmationModalProps } from "components/modals/shared/ConfirmationModal";
import { Translations } from "constants/translations";
import { type DB_Record } from "dataLayer/types";
import useStoreHook from "hooks/dashboardDataHooks/useStoreHook";
import { type IContextActionBinding } from "hooks/useContextMenuAction";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { dispatch } from "store/hooks";
import { storeMiddleware } from "store/slices/store";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";

enum TagAction {
  REMOVE = "remove",
  EDIT = "edit",
}

const useTagListActions = () => {
  const [t] = useTranslation();
  const { store } = useStoreHook();

  const onRemove = useCallback(
    (tagId: string) => {
      const props: ConfirmationModalProps = {
        title: t(Translations.MODAL_CONFIRMATION_TITLE_REMOVE_CATEGORY),
        subtitle: t(Translations.MODAL_CONFIRMATION_SUBTITLE_REMOVE_CATEGORY),
        confirmationCallback: () => {
          dispatch(
            storeMiddleware.removeStoreTag({
              storeId: store!.id,
              tagId,
            }),
          );
        },
      };
      dispatch(
        viewsMiddleware.openModal({
          name: ModalName.ConfirmationModal,
          props,
        }),
      );
    },
    [t, store],
  );

  const onEdit = useCallback(
    (tagId: string) => {
      dispatch(
        viewsMiddleware.openModal({
          name: ModalName.StoreTagModal,
          props: {
            storeTypeId: store!.id,
            tagId,
          },
        }),
      );
    },
    [store],
  );

  return (rowData: DB_Record): IContextActionBinding[] => {
    return [
      {
        id: TagAction.REMOVE,
        title: t(Translations.CONTEXT_ACTION_TAG_REMOVE),
        actionCallback: () => {
          onRemove(rowData.id);
        },
      },
      {
        id: TagAction.EDIT,
        title: t(Translations.CONTEXT_ACTION_TAG_EDIT),
        actionCallback: () => {
          onEdit(rowData.id);
        },
      },
    ];
  };
};

export default useTagListActions;
