import { type IStoreMeta } from "store/types/dashboard";
import ProjectStoreCardView from "./ProjectStoreCardView";
import ProjectStoreCardAdd from "./ProjectStoreCardAdd";
import { Grid } from "@mui/material";

interface ProjectStoreCardProps {
  storeData: IStoreMeta | null;
}

const ProjectStoreCard = ({ storeData }: ProjectStoreCardProps) => {
  return (
    <Grid item xs={4} sx={{ p: "5px" }}>
      {storeData ? <ProjectStoreCardView storeData={storeData} /> : <ProjectStoreCardAdd />}
    </Grid>
  );
};

export default ProjectStoreCard;
