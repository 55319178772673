import { Grid } from "@mui/material";
import { useCallback, useContext } from "react";
import StoreCategoryContext from "../../context/StoreCategoryContext";
import { Translations } from "constants/translations";
import PortalDateTimePicker from "components/modals/common/PortalDateTimePicker";
import { convertToDateOnly } from "utils/date";

const VisibilityConfigFields = () => {
  const { visibilityConfig, setVisibilityConfig } = useContext(StoreCategoryContext);

  const setStartDate = useCallback(
    (startDate: Date | null) => {
      setVisibilityConfig({
        ...visibilityConfig!,
        startDate,
      });
    },
    [visibilityConfig, setVisibilityConfig],
  );

  const setEndDate = useCallback(
    (endDate: Date | null) => {
      setVisibilityConfig({
        ...visibilityConfig!,
        endDate,
      });
    },
    [visibilityConfig, setVisibilityConfig],
  );

  const shouldDisableEndDateDate = useCallback(
    (value: Date) => {
      const valueDateOnlyString = convertToDateOnly(value);
      if (!valueDateOnlyString || !visibilityConfig?.startDate) {
        return false;
      }
      const valueDateOnly = new Date(valueDateOnlyString);
      const startDateOnlyString = convertToDateOnly(visibilityConfig.startDate as Date);
      const startDateOnly = new Date(startDateOnlyString!);
      return valueDateOnly.getTime() < startDateOnly.getTime();
    },
    [visibilityConfig],
  );

  const shouldDisableEndDateTime = useCallback(
    (value: Date) => {
      return value.getTime() <= (visibilityConfig!.startDate as Date).getTime();
    },
    [visibilityConfig],
  );
  return (
    <Grid container item xs={11} spacing={1}>
      <Grid item xs={5}>
        <PortalDateTimePicker
          label={Translations.MODAL_CREATE_CATEGORY_FIELD_VISIBILITY_CONFIG_START}
          value={visibilityConfig!.startDate as Date}
          onChange={setStartDate}
          disablePast
          minutesStep={5}
        />
      </Grid>
      <Grid item xs={5}>
        <PortalDateTimePicker
          label={Translations.MODAL_CREATE_CATEGORY_FIELD_VISIBILITY_CONFIG_END}
          value={visibilityConfig!.endDate as Date}
          onChange={setEndDate}
          shouldDisableDate={shouldDisableEndDateDate}
          shouldDisableTime={shouldDisableEndDateTime}
          disabled={!visibilityConfig?.startDate}
          minutesStep={5}
        />
      </Grid>
    </Grid>
  );
};

export default VisibilityConfigFields;
