import { type SliceCaseReducers } from "@reduxjs/toolkit";
import { type DB_StoreDocument } from "dataLayer/types/storeEndpoints";
import { type IClientStoreType } from "dataLayer/types/storeTypeEndpoints";
import { type IAction } from "store/store";
import { type IStoreSliceState } from "store/types/store";

const createReducer = <T extends SliceCaseReducers<IStoreSliceState>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setStoreLoading(state, action: IAction<string | null>) {
    state.loadingStates.projectStoreInLoading = action.payload;
  },
  setStoreCreateLoading(state, action: IAction<boolean>) {
    state.loadingStates.isStoreCreateLoading = action.payload;
  },
  setStoreUpdateLoading(state, action: IAction<boolean>) {
    state.loadingStates.isStoreUpdateLoading = action.payload;
  },
  setStoreRemoveLoading(state, action: IAction<boolean>) {
    state.loadingStates.isStoreRemoveLoading = action.payload;
  },
  setCurrentStoreData(state, action: IAction<DB_StoreDocument | null>) {
    state.store = action.payload;
  },
  setCurrentStoreLoading(state, action: IAction<boolean>) {
    state.loadingStates.isStoreLoading = action.payload;
  },
  setStoreCategoryLoading(state, action: IAction<boolean>) {
    state.loadingStates.isCategoryLoading = action.payload;
  },
  setStoreTagLoading(state, action: IAction<boolean>) {
    state.loadingStates.isTagLoading = action.payload;
  },
  setStoreProductLoading(state, action: IAction<boolean>) {
    state.loadingStates.isProductLoading = action.payload;
  },
  setStoreTypes(state, action: IAction<IClientStoreType[]>) {
    state.storeTypes.list = action.payload;
  },
  setStoreTypesLoading(state, action: IAction<boolean>) {
    state.storeTypes.isLoading = action.payload;
  },
  setSubdomainCheckResult(state, action: IAction<boolean | null>) {
    state.subdomainCheckResult.result = action.payload;
  },
  setSubdomainCheckResultLoading(state, action: IAction<boolean>) {
    state.subdomainCheckResult.isLoading = action.payload;
  },
});

export default reducers;
