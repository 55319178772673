import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { ListItemButton, ListItemIcon, ListItemText, Collapse, List } from "@mui/material";
import React, { type PropsWithChildren, useState, useImperativeHandle, useContext } from "react";
import { useTranslation } from "react-i18next";
import DrawerContext from "../context/DrawerContext";

interface DrawerListGroupProps extends PropsWithChildren {
  icon?: React.JSX.Element;
  title: string;
}

export interface DrawerListGroupRefType {
  setOpen: (value: boolean) => void;
}

const DrawerListGroup = React.forwardRef(({ title, icon, children }: DrawerListGroupProps, ref) => {
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);
  const { drawerOpen } = useContext(DrawerContext);

  useImperativeHandle(
    ref,
    () => ({
      setOpen,
    }),
    [],
  );

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton onClick={toggle}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={t(title)} />
        {drawerOpen ? open ? <ExpandLess /> : <ExpandMore /> : null}
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  );
});

DrawerListGroup.displayName = "DrawerListGroup";

export default DrawerListGroup;
