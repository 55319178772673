import { dispatch, useAppSelector } from "store/hooks";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";
import BootstrapDialog from "../../common/BootstrapDialog";
import FormBody from "./FormBody";
import FormActions from "./FormActions";
import FormHeader from "./FormHeader";
import React, { useCallback, useContext } from "react";
import { storeMiddleware, storeSelector } from "store/slices/store";
import StoreCategoryContext, { type IStoreCategoryContext } from "./context/StoreCategoryContext";
import {
  type PortalUpdateStoreCategoryBody,
  type PortalCreateStoreCategoryBody,
} from "dataLayer/types/storeCategoryEndpoints";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";

const submitCreation = (context: IStoreCategoryContext) => {
  const body: PortalCreateStoreCategoryBody = {
    storeId: context.storeId,
    title: context.title,
    isEnabled: context.isEnabled,
    items: context.items,
    visibility: {
      isVisible: context.isVisible,
      visibilityConfig: context.visibilityConfig,
    },
  };

  dispatch(storeMiddleware.createStoreCategory(body));
};
const submitUpdate = (context: IStoreCategoryContext) => {
  const body: PortalUpdateStoreCategoryBody = {
    storeId: context.storeId,
    category: {
      id: context.categoryId!,
      title: context.title,
      isEnabled: context.isEnabled,
      items: context.items,
      visibility: {
        isVisible: context.isVisible,
        visibilityConfig: context.visibilityConfig,
      },
    },
  };

  dispatch(storeMiddleware.updateStoreCategory(body));
};

const CreateStoreCategoryModalDialog = () => {
  const [t] = useTranslation();
  const context = useContext(StoreCategoryContext);
  const isCategoryLoading = useAppSelector(storeSelector.isCategoryLoading);
  const title = t(
    context.categoryId
      ? Translations.MODAL_CREATE_CATEGORY_TITLE_EDIT
      : Translations.MODAL_CREATE_CATEGORY_TITLE_CREATE,
  );
  const handleClose = useCallback(() => {
    if (isCategoryLoading) {
      return;
    }
    dispatch(viewsMiddleware.closeModal(ModalName.StoreCategoryModal));
  }, [isCategoryLoading]);

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const { categoryId } = context;

      if (categoryId) {
        submitUpdate(context);
      } else {
        submitCreation(context);
      }
    },
    [context],
  );

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby={title}
      PaperProps={{
        component: "form",
        onSubmit,
        sx: { minWidth: "30%" },
      }}
      open
    >
      <FormHeader title={title} onClose={handleClose} />
      <FormBody />
      <FormActions onClose={handleClose} />
    </BootstrapDialog>
  );
};

export default CreateStoreCategoryModalDialog;
