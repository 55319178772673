import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography, styled } from "@mui/material";
import { type DB_StoreProductDocument } from "dataLayer/types/storeProductEndpoints";
import CheckIcon from "@mui/icons-material/Check";
import { getMultilingualContent } from "utils/language";
import useLanguage from "hooks/useLanguage";

interface ProductSelectionCardProps {
  data: DB_StoreProductDocument;
  onClick: (productId: string) => void;
  isSelected?: boolean;
}

const StyledCard = styled(Card)(({ theme }) => ({
  transition: "transform 0.1s ease-in-out",
  "&:hover": { transform: "scale3d(1.04, 1.04, 1.04)" },
}));

const ProductSelectionCard = ({ data, onClick, isSelected }: ProductSelectionCardProps) => {
  const { language } = useLanguage();

  return (
    <StyledCard>
      <Grid container item xs={12}>
        <CardActionArea
          onClick={() => {
            onClick(data.id);
          }}
        >
          <CardContent>
            <Typography variant="body2" noWrap>{`${getMultilingualContent(language, data.title)}`}</Typography>
          </CardContent>
          {data.image ? (
            <CardMedia component="img" image={data.image} alt={getMultilingualContent(language, data.title)} />
          ) : null}
          {isSelected ? (
            <Grid container justifyContent={"flex-end"}>
              <CheckIcon color={"success"} />
            </Grid>
          ) : null}
        </CardActionArea>
      </Grid>
    </StyledCard>
  );
};

export default ProductSelectionCard;
