import React, { useContext, useMemo } from "react";
import { DialogTitle, Divider, IconButton } from "@mui/material";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import StoreModalContext from "./context/StoreModalContext";
import { useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";
import useLanguage from "hooks/useLanguage";
import useStoreTypes from "pages/Dashboard/hooks/useStoreTypes";
import { getMultilingualContent } from "utils/language";

interface FormHeaderProps {
  onClose: (event: React.SyntheticEvent, reason?: string) => void;
}
const FormHeader = ({ onClose }: FormHeaderProps) => {
  const [t] = useTranslation();
  const { storeType } = useContext(StoreModalContext);
  const isStoreCreateLoading = useAppSelector(storeSelector.isStoreCreateLoading);
  const { language } = useLanguage();
  const { getStoreTypeById } = useStoreTypes();

  const storeTypeDetails = useMemo(() => getStoreTypeById(storeType) ?? null, [storeType, getStoreTypeById]);
  const storeTypeTitle = useMemo(
    () => (storeTypeDetails ? getMultilingualContent(language, storeTypeDetails.title) : ""),
    [language, storeTypeDetails],
  );

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {t(Translations.MODAL_CREATE_STORE_TITLE, {
          storeType: storeTypeTitle,
        })}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
        disabled={isStoreCreateLoading}
      >
        <CloseIcon />
      </IconButton>
      <Divider />
    </>
  );
};

export default FormHeader;
