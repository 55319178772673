import store, { type AppDispatch } from "store/store";
import slice from "./slice";
import FirebaseManager from "dataLayer/FirebaseManager";
import { logError } from "utils/logger";
import { getAuth, onAuthStateChanged, type User } from "firebase/auth";
import { viewsMiddleware } from "../views";
import { ModalName, SeveritiesType } from "store/types/views";
import { type FirebaseError, getApp } from "firebase/app";
import { type HttpsCallableResult } from "firebase/functions";
import { Translations } from "constants/translations";
import { FirebaseAuthError } from "dataLayer/Errors";
import {
  type PortalCreateUserBody,
  type ISignInBody,
  type ISignUpBody,
  type UserPersonalInformationBody,
  type PortalGetUserResponse,
} from "dataLayer/types/userEndpoints";
import { dashboardMiddleware } from "../dashboard";
import { isRootDomain } from "../../../utils/urlUtils";

const {
  setIsFirebaseInitialized,
  setIsFirebaseInitializationInProgress,
  setAuthLoading,
  setAuthUser,
  setFirebaseAuthInitialState,
  setDnsRecordsLoading,
  setDomainDnsRecords,
  setSubdomainDnsRecords,
} = slice.actions;

const initialize = () => async (dispatch: AppDispatch) => {
  if (store.getState().core.firebase.isInitializationInProgress) {
    return;
  }
  try {
    setIsFirebaseInitializationInProgress(true);
    FirebaseManager.initializeFirebaseApplication();
    const app = getApp();
    const auth = getAuth(app);
    onAuthStateChanged(auth, (user: User | null) => {
      dispatch(applyUserData(user));
    });
    dispatch(setIsFirebaseInitialized(true));
  } catch (error) {
    logError("Firebase Initialization Failed", error);
  } finally {
    dispatch(setIsFirebaseInitializationInProgress(false));
  }
};

const signUp = (data: ISignUpBody) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setAuthLoading(true));
    const createUserBody: PortalCreateUserBody = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      emailVerified: false,
      password: data.password,
    };
    await FirebaseManager.API.Portal.createUser(createUserBody);
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.success,
          message: Translations.TOAST_SUCCESS_SIGN_UP,
        },
      }),
    );
    dispatch(viewsMiddleware.closeModal(ModalName.SignUpModal));
  } catch (error) {
    let message: string = Translations.TOAST_ERROR_SIGN_UP;
    let messageObject;

    logError("Sign Up", error);

    switch ((error as FirebaseError).code) {
      case FirebaseAuthError.SIGN_UP_EMAIL_USED:
        message = Translations.TOAST_ERROR_SIGN_UP_ALREADY_REGISTERED;
        messageObject = {
          email: data.email,
        };
        break;

      default:
        break;
    }
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.error,
          message,
          messageObject,
        },
      }),
    );
  } finally {
    dispatch(setAuthLoading(false));
  }
};

const signIn = (data: ISignInBody) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setAuthLoading(true));
    await FirebaseManager.signInUser(data.email, data.password);
  } catch (error) {
    dispatch(signOut(true));
    logError("Sign In", error);
    let message: string = Translations.TOAST_ERROR_SIGN_UP;
    let messageObject;

    switch ((error as FirebaseError).code) {
      case FirebaseAuthError.SIGN_IN_WRONG_PASSWORD:
        message = Translations.TOAST_ERROR_SIGN_IN_WRONG_PASSWORD;
        break;
      case FirebaseAuthError.SIGN_IN_WRONG_EMAIL:
        message = Translations.TOAST_ERROR_SIGN_IN_NOT_REGISTERED;
        messageObject = {
          email: data.email,
        };
        break;
      case FirebaseAuthError.SIGN_IN_USER_DISABLED:
        message = Translations.TOAST_ERROR_SIGN_IN_USER_DISABLED;
        messageObject = {
          email: data.email,
        };
        break;

      default:
        break;
    }
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.error,
          message,
          messageObject,
        },
      }),
    );
  } finally {
    dispatch(setAuthLoading(false));
  }
};

const signOut =
  (silent: boolean = false) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(setAuthLoading(true));
      await FirebaseManager.signOutUser();
      dispatch(setAuthUser(null));
      if (!silent) {
        dispatch(
          viewsMiddleware.setToastNotificationPopUpState({
            open: true,
            props: {
              severityType: SeveritiesType.success,
              message: Translations.TOAST_SUCCESS_SIGN_OUT,
            },
          }),
        );
      }
    } catch (error) {
      dispatch(
        viewsMiddleware.setToastNotificationPopUpState({
          open: true,
          props: {
            severityType: SeveritiesType.error,
            message: Translations.TOAST_ERROR_SIGN_OUT,
          },
        }),
      );
      logError("Sign out failed", error);
    } finally {
      dispatch(setAuthLoading(false));
    }
  };

const applyUserData =
  (user = FirebaseManager.getUser()) =>
  async (dispatch: AppDispatch) => {
    try {
      if (user) {
        const userResponse: HttpsCallableResult<PortalGetUserResponse> = await FirebaseManager.API.Portal.getUser({});
        const { projects, ...userData } = userResponse.data;

        dispatch(setAuthUser(userData));
        dispatch(dashboardMiddleware.setUserProjects(projects));
      } else {
        dispatch(setAuthUser(null));
      }
    } catch (error) {
      dispatch(
        viewsMiddleware.setToastNotificationPopUpState({
          open: true,
          props: {
            severityType: SeveritiesType.error,
            message: Translations.TOAST_ERROR_SIGN_IN,
          },
        }),
      );
    } finally {
      dispatch(setFirebaseAuthInitialState());
    }
  };

const updateProfile = (data: UserPersonalInformationBody) => async (dispatch: AppDispatch) => {
  try {
    const userData = await FirebaseManager.API.Portal.updateUserDisplayInformation(data);
    dispatch(setAuthUser(userData.data));
    dispatch(viewsMiddleware.closeModal(ModalName.ProfileDetails));
  } catch (error) {
    logError("Failed to retrieve user data", error);
  }
};

const getDnsRecords = (url: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setDnsRecordsLoading(true));
    const response = await FirebaseManager.API.Portal.getDnsRecords({ url });
    const { records } = response.data;
    if (isRootDomain(url)) {
      dispatch(setDomainDnsRecords(records));
    } else {
      dispatch(setSubdomainDnsRecords(records));
    }
  } catch (error) {
    logError("Failed to retrieve DNS records", error);
  } finally {
    dispatch(setDnsRecordsLoading(false));
  }
};

export default {
  initialize,
  setIsFirebaseInitialized,
  applyUserData,
  signUp,
  signIn,
  signOut,
  updateProfile,
  getDnsRecords,
};
