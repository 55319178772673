import { Language, type MultilingualString } from "dataLayer/types";
export const getMultilingualContentValue = (language: Language, target: MultilingualString) => {
  return target[language] || "";
};

export const getMultilingualContent = (
  language: Language,
  target: MultilingualString,
  fallbackLanguage: Language = Language.en,
) => {
  return getMultilingualContentValue(language, target) || target[fallbackLanguage];
};

export const getEmptyMultilingualString = (): MultilingualString => {
  return {
    [Language.en]: "",
    [Language.ru]: "",
    [Language.cn]: "",
    [Language.sp]: "",
    [Language.ar]: "",
    [Language.fr]: "",
  };
};
