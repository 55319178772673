import CryptoJS from "crypto-js";
import { getEnvironmentVariables } from "./getEnvironmentVariables";

const { ENCRYPTION_KEY, IV_KEY } = getEnvironmentVariables();

const encryptionKey = CryptoJS.enc.Hex.parse(ENCRYPTION_KEY);
const ivWordArray = CryptoJS.enc.Hex.parse(IV_KEY);

export const encrypt = <B>(body: B): string => {
  const text = JSON.stringify(body);

  const encrypted = CryptoJS.AES.encrypt(text, encryptionKey, {
    iv: ivWordArray,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();

  return encrypted;
};

export const decrypt = <R>(encryptedText: string): R => {
  const decrypted = CryptoJS.AES.decrypt(encryptedText, encryptionKey, {
    iv: ivWordArray,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString(CryptoJS.enc.Utf8);
  return JSON.parse(decrypted) as R;
};
