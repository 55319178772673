import { Autocomplete, TextField } from "@mui/material";
import { type ISelectOption } from "components/common/types";
import { Translations } from "constants/translations";
import useCategoriesHook from "hooks/dashboardDataHooks/useCategoriesHook";
import useLanguage from "hooks/useLanguage";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { convertCategoriesIntoOptions } from "utils/convertingUtils";

interface ProductSelectionFilterProps {
  categoryCallback: (categoryId: string | null) => void;
}

const ProductSelectionFilter = ({ categoryCallback }: ProductSelectionFilterProps) => {
  const [t] = useTranslation();
  const { language } = useLanguage();
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>("all");

  const { categories } = useCategoriesHook();

  const itemsArray = useMemo(() => categories ?? [], [categories]);

  const options = useMemo(() => {
    const allOption: ISelectOption = {
      id: "all",
      title: t(Translations.MODAL_SELECT_STORE_PRODUCT_FIELD_FILTERS_OPTION_ALL),
    };
    const convertedOptions = convertCategoriesIntoOptions(language, itemsArray);
    return [allOption, ...convertedOptions];
  }, [itemsArray, t, language]);

  const selectedOption = useMemo(
    () => options.find((option) => option.id === selectedCategoryId),
    [options, selectedCategoryId],
  );

  useEffect(() => {
    categoryCallback(selectedCategoryId);
  }, [selectedCategoryId, categoryCallback]);

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option.title}
      renderInput={(params) => (
        <TextField {...params} label={t(Translations.MODAL_CREATE_PRODUCT_FIELD_CATEGORIES)} variant="outlined" />
      )}
      value={selectedOption}
      onChange={(_, value) => {
        setSelectedCategoryId(value ? value.id : "all");
      }}
    />
  );
};

export default ProductSelectionFilter;
