import { Grid, Switch, Typography } from "@mui/material";
import { Translations } from "constants/translations";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import StoreCategoryContext from "../../context/StoreCategoryContext";

const CategoryEnabledField = () => {
  const [t] = useTranslation();
  const { isEnabled, setIsEnabled } = useContext(StoreCategoryContext);

  return (
    <Grid container item xs={12} alignItems={"center"}>
      <Grid item xs={11}>
        <Typography>{t(Translations.MODAL_CREATE_CATEGORY_FIELD_ENABLED)}</Typography>
      </Grid>
      <Grid item xs={1}>
        <Switch
          value={isEnabled}
          onChange={(_, checked) => {
            setIsEnabled(checked);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default CategoryEnabledField;
