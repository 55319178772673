import { LoadingButton } from "@mui/lab";
import { DialogActions } from "@mui/material";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { coreSelector } from "store/slices/core";

interface FormActionsProps {
  onClose: () => void;
}

const FormActions = ({ onClose }: FormActionsProps) => {
  const [t] = useTranslation();
  const { isLoading } = useAppSelector(coreSelector.authentication);

  return (
    <DialogActions>
      <LoadingButton loading={isLoading} onClick={onClose}>
        {t(Translations.MODAL_SIGN_OUT_BUTTON_CANCEL)}
      </LoadingButton>
      <LoadingButton loading={isLoading} type="submit">
        {t(Translations.MODAL_SIGN_OUT_BUTTON_SUBMIT)}
      </LoadingButton>
    </DialogActions>
  );
};

export default FormActions;
