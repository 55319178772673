import React from "react";
import StoreCategoryContextProvider from "./context/StoreCategoryContextProvider";
import CreateStoreCategoryModalDialog from "./Dialog";
import useCategoriesHook from "hooks/dashboardDataHooks/useCategoriesHook";
import { convertStoreCategoryToContext } from "utils/convertingUtils";

export interface StoreCategoryModalProps {
  storeId: string;
  categoryId: string | null;
}

const StoreCategoryModal = ({ storeId, categoryId }: StoreCategoryModalProps) => {
  const { getCategoryById } = useCategoriesHook();

  const targetCategory = categoryId ? getCategoryById(categoryId) : null;

  const defaultValues = targetCategory ? convertStoreCategoryToContext(targetCategory) : null;

  return (
    <StoreCategoryContextProvider storeId={storeId} categoryId={categoryId ?? null} defaultValues={defaultValues}>
      <CreateStoreCategoryModalDialog />
    </StoreCategoryContextProvider>
  );
};

export default StoreCategoryModal;
