import React from "react";
import { LoadingButton } from "@mui/lab";
import { DialogActions } from "@mui/material";
import { Translations } from "constants/translations";
import { t } from "i18next";
import { useAppSelector } from "store/hooks";
import { dashboardSelector } from "store/slices/dashboard";

interface FormActionsProps {
  onClose: (event: React.SyntheticEvent) => void;
}
const FormActions = ({ onClose }: FormActionsProps) => {
  const { isCreateLoading } = useAppSelector(dashboardSelector.userProjects);
  return (
    <DialogActions>
      <LoadingButton onClick={onClose} loading={isCreateLoading}>
        {t(Translations.MODAL_PROFILE_DETAILS_BUTTON_CANCEL)}
      </LoadingButton>
      <LoadingButton type="submit" loading={isCreateLoading}>
        {t(Translations.MODAL_PROFILE_DETAILS_BUTTON_SAVE)}
      </LoadingButton>
    </DialogActions>
  );
};

export default FormActions;
