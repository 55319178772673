import { DialogTitle, Divider, Grid, IconButton } from "@mui/material";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { useContext, useMemo } from "react";
import StoreProductContext from "./context/StoreProductContext";
import ProductPrototypeButton from "./components/buttons/ProductPrototypeButton";
import { useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";

interface FormHeaderProps {
  onClose: () => void;
}
const FormHeader = ({ onClose }: FormHeaderProps) => {
  const [t] = useTranslation();
  const { productId } = useContext(StoreProductContext);
  const isLoading = useAppSelector(storeSelector.isProductLoading);

  const modalTitle = useMemo(
    () => (productId ? Translations.MODAL_UPDATE_PRODUCT_TITLE : Translations.MODAL_CREATE_PRODUCT_TITLE),
    [productId],
  );

  return (
    <>
      <Grid container alignItems={"center"}>
        <Grid item xs={6}>
          <DialogTitle sx={{ m: 0, p: 2 }}>{t(modalTitle)}</DialogTitle>
        </Grid>
        <Grid container item xs={6} justifyContent={"flex-end"}>
          <Grid item>
            <ProductPrototypeButton />
          </Grid>
          <Grid item xs={2}>
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
              disabled={isLoading}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};

export default FormHeader;
