import ProductsOverview from "components/Dashboard/ProjectStore/ProductsOverview";
// eslint-disable-next-line max-len
import ProductsOverviewContextProvider from "components/Dashboard/ProjectStore/ProductsOverview/context/ProductsOverviewContextProvider";

const ProductsPage = () => {
  return (
    <ProductsOverviewContextProvider>
      <ProductsOverview />
    </ProductsOverviewContextProvider>
  );
};

export default ProductsPage;
