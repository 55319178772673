import { Button } from "@mui/material";
import { Translations } from "constants/translations";
import useProductsHook from "hooks/dashboardDataHooks/useProductsHook";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import StoreProductContext from "../../context/StoreProductContext";
import { dispatch } from "store/hooks";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";
import { type SelectProductModalProps } from "components/modals/Store/SelectProductModal";

const ProductPrototypeButton = () => {
  const [t] = useTranslation();
  const { prefillFields } = useContext(StoreProductContext);
  const { getProductById } = useProductsHook();

  const onPrototypeSelect = useCallback(
    (productIds: string[]) => {
      const targetProduct = productIds.length ? getProductById(productIds[0]) : null;
      prefillFields(targetProduct);
    },
    [getProductById, prefillFields],
  );

  const openProductSelectionModal = useCallback(() => {
    const props: SelectProductModalProps = {
      multiple: false,
      preselectedProductIds: [],
      selectionCallback: onPrototypeSelect,
    };
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.SelectProductModal,
        props,
      }),
    );
  }, [onPrototypeSelect]);

  return <Button onClick={openProductSelectionModal}>{t(Translations.MODAL_CREATE_PRODUCT_BUTTON_PROTOTYPE)}</Button>;
};

export default ProductPrototypeButton;
