import { type PropsWithChildren, useReducer, useState } from "react";
import StoreModalContext from "./StoreModalContext";
import { getEmptyMultilingualString } from "utils/language";
import multiLingualStringReducer from "utils/multilingualUtils";
import { type Language } from "dataLayer/types";
import { getCurrentTimeZone } from "utils/date";

interface StoreModalContextProviderProps extends PropsWithChildren {}

const StoreModalContextProvider = ({ children }: StoreModalContextProviderProps) => {
  const [step, setStep] = useState<number>(0);
  const [url, setUrl] = useState<string | null>(null);
  const [icon, setIcon] = useState<string | null>(null);
  const [supportedLanguages, setSupportedLanguages] = useState<Language[]>([]);
  const [fallbackLanguage, setFallbackLanguage] = useState<Language | null>(null);
  const [subdomain, setSubdomain] = useState<string | null>(null);
  const [storeType, setStoreType] = useState<string | null>(null);
  const [storeTitle, setStoreTitle] = useReducer(multiLingualStringReducer, getEmptyMultilingualString());
  const [timeZone, setTimeZone] = useState<string>(getCurrentTimeZone());

  return (
    <StoreModalContext.Provider
      value={{
        step,
        setStep,
        storeType,
        url,
        icon,
        subdomain,
        supportedLanguages,
        fallbackLanguage,
        timeZone,
        setStoreType,
        storeTitle,
        setStoreTitle,
        setUrl,
        setSubdomain,
        setIcon,
        setSupportedLanguages,
        setFallbackLanguage,
        setTimeZone,
      }}
    >
      {children}
    </StoreModalContext.Provider>
  );
};

export default StoreModalContextProvider;
