import { Grid } from "@mui/material";
import StoreLanguagesField from "./fields/StoreLanguagesField";
import StoreFallbackLanguageField from "./fields/StoreFallbackLanguageField";
import StoreTimeZoneField from "./fields/StoreTimeZoneField";

const StoreLocalizationSettings = () => {
  return (
    <Grid container sx={{ padding: "10px" }} spacing={2}>
      <Grid item xs={12}>
        <StoreLanguagesField />
      </Grid>
      <Grid item xs={12}>
        <StoreFallbackLanguageField />
      </Grid>
      <Grid item xs={12}>
        <StoreTimeZoneField />
      </Grid>
    </Grid>
  );
};

export default StoreLocalizationSettings;
