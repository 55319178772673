import { ImageIcon } from "components/common/ImageIcon";
import { Translations } from "constants/translations";
import DrawerListGroup, { type DrawerListGroupRefType } from "../common/DrawerListGroup";
import DrawerListItem from "../common/DrawerListItem";
import { useNavigate, useParams } from "react-router-dom";
import { dispatch, useAppSelector } from "store/hooks";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import { dashboardSelector } from "store/slices/dashboard";
import AddIcon from "@mui/icons-material/Add";
import { useRef } from "react";

const ProjectsList = () => {
  const userProjects = useAppSelector(dashboardSelector.userProjects);
  const { projectId } = useParams();
  const navigate = useNavigate();
  const navigationRef = useRef<DrawerListGroupRefType | null>(null);

  const onAddProjectClick = () => {
    dispatch(viewsMiddleware.openModal({ name: ModalName.CreateProjectModal, props: null }));
  };

  const onProjectClick = (projectId: string) => {
    navigate(`/dashboard/${projectId}`);
    navigationRef.current?.setOpen(false);
  };

  return (
    <DrawerListGroup
      title={Translations.LAYOUT_DASHBOARD_NAVIGATION_PROJECTS}
      icon={<AccountTreeOutlinedIcon />}
      ref={navigationRef}
    >
      <>
        {userProjects.list
          ? userProjects.list.map((projectMeta) => (
              <DrawerListItem
                key={projectMeta.id}
                title={projectMeta.title}
                icon={<ImageIcon iconURL={projectMeta.icon} />}
                selected={projectId === projectMeta.id}
                onClick={() => {
                  onProjectClick(projectMeta.id);
                }}
              />
            ))
          : null}
        <DrawerListItem
          icon={<AddIcon />}
          title={Translations.LAYOUT_DASHBOARD_NAVIGATION_PROJECTS_ADD}
          onClick={onAddProjectClick}
        />
      </>
    </DrawerListGroup>
  );
};

export default ProjectsList;
