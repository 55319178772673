import { Dialog } from "@mui/material";

import { dispatch } from "store/hooks";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";
import FormBody from "./FormBody";
import React, { useCallback, useContext } from "react";
import FormHeader from "./FormHeader";
import FormActions from "./FormActions";
import StoreProductContext, { type IStoreProductContext } from "./context/StoreProductContext";
import {
  type PortalUpdateStoreProductBody,
  type PortalCreateStoreProductBody,
} from "dataLayer/types/storeProductEndpoints";
import { storeMiddleware } from "store/slices/store";

export interface CreateStoreProductModalProps {
  storeId: string;
}

const submitCreation = (context: IStoreProductContext, storeId: string): void => {
  const {
    title,
    description,
    image,
    subItems,
    categories,
    price,
    visibility,
    globalLimit,
    userLimit,
    gifting,
    alternativeProducts,
    additionalProduct,
  } = context;

  const body: PortalCreateStoreProductBody = {
    storeId,
    categoryIds: categories,
    product: {
      title,
      description,
      image,
      subItems,
      price,
      visibility,
      limits: {
        global: globalLimit,
        user: userLimit,
      },
      giftingConfig: {
        enabled: gifting,
      },
      upSellConfig: {
        alternativeProducts,
        additionalProduct,
      },
      commands: [],
    },
  };
  dispatch(storeMiddleware.createStoreProduct(body));
};
const submitUpdate = (context: IStoreProductContext, storeId: string): void => {
  const {
    productId,
    title,
    description,
    image,
    subItems,
    categories,
    price,
    visibility,
    globalLimit,
    userLimit,
    gifting,
    alternativeProducts,
    additionalProduct,
  } = context;

  const body: PortalUpdateStoreProductBody = {
    storeId,
    categoryIds: categories,
    product: {
      id: productId!,
      title,
      description,
      image,
      subItems,
      price,
      visibility,
      limits: {
        global: globalLimit,
        user: userLimit,
      },
      giftingConfig: {
        enabled: gifting,
      },
      upSellConfig: {
        alternativeProducts,
        additionalProduct,
      },
      commands: [],
    },
  };
  dispatch(storeMiddleware.updateStoreProduct(body));
};

const CreateStoreProductModalDialog = ({ storeId }: CreateStoreProductModalProps) => {
  const context = useContext(StoreProductContext);

  const handleClose = useCallback((_?: any, reason?: string) => {
    if (reason === "backdropClick") {
      return;
    }
    dispatch(viewsMiddleware.closeModal(ModalName.StoreProductModal));
  }, []);

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const { productId } = context;
      if (productId) {
        submitUpdate(context, storeId);
      } else {
        submitCreation(context, storeId);
      }
    },
    [storeId, context],
  );

  return (
    <Dialog
      open
      fullWidth
      maxWidth="lg"
      onClose={handleClose}
      PaperProps={{
        sx: { height: "90%" },
        onSubmit,
        component: "form",
      }}
    >
      <FormHeader onClose={handleClose} />
      <FormBody />
      <FormActions onClose={handleClose} />
    </Dialog>
  );
};

export default CreateStoreProductModalDialog;
