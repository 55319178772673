import { type IUnique } from "dataLayer/types";
import { format, toZonedTime } from "date-fns-tz";

const dateOnlyValueFormat = "yyyy-MM-dd";
export const dateOnlyDisplayFormat = "MMM dd, yyyy";

// const dateOnlyWithoutYear = "MMM dd";
// const timeOnlyValueFormat = "HH:mm:ss";

const allTimeZones = Intl.supportedValuesOf("timeZone");
const date = new Date();

export const timeZoneOptions: IUnique[] = allTimeZones.map((timeZone: string) => {
  const zonedTime = toZonedTime(date, timeZone);
  const offset = format(zonedTime, "XXX", { timeZone });
  return {
    id: timeZone,
    title: `${timeZone} (UTC${offset})`,
  };
});

export const convertToDateOnly = (date: Date | null) => {
  if (!date) {
    return null;
  }
  return format(date, dateOnlyValueFormat);
};

export const convertFromDateOnly = (dateOnly: string | null) => {
  if (!dateOnly) {
    return dateOnly;
  }
  return new Date(dateOnly);
};

export const formatDateOnly = (dateOnly: string | null) => {
  if (!dateOnly) {
    return null;
  }
  return format(convertFromDateOnly(dateOnly)!, dateOnlyDisplayFormat);
};

export const convertFromDateTime = (dateTime: string | null) => {
  if (!dateTime) {
    return dateTime;
  }
  return new Date(dateTime);
};

export const convertToIso = (date: Date) => {
  return date.toISOString();
};

export const getCurrentTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};
