import { TextField } from "@mui/material";
import StoreSettingsContext from "components/Dashboard/ProjectStore/SettingsOverview/context/StoreSettingsContext";
import { Translations } from "constants/translations";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ipV4Checker } from "utils/regexCheckers";

const allowedCharacters = /^[0-9.]+$/;

const RconHostField = () => {
  const [t] = useTranslation();
  const { serverDetails, setServerDetails } = useContext(StoreSettingsContext);
  const [error, setError] = useState<string | null>(null);

  const setHost = useCallback(
    (value: string) => {
      if (value && !allowedCharacters.test(value)) {
        return;
      }
      setServerDetails({ property: "host", value });
    },
    [setServerDetails],
  );

  useEffect(() => {
    if (ipV4Checker(serverDetails.host ?? "")) {
      setError(null);
    } else {
      setError(t(Translations.VALIDATION_ERROR_STORE_SETTINGS_SERVER_RCON_HOST));
    }
  }, [t, serverDetails.host, setError]);

  return (
    <TextField
      required
      margin="dense"
      key={Translations.PAGE_PROJECT_STORE_SETTINGS_RCON_HOST}
      name="server-host"
      label={t(Translations.PAGE_PROJECT_STORE_SETTINGS_RCON_HOST)}
      type="text"
      fullWidth
      variant="outlined"
      value={serverDetails.host}
      inputProps={{
        maxLength: 16,
      }}
      error={!!error}
      helperText={error}
      onChange={(_) => {
        setHost(_.target.value);
      }}
    />
  );
};

export default RconHostField;
