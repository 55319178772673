import { Grid, Switch, Typography } from "@mui/material";
import { Translations } from "constants/translations";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import StoreProductContext from "../../context/StoreProductContext";

const ProductGiftingField = () => {
  const [t] = useTranslation();
  const { gifting, setGifting } = useContext(StoreProductContext);

  return (
    <Grid container item xs={12} alignItems={"center"}>
      <Grid item xs={11}>
        <Typography>{t(Translations.MODAL_CREATE_PRODUCT_FIELD_GIFTING_ENABLED)}</Typography>
      </Grid>
      <Grid item xs={1}>
        <Switch
          value={gifting}
          onChange={(_, checked) => {
            setGifting(checked);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ProductGiftingField;
