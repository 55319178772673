import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { type AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { type AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { type IAccordionListItem } from "./types";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import AccordionListContextProvider from "./context/AccordionListContextProvider";
import { useCallback, useContext, useMemo, useState } from "react";
import AccordionListContext from "./context/AccordionListContext";
import { ContextMenu } from "../DataTable/ContextMenu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&::before": {
      display: "none",
    },
  }),
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    marginTop: 0,
    marginBottom: 0,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

interface AccordionSectionProps {
  item: IAccordionListItem;
  multiple?: boolean;
}
const AccordionSection = ({ item, multiple }: AccordionSectionProps) => {
  const [t] = useTranslation();
  const { expandedItemId, setExpandedItemId } = useContext(AccordionListContext);
  const [expanded, setExpanded] = useState<boolean>(false);

  const isExpanded = useMemo(() => {
    return multiple ? expanded : expandedItemId === item.id;
  }, [item, multiple, expanded, expandedItemId]);

  const handleExpandStateSingle = useCallback(() => {
    setExpandedItemId(expandedItemId !== item.id ? item.id : null);
  }, [item, expandedItemId, setExpandedItemId]);

  const handleExpandStateMultiple = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded, setExpanded]);

  const handleExpandState = useCallback(() => {
    multiple ? handleExpandStateMultiple() : handleExpandStateSingle();
  }, [handleExpandStateSingle, handleExpandStateMultiple, multiple]);

  return (
    <Accordion expanded={isExpanded} sx={{ p: 0 }}>
      <Grid container item xs={12} justifyContent={"space-between"} sx={{ height: "100%" }}>
        <Grid xs={11} item onClick={handleExpandState} component={"div"}>
          <AccordionSummary expandIcon={<ExpandMoreIcon onClick={handleExpandState} />}>
            <Typography>{t(item.title)}</Typography>
          </AccordionSummary>
        </Grid>
        {item.actions ? (
          <Grid item xs={1}>
            <AccordionSummary expandIcon={null}>{<ContextMenu actionBindings={item.actions} />}</AccordionSummary>
          </Grid>
        ) : null}
      </Grid>
      <AccordionDetails>{item.content}</AccordionDetails>
    </Accordion>
  );
};

interface AccordionListProps {
  items: IAccordionListItem[];
  multiple?: boolean;
}

const AccordionList = ({ items, multiple }: AccordionListProps) => {
  return (
    <AccordionListContextProvider>
      {items.map((item) => (
        <AccordionSection key={item.id} item={item} multiple={multiple} />
      ))}
    </AccordionListContextProvider>
  );
};

export default AccordionList;
