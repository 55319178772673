import { TextField } from "@mui/material";
import StoreSettingsContext from "components/Dashboard/ProjectStore/SettingsOverview/context/StoreSettingsContext";
import { Translations } from "constants/translations";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { portChecker } from "utils/regexCheckers";

const RconPortField = () => {
  const [t] = useTranslation();
  const { serverDetails, setServerDetails } = useContext(StoreSettingsContext);
  const [error, setError] = useState<string | null>(null);

  const setPort = useCallback(
    (value: string) => {
      if (value.length > 1 && value.startsWith("0")) {
        value = value.substring(1);
      }
      if (value && !portChecker(value)) {
        return;
      }
      setServerDetails({ property: "port", value: +value });
    },
    [setServerDetails],
  );

  useEffect(() => {
    if (serverDetails.port && portChecker(serverDetails.port.toString())) {
      setError(null);
    } else {
      setError(t(Translations.VALIDATION_ERROR_STORE_SETTINGS_SERVER_RCON_PORT));
    }
  }, [t, serverDetails.port, setError]);

  return (
    <TextField
      required
      margin="dense"
      key={Translations.PAGE_PROJECT_STORE_SETTINGS_RCON_PORT}
      name="server-port"
      label={t(Translations.PAGE_PROJECT_STORE_SETTINGS_RCON_PORT)}
      type="text"
      fullWidth
      variant="outlined"
      value={serverDetails.port}
      inputProps={{
        maxLength: 5,
      }}
      error={!!error}
      helperText={error}
      onChange={(_) => {
        setPort(_.target.value);
      }}
    />
  );
};

export default RconPortField;
