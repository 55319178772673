import { useContext } from "react";
import StoreProductContext from "../../context/StoreProductContext";
import { Grid, IconButton, TextField } from "@mui/material";
import { Translations } from "constants/translations";
import { t } from "i18next";
import ClearIcon from "@mui/icons-material/Clear";

const ProductImageField = () => {
  const { image, setImage } = useContext(StoreProductContext);
  const onClear = () => {
    setImage(null);
  };

  return (
    <Grid container item xs={12} alignContent={"start"}>
      <Grid item xs={12}>
        <TextField
          autoFocus
          required
          margin="dense"
          key={Translations.MODAL_CREATE_PRODUCT_FIELD_IMAGE}
          name={Translations.MODAL_CREATE_PRODUCT_FIELD_IMAGE}
          label={t(Translations.MODAL_CREATE_PRODUCT_FIELD_IMAGE)}
          type="text"
          fullWidth
          variant="outlined"
          value={image ?? ""}
          InputProps={{
            endAdornment: (
              <IconButton onClick={onClear} sx={{ display: image ? "inherit" : "none" }}>
                <ClearIcon />
              </IconButton>
            ),
          }}
          onChange={(_) => {
            setImage(_.target.value);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ProductImageField;
