export const drawerWidth: number = 240;
export const footerHeight: number = 30;
export const iconSize: number = 24;

const fabPositionBottom = 16;
type FabSize = "small" | "medium" | "large";
const fabSizes: Record<FabSize, number> = {
  small: 16,
  medium: 32,
  large: 64,
};

export const getFabStyle = (index: number, size: FabSize = "large") => ({
  position: "absolute",
  bottom: fabPositionBottom + index * fabSizes[size],
  right: 32,
});
