import { Box, DialogContent, DialogContentText, TextField } from "@mui/material";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";

const FormBody = () => {
  const [t] = useTranslation();
  return (
    <DialogContent dividers>
      <Box sx={{ pb: "14px" }}>
        <DialogContentText>{t(Translations.MODAL_CREATE_PROJECT_SUBTITLE)}</DialogContentText>
      </Box>
      <TextField
        autoFocus
        required
        margin="dense"
        key={Translations.MODAL_CREATE_PROJECT_FIELD_NAME}
        name="title"
        label={t(Translations.MODAL_CREATE_PROJECT_FIELD_NAME)}
        type="text"
        fullWidth
        variant="outlined"
      />
      <TextField
        autoFocus
        margin="dense"
        key={Translations.MODAL_CREATE_PROJECT_FIELD_ICON}
        name="icon"
        label={t(Translations.MODAL_CREATE_PROJECT_FIELD_ICON)}
        type="text"
        fullWidth
        variant="outlined"
      />
    </DialogContent>
  );
};

export default FormBody;
