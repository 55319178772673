import { useCallback, useContext, useMemo } from "react";
import { Translations } from "constants/translations";
import { Autocomplete, TextField } from "@mui/material";
import { convertProductsIntoOptions } from "utils/convertingUtils";
import { useTranslation } from "react-i18next";
import useProductsHook from "hooks/dashboardDataHooks/useProductsHook";
import StoreTagContext from "../../context/StoreTagContext";
import { type SelectProductModalProps } from "components/modals/Store/SelectProductModal";
import { dispatch } from "store/hooks";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";
import MultilingualFieldContext from "components/common/MultilingualFieldWrapper/context/MultilingualFieldContext";

const TagProductsField = () => {
  const [t] = useTranslation();
  const { items, setItems } = useContext(StoreTagContext);
  const { language } = useContext(MultilingualFieldContext);

  const { products } = useProductsHook();

  const options = useMemo(() => convertProductsIntoOptions(language, products, null), [language, products]);

  const selectedOptions = useMemo(() => options.filter((option) => items.includes(option.id)), [options, items]);

  const openProductSelectionModal = useCallback(() => {
    const props: SelectProductModalProps = {
      multiple: true,
      preselectedProductIds: items,
      selectionCallback: setItems,
    };
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.SelectProductModal,
        props,
      }),
    );
  }, [items, setItems]);

  return (
    <Autocomplete
      open={false}
      multiple
      options={options}
      getOptionLabel={(option) => option.title}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t(Translations.MODAL_CREATE_TAG_FIELD_PRODUCTS)}
          variant="outlined"
          onClick={openProductSelectionModal}
        />
      )}
      value={selectedOptions}
      onChange={(_, values) => {
        setItems(values.map((value) => value.id));
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.title}
        </li>
      )}
    />
  );
};

export default TagProductsField;
