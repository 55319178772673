import { dispatch, useAppSelector } from "store/hooks";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";
import BootstrapDialog from "../../common/BootstrapDialog";
import FormBody from "./FormBody";
import FormActions from "./FormActions";
import FormHeader from "./FormHeader";
import React, { useCallback, useContext } from "react";
import { storeMiddleware, storeSelector } from "store/slices/store";
import StoreTagContext, { type IStoreTagContext } from "./context/StoreTagContext";
import { useTranslation } from "react-i18next";
import { Translations } from "constants/translations";
import { type PortalCreateStoreTagBody, type PortalUpdateStoreTagBody } from "dataLayer/types/storeTagEndpoints";

const submitCreation = (context: IStoreTagContext) => {
  const body: PortalCreateStoreTagBody = {
    storeId: context.storeId,
    title: context.title,
    items: context.items,
  };

  dispatch(storeMiddleware.createStoreTag(body));
};
const submitUpdate = (context: IStoreTagContext) => {
  const body: PortalUpdateStoreTagBody = {
    storeId: context.storeId,
    tag: {
      id: context.tagId!,
      title: context.title,
      items: context.items,
    },
  };

  dispatch(storeMiddleware.updateStoreTag(body));
};

const CreateStoreTagModalDialog = () => {
  const [t] = useTranslation();
  const isLoading = useAppSelector(storeSelector.isTagLoading);
  const context = useContext(StoreTagContext);

  const handleClose = useCallback(() => {
    if (isLoading) {
      return;
    }
    dispatch(viewsMiddleware.closeModal(ModalName.StoreTagModal));
  }, [isLoading]);

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const { tagId } = context;

      if (tagId) {
        submitUpdate(context);
      } else {
        submitCreation(context);
      }
    },
    [context],
  );

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby={t(Translations.MODAL_CREATE_TAG_TITLE)}
      PaperProps={{
        component: "form",
        onSubmit,
        sx: { minWidth: "40%" },
      }}
      open
    >
      <FormHeader onClose={handleClose} />
      <FormBody />
      <FormActions onClose={handleClose} />
    </BootstrapDialog>
  );
};

export default CreateStoreTagModalDialog;
