import { Box, Container, Toolbar } from "@mui/material";
import { ModalsController } from "components/modals/controller";
import { ToastNotificationsController } from "components/toast/ToastNotificationController";
import { Outlet } from "react-router-dom";

const RootLayout = () => {
  return (
    <>
      <Box sx={{ minHeight: "100vh" }}>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Outlet />
          </Container>
          {/* <Footer /> */}
        </Box>
        <ModalsController />
        <ToastNotificationsController />
      </Box>
    </>
  );
};

export default RootLayout;
