import { Box, DialogContent, DialogContentText, TextField } from "@mui/material";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";
import { type UserState } from "store/types/core";

interface FormBodyProps {
  user: UserState;
}

const FormBody = ({ user }: FormBodyProps) => {
  const [t] = useTranslation();
  return (
    <DialogContent dividers>
      <Box sx={{ pb: "14px" }}>
        <DialogContentText>{t(Translations.MODAL_PROFILE_DETAILS_SUBTITLE)}</DialogContentText>
      </Box>
      <TextField
        autoFocus
        required
        margin="dense"
        key={Translations.MODAL_PROFILE_DETAILS_FIRST_NAME}
        name="firstName"
        label={t(Translations.MODAL_PROFILE_DETAILS_FIRST_NAME)}
        type="text"
        fullWidth
        variant="outlined"
        defaultValue={user.firstName}
      />
      <TextField
        autoFocus
        required
        margin="dense"
        key={Translations.MODAL_PROFILE_DETAILS_LAST_NAME}
        name="lastName"
        label={t(Translations.MODAL_PROFILE_DETAILS_LAST_NAME)}
        type="text"
        fullWidth
        variant="outlined"
        defaultValue={user.lastName}
      />
      <TextField
        autoFocus
        margin="dense"
        key={Translations.MODAL_PROFILE_DETAILS_EMAIL}
        name="email"
        label={t(Translations.MODAL_PROFILE_DETAILS_EMAIL)}
        type="text"
        fullWidth
        variant="outlined"
        defaultValue={user.email}
        disabled
      />
    </DialogContent>
  );
};

export default FormBody;
