import { type PropsWithChildren } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";

import { enUS } from "date-fns/locale/en-US";

const PortalLocalizationProvider = ({ children }: PropsWithChildren) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS}>
      {children}
    </LocalizationProvider>
  );
};

export default PortalLocalizationProvider;
