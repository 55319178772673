import { type SliceCaseReducers } from "@reduxjs/toolkit";
import { type IDnsRecordsMap } from "dataLayer/types";
import { type IAction } from "store/store";
import { type ICoreSliceState, type UserState } from "store/types/core";

const createReducer = <T extends SliceCaseReducers<ICoreSliceState>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setAuthUser(state, action: IAction<UserState | null>) {
    state.authentication = {
      ...state.authentication,
      user: action.payload,
    };
  },
  setAuthLoading(state, action: IAction<boolean>) {
    state.authentication = {
      ...state.authentication,
      isLoading: action.payload,
    };
  },
  setIsFirebaseInitializationInProgress(state, action: IAction<boolean>) {
    state.firebase = {
      ...state.firebase,
      isInitializationInProgress: action.payload,
    };
  },
  setIsFirebaseInitialized(state, action: IAction<boolean>) {
    state.firebase = {
      ...state.firebase,
      isInitialized: action.payload,
    };
  },
  setFirebaseAuthInitialState(state) {
    state.authentication = {
      ...state.authentication,
      initialCheckComplete: true,
    };
  },
  setSubdomainDnsRecords(state, action: IAction<IDnsRecordsMap>) {
    state.dnsRecords.subdomain = action.payload;
  },
  setDomainDnsRecords(state, action: IAction<IDnsRecordsMap>) {
    state.dnsRecords.domain = action.payload;
  },
  setDnsRecordsLoading(state, action: IAction<boolean>) {
    state.dnsRecords.isLoading = action.payload;
  },
});

export default reducers;
