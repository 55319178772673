import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { Translations } from "constants/translations";
import { type Dispatch, type SetStateAction, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TimeUnit, type StoreProductLimit } from "dataLayer/types/storeProductEndpoints";
import { getOptionById } from "utils/convertingUtils";
import FieldToggleWrapper from "../../../../common/FieldToggleWrapper";

interface ProductLimitFieldsProps {
  label: string;
  state: StoreProductLimit | null;
  setState: Dispatch<SetStateAction<StoreProductLimit | null>>;
}

const optionIds = [TimeUnit.MINUTE, TimeUnit.HOUR, TimeUnit.DAY, TimeUnit.WEEK, TimeUnit.MONTH, TimeUnit.YEAR];

const options = optionIds.map((optionId) => ({
  id: `${optionId}`,
  title: `${Translations.COMMON_TIME_UNIT}.${optionId}`,
}));

const FieldsContent = ({ label, state, setState }: ProductLimitFieldsProps) => {
  const [t] = useTranslation();

  const updateMaxCount = useCallback(
    (maxCount: string) => {
      setState({
        ...state!,
        maxCount,
      });
    },
    [state, setState],
  );

  const updateRecency = useCallback(
    (limitTimeUnitsCount: string) => {
      setState({
        ...state!,
        limitTimeUnitsCount,
      });
    },
    [state, setState],
  );

  const updateTimeUnit = useCallback(
    (limitTimeUnit: string) => {
      setState({
        ...state!,
        limitTimeUnit,
      });
    },
    [state, setState],
  );

  return (
    <Grid container item xs={12}>
      <Grid container item xs={12}>
        <Typography>{t(label)}</Typography>
      </Grid>
      <Grid container item xs={12} alignItems={"center"}>
        <Grid container item xs={4}>
          <TextField
            required
            margin="dense"
            key={Translations.MODAL_CREATE_PRODUCT_FIELD_LIMIT_MAX_COUNT}
            name={Translations.MODAL_CREATE_PRODUCT_FIELD_LIMIT_MAX_COUNT}
            label={t(Translations.MODAL_CREATE_PRODUCT_FIELD_LIMIT_MAX_COUNT)}
            type="number"
            fullWidth
            variant="outlined"
            inputProps={{ step: 1 }}
            defaultValue={state?.maxCount}
            sx={{
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                display: "none",
              },
              "& input[type=number]": {
                MozAppearance: "textfield",
              },
            }}
            onChange={(_) => {
              updateMaxCount(_.target.value);
            }}
          />
        </Grid>
        <Grid container item xs={4}>
          <TextField
            required
            margin="dense"
            key={Translations.MODAL_CREATE_PRODUCT_FIELD_LIMIT_RECENCY}
            name={Translations.MODAL_CREATE_PRODUCT_FIELD_LIMIT_RECENCY}
            label={t(Translations.MODAL_CREATE_PRODUCT_FIELD_LIMIT_RECENCY)}
            type="number"
            fullWidth
            variant="outlined"
            inputProps={{ step: 1 }}
            defaultValue={state?.limitTimeUnitsCount}
            sx={{
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                display: "none",
              },
              "& input[type=number]": {
                MozAppearance: "textfield",
              },
            }}
            onChange={(_) => {
              updateRecency(_.target.value);
            }}
          />
        </Grid>
        <Grid container item xs={4} alignItems={"center"}>
          <Autocomplete
            options={options}
            getOptionLabel={(option) => t(option.title)}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label={t(Translations.MODAL_CREATE_PRODUCT_FIELD_LIMIT_TIME_UNIT)}
                variant="outlined"
              />
            )}
            value={state ? getOptionById(options, state.limitTimeUnit) : null}
            onChange={(_, value) => {
              if (value) {
                updateTimeUnit(value.id);
              }
            }}
            sx={{ mt: 0.5 }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const defaultEnabledState: StoreProductLimit = {
  maxCount: "0",
  limitTimeUnit: `${TimeUnit.MINUTE}`,
  limitTimeUnitsCount: "0",
};

const ProductLimitFields = ({ label, state, setState }: ProductLimitFieldsProps) => {
  return (
    <FieldToggleWrapper
      label={label}
      state={state}
      setState={setState}
      defaultEnabledState={defaultEnabledState}
      defaultDisabledState={null}
    >
      <FieldsContent label={label} state={state} setState={setState} />
    </FieldToggleWrapper>
  );
};

export default ProductLimitFields;
