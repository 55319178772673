import React from "react";
import StoreTagContextProvider from "./context/StoreTagContextProvider";
import CreateStoreTagModalDialog from "./Dialog";
import useTagsHook from "hooks/dashboardDataHooks/useTagsHook";

export interface StoreTagModalProps {
  storeTypeId: string;
  tagId: string | null;
}

const StoreTagModal = ({ storeTypeId, tagId }: StoreTagModalProps) => {
  const { getTagById } = useTagsHook();

  const targetTag = tagId ? getTagById(tagId) : null;

  const defaultValues = targetTag ?? null;

  return (
    <StoreTagContextProvider storeId={storeTypeId} tagId={tagId ?? null} defaultValues={defaultValues}>
      <CreateStoreTagModalDialog />
    </StoreTagContextProvider>
  );
};

export default StoreTagModal;
