import { DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

interface FormHeaderProps {
  onClose: () => void;
  purpose?: string;
}
const FormHeader = ({ onClose, purpose }: FormHeaderProps) => {
  const [t] = useTranslation();

  return (
    <Grid container>
      <Grid item container>
        <DialogTitle sx={{ m: 0, p: 2 }}>{t(Translations.MODAL_SELECT_STORE_PRODUCT_TITLE)}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      {purpose ? (
        <Grid item container>
          <Typography>{purpose}</Typography>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default FormHeader;
