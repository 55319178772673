import { LoadingButton } from "@mui/lab";
import { DialogActions } from "@mui/material";
import { Translations } from "constants/translations";
import { t } from "i18next";
import { useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";

interface FormActionsProps {
  onClose: () => void;
}
const FormActions = ({ onClose }: FormActionsProps) => {
  const isCategoryLoading = useAppSelector(storeSelector.isCategoryLoading);
  return (
    <DialogActions>
      <LoadingButton onClick={onClose} loading={isCategoryLoading}>
        {t(Translations.MODAL_CONFIRMATION_BUTTON_CANCEL)}
      </LoadingButton>
      <LoadingButton type="submit" loading={isCategoryLoading}>
        {t(Translations.MODAL_CONFIRMATION_BUTTON_CONFIRM)}
      </LoadingButton>
    </DialogActions>
  );
};

export default FormActions;
