import StoreSettingsOverview from "components/Dashboard/ProjectStore/SettingsOverview";

const StoreSettingsPage = () => {
  return (
    <>
      <StoreSettingsOverview />
    </>
  );
};

export default StoreSettingsPage;
