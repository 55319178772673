import { Autocomplete, TextField } from "@mui/material";
import StoreSettingsContext from "components/Dashboard/ProjectStore/SettingsOverview/context/StoreSettingsContext";
import { Translations } from "constants/translations";
import { type IUnique } from "dataLayer/types";
import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

const encodingOptions: IUnique[] = [
  {
    id: "utf-8",
    title: "UTF-8",
  },
  {
    id: "ascii",
    title: "ASCII",
  },
];

const RconEncodingField = () => {
  const [t] = useTranslation();
  const { serverDetails, setServerDetails } = useContext(StoreSettingsContext);

  const currentValue = useMemo(() => {
    return encodingOptions.find((option) => option.id === serverDetails.encoding) ?? null;
  }, [serverDetails.encoding]);

  const setEncoding = useCallback(
    (value: string) => {
      setServerDetails({ property: "encoding", value });
    },
    [setServerDetails],
  );

  return (
    <Autocomplete
      options={encodingOptions}
      getOptionLabel={(option) => option.title}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          label={t(Translations.PAGE_PROJECT_STORE_SETTINGS_RCON_ENCODING)}
          variant="outlined"
        />
      )}
      value={currentValue}
      onChange={(_, value) => {
        if (!value) {
          return;
        }
        setEncoding(value.id);
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.title}
        </li>
      )}
    />
  );
};

export default RconEncodingField;
