import { DialogContentText } from "@mui/material";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";

const FormBody = () => {
  const [t] = useTranslation();
  return (
    <>
      <DialogContentText>{t(Translations.MODAL_SIGN_OUT_SUBTITLE)}</DialogContentText>
    </>
  );
};

export default FormBody;
