import { Grid, Typography } from "@mui/material";
import useProject from "pages/Dashboard/hooks/useProject";
import ProjectSettingsButton from "./ProjectSettingsButton";
import { useTranslation } from "react-i18next";
import { Translations } from "constants/translations";
import { useEffect } from "react";
import useDocumentTitle from "pages/Dashboard/hooks/useDocumentTitle";

const ProjectBreadcrumbTitle = () => {
  const [t] = useTranslation();
  const { PROJECT, PROJECT_SETTINGS } = useProject();
  const { setDocumentTitle } = useDocumentTitle();

  useEffect(() => {
    if (!PROJECT) {
      return;
    }
    setDocumentTitle(PROJECT.title);
  }, [PROJECT, setDocumentTitle]);

  return PROJECT && PROJECT_SETTINGS ? (
    <Grid item>
      <Grid container alignContent={"center"} justifyContent={"space-between"}>
        <Typography variant="overline" sx={{ my: "auto" }}>
          {t(Translations.BREADCRUMB_PROJECT_TITLE, { title: PROJECT.title })}
        </Typography>
        <ProjectSettingsButton />
      </Grid>
    </Grid>
  ) : null;
};

export default ProjectBreadcrumbTitle;
