import { Grid, Toolbar } from "@mui/material";

const GameSelection = () => {
  return null;
};

const DashboardPage = () => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Toolbar />
          <GameSelection />
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardPage;
