import { iconSize } from "constants/style";
import { useState } from "react";

export interface ImageIconProps {
  iconURL: string | null;
}
export const ImageIcon = ({ iconURL }: ImageIconProps) => {
  const [failed, setFailed] = useState<boolean>(false);

  const onError = () => {
    setFailed(true);
  };
  return iconURL && !failed ? <img src={iconURL} width={iconSize} height={iconSize} onError={onError} /> : null;
};
