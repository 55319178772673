import { contextSetterImitator } from "constants/context";
import { type MultilingualString } from "dataLayer/types";
import { type CategoryVisibilityConfig } from "dataLayer/types/storeCategoryEndpoints";
import { type Dispatch, type SetStateAction, createContext } from "react";
import { getEmptyMultilingualString } from "utils/language";
import { type ISetMultilingualContentPayload } from "utils/multilingualUtils";

export interface IStoreCategoryContextFields {
  title: MultilingualString;
  items: string[];
  isEnabled: boolean;
  isVisible: boolean | null;
  visibilityConfig: CategoryVisibilityConfig | null;
}
export interface IStoreCategoryContext extends IStoreCategoryContextFields {
  storeId: string;
  categoryId: string | null;
  setTitle: Dispatch<ISetMultilingualContentPayload>;
  setItems: Dispatch<SetStateAction<string[]>>;
  setIsEnabled: Dispatch<SetStateAction<boolean>>;
  setIsVisible: Dispatch<SetStateAction<boolean | null>>;
  setVisibilityConfig: Dispatch<SetStateAction<CategoryVisibilityConfig | null>>;
}

const StoreCategoryContext = createContext<IStoreCategoryContext>({
  categoryId: null,
  storeId: "",
  title: getEmptyMultilingualString(),
  items: [],
  isEnabled: true,
  isVisible: true,
  visibilityConfig: null,
  setTitle: contextSetterImitator,
  setItems: contextSetterImitator,
  setIsEnabled: contextSetterImitator,
  setIsVisible: contextSetterImitator,
  setVisibilityConfig: contextSetterImitator,
});

export default StoreCategoryContext;
