import { Box } from "@mui/material";
import DrawerContextProvider from "components/Dashboard/DashboardDrawer/context/DrawerContextProvider";
import PageAppBar from "components/Dashboard/PageAppBar";
import { Translations } from "constants/translations";
import { Outlet } from "react-router-dom";

const HomeLayout = () => {
  return (
    <>
      <DrawerContextProvider>
        <PageAppBar title={Translations.PAGE_HOME_TITLE} hasMenu={false} />
      </DrawerContextProvider>
      <Box sx={{ pt: "40px" }}>
        <Outlet />
      </Box>
    </>
  );
};

export default HomeLayout;
