import { Button, Grid, Paper, Typography } from "@mui/material";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";
import { dispatch } from "store/hooks";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";
import useProjectStore from "pages/Dashboard/hooks/useProjectStore";
import useCategoryListActions from "hooks/actionHooks/useCategoryListActions";
import { type IAccordionListItem } from "components/common/AccordionList/types";
import { useCallback, useMemo } from "react";
import useCategoriesHook from "hooks/dashboardDataHooks/useCategoriesHook";
import AccordionList from "components/common/AccordionList";
import ProductsListView from "../ProductsOverview/components/ProductsListView";
import useProductsHook from "hooks/dashboardDataHooks/useProductsHook";
import useLanguage from "hooks/useLanguage";
import { getMultilingualContent } from "utils/language";
import InfoIcon from "@mui/icons-material/Info";

const CategoriesOverview = () => {
  const [t] = useTranslation();
  const { categories } = useCategoriesHook();
  const { storeId } = useProjectStore();
  const { getProductsByIds } = useProductsHook();
  const { language } = useLanguage();

  const actionBindingsGenerator = useCategoryListActions(storeId!);

  const accordionItems: IAccordionListItem[] = useMemo(
    () =>
      categories?.map((category) => {
        const categoryProducts = getProductsByIds(category.items);
        return {
          id: category.id,
          title: getMultilingualContent(language, category.title),
          content: <ProductsListView productsList={categoryProducts} isCategorized />,
          actions: actionBindingsGenerator(category),
        };
      }),
    [categories, actionBindingsGenerator, getProductsByIds, language],
  );

  const onCreateCategory = useCallback(() => {
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.StoreCategoryModal,
        props: {
          storeId,
        },
      }),
    );
  }, [storeId]);

  return (
    <>
      <Paper sx={{ p: "10px", mb: "10px" }}>
        <Typography variant="h6">{t(Translations.PAGE_PROJECT_STORE_CATEGORIES_TITLE)}</Typography>
      </Paper>
      <Paper sx={{ p: "10px", mb: "10px" }}>
        <Grid container sx={{ p: "10px" }} alignContent={"center"}>
          <Grid item xs={1}>
            <InfoIcon color="primary" />
          </Grid>
          <Grid item>
            <Typography variant="body1">{t(Translations.PAGE_PROJECT_STORE_CATEGORIES_SUBTITLE)}</Typography>
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ p: "10px", overflow: "hidden" }}>
        <Grid container direction={"column"} spacing={1}>
          <Grid container item xs={12} justifyContent={"end"}>
            <Button onClick={onCreateCategory}>{t(Translations.PAGE_PROJECT_STORE_CATEGORIES_BUTTON_CREATE)} </Button>
          </Grid>
          <Grid item xs={12}>
            {accordionItems.length ? (
              <AccordionList items={accordionItems} multiple />
            ) : (
              <Grid container justifyContent={"center"}>
                <Typography>{t(Translations.PAGE_PROJECT_STORE_CATEGORIES_LIST_EMPTY)}</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default CategoriesOverview;
