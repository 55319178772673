import { dispatch } from "store/hooks";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";
import BootstrapDialog from "../../common/BootstrapDialog";
import FormBody from "./FormBody";
import FormActions from "./FormActions";
import FormHeader from "./FormHeader";
import { getFormData } from "utils/form";
import React, { useCallback, useMemo } from "react";
import { storeMiddleware } from "store/slices/store";
import useProductsHook from "hooks/dashboardDataHooks/useProductsHook";
import useCategoriesHook from "hooks/dashboardDataHooks/useCategoriesHook";
import { useTranslation } from "react-i18next";
import { Translations } from "constants/translations";

export interface CloneStoreProductModalProps {
  storeId: string;
  productId: string;
}

const CloneStoreProductModal = ({ storeId, productId }: CloneStoreProductModalProps) => {
  const [t] = useTranslation();

  const { getProductById } = useProductsHook();
  const { getProductCategories } = useCategoriesHook();

  const selectedProduct = useMemo(() => getProductById(productId), [productId, getProductById]);
  const productCategories = useMemo(() => getProductCategories(productId), [productId, getProductCategories]);
  const categoryIds = useMemo(() => productCategories.map((category) => category.id), [productCategories]);

  const handleClose = useCallback(() => {
    dispatch(viewsMiddleware.closeModal(ModalName.CloneStoreProductModal));
  }, []);

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      if (!selectedProduct) {
        return;
      }
      event.preventDefault();
      const { name } = getFormData(event);
      const { id, ...rest } = selectedProduct;
      const product = Object.assign({}, rest);
      product.title = name;
      dispatch(
        storeMiddleware.createStoreProduct({
          storeId,
          product,
          categoryIds,
        }),
      );
      dispatch(viewsMiddleware.closeModal(ModalName.CloneStoreProductModal));
    },
    [storeId, selectedProduct, categoryIds],
  );

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby={t(Translations.MODAL_CLONE_PRODUCT_TITLE)}
      PaperProps={{
        component: "form",
        onSubmit,
        sx: { minWidth: "30%" },
      }}
      open
    >
      <FormHeader onClose={handleClose} />
      <FormBody />
      <FormActions onClose={handleClose} />
    </BootstrapDialog>
  );
};

export default CloneStoreProductModal;
