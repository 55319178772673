import { type Language, type MultilingualString } from "dataLayer/types";

// Define the actions
export interface ISetMultilingualContentPayload {
  language: Language;
  value: string;
}

// Reducer function
const multiLingualStringReducer = (
  state: MultilingualString,
  action: ISetMultilingualContentPayload,
): MultilingualString => {
  return {
    ...state,
    [action.language]: action.value,
  };
};

export default multiLingualStringReducer;
