import { useCallback, useMemo } from "react";
import useStoreHook from "./useStoreHook";
import { type DB_StoreTagDocument } from "dataLayer/types/storeTagEndpoints";

const useTagsHook = () => {
  const { store } = useStoreHook();

  const tags: DB_StoreTagDocument[] = useMemo(() => store?.entities.tags ?? [], [store]);

  const getTagById = useCallback((tagId: string) => tags.find((tag) => tag.id === tagId) ?? null, [tags]);

  const getProductTags = useCallback(
    (productId: string | null) => {
      return productId ? tags.filter((tag) => tag.items.includes(productId)) : [];
    },
    [tags],
  );

  return {
    tags,
    getProductTags,
    getTagById,
  };
};

export default useTagsHook;
