import { type PropsWithChildren, useCallback, useReducer, useState } from "react";
import StoreProductContext, { type IStoreProductContextFields } from "./StoreProductContext";
import {
  type ProductCommandConfig,
  type AdditionalProductConfig,
  type StoreProductLimit,
  type DB_StoreProductDocument,
} from "dataLayer/types/storeProductEndpoints";
import { type MultilingualString, type Language } from "dataLayer/types";
import { getMultilingualContentValue, getEmptyMultilingualString } from "utils/language";
import multiLingualStringReducer from "utils/multilingualUtils";

interface StoreProductContextProviderProps extends PropsWithChildren {
  productId: string | null;
  defaultValues: IStoreProductContextFields | null;
}

const StoreProductContextProvider = ({ productId, defaultValues, children }: StoreProductContextProviderProps) => {
  const [title, setTitle] = useReducer(multiLingualStringReducer, defaultValues?.title ?? getEmptyMultilingualString());
  const [description, setDescription] = useReducer(
    multiLingualStringReducer,
    defaultValues?.description ?? getEmptyMultilingualString(),
  );
  const [image, setImage] = useState<string | null>(defaultValues?.image ?? null);
  const [subItems, setSubItems] = useState<string[]>(defaultValues?.subItems ?? []);
  const [commands, setCommands] = useState<ProductCommandConfig[]>(defaultValues?.commands ?? []);
  const [categories, setCategories] = useState<string[]>(defaultValues?.categories ?? []);
  const [price, setPrice] = useState<string>(defaultValues?.price ?? "0");
  const [visibility, setVisibility] = useState<boolean>(defaultValues?.visibility ?? false);
  const [globalLimit, setGlobalLimit] = useState<StoreProductLimit | null>(defaultValues?.globalLimit ?? null);
  const [userLimit, setUserLimit] = useState<StoreProductLimit | null>(defaultValues?.userLimit ?? null);
  const [gifting, setGifting] = useState<boolean>(defaultValues?.gifting ?? false);
  const [alternativeProducts, setAlternativeProducts] = useState<string[] | null>(
    defaultValues?.alternativeProducts ?? null,
  );
  const [additionalProduct, setAdditionalProduct] = useState<AdditionalProductConfig | null>(
    defaultValues?.additionalProduct ?? null,
  );

  const prefillTitle = useCallback(
    (title: MultilingualString) => {
      for (const key in title) {
        setTitle({ language: key as Language, value: getMultilingualContentValue(key as Language, title) });
      }
    },
    [setTitle],
  );

  const prefillDescription = useCallback(
    (description: MultilingualString) => {
      for (const key in description) {
        setDescription({ language: key as Language, value: getMultilingualContentValue(key as Language, description) });
      }
    },
    [setDescription],
  );

  const applyDefaultValues = useCallback(() => {
    prefillTitle(defaultValues?.title ?? getEmptyMultilingualString());
    prefillDescription(defaultValues?.description ?? getEmptyMultilingualString());
    setImage(defaultValues?.image ?? null);
    setSubItems(defaultValues?.subItems ?? []);
    setCommands(defaultValues?.commands ?? []);
    setCategories(defaultValues?.categories ?? []);
    setPrice(defaultValues?.price ?? "0");
    setVisibility(defaultValues?.visibility ?? false);
    setGlobalLimit(defaultValues?.globalLimit ?? null);
    setUserLimit(defaultValues?.userLimit ?? null);
    setGifting(defaultValues?.gifting ?? false);
    setAlternativeProducts(defaultValues?.alternativeProducts ?? null);
    setAdditionalProduct(defaultValues?.additionalProduct ?? null);
  }, [defaultValues, prefillTitle, prefillDescription]);

  const prefillFields = useCallback(
    (product: DB_StoreProductDocument | null) => {
      if (!product) {
        applyDefaultValues();
        return;
      }
      prefillTitle(product.title);
      prefillDescription(product.description);
      setImage(product.image);
      setSubItems(product.subItems);
      setCommands(product.commands);
      setPrice(product.price);
      setVisibility(product.visibility);
      setGlobalLimit(product.limits.global);
      setUserLimit(product.limits.user);
      setGifting(product.giftingConfig.enabled);
      setAlternativeProducts(product.upSellConfig.alternativeProducts);
      setAdditionalProduct(product.upSellConfig.additionalProduct);
    },
    [applyDefaultValues, prefillTitle, prefillDescription],
  );

  return (
    <StoreProductContext.Provider
      value={{
        productId,
        title,
        description,
        image,
        subItems,
        commands,
        categories,
        price,
        visibility,
        globalLimit,
        userLimit,
        gifting,
        alternativeProducts,
        additionalProduct,
        setTitle,
        setDescription,
        setImage,
        setSubItems,
        setCommands,
        setCategories,
        setPrice,
        setVisibility,
        setGlobalLimit,
        setUserLimit,
        setGifting,
        setAlternativeProducts,
        setAdditionalProduct,
        prefillFields,
      }}
    >
      {children}
    </StoreProductContext.Provider>
  );
};

export default StoreProductContextProvider;
