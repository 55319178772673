import { useTranslation } from "react-i18next";
import PortalLocalizationProvider from "./PortalLocalizationProvider";
import { MobileDateTimePicker, type MobileDateTimePickerProps } from "@mui/x-date-pickers";
import { useState } from "react";

const PortalDateTimePicker = <T,>({ label, ...props }: MobileDateTimePickerProps<T>) => {
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);
  return (
    <PortalLocalizationProvider>
      <MobileDateTimePicker
        {...props}
        label={typeof label === "string" ? t(label) : label}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        ampm={false}
      />
    </PortalLocalizationProvider>
  );
};

export default PortalDateTimePicker;
