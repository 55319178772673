import {
  type ConnectionType,
  type AllowedUsers,
  type DB_Record,
  type SupportedEncoding,
  type IDnsRecordsMap,
  type MultilingualString,
  type Language,
} from ".";
import { type DB_StoreProductDocument } from "./storeProductEndpoints";
import { type DB_StoreCategoryDocument } from "./storeCategoryEndpoints";
import { type PortalGetProjectResponse } from "./projectEndpoints";
import { type DB_StoreTagDocument } from "./storeTagEndpoints";

export interface PortalCreateStoreBody {
  title: MultilingualString;
  subdomain: string;
  icon: string | null;
  customDomain: string | null;
  projectId: string;
  storeType: string;
  supportedLanguages: Language[];
  fallbackLanguage: Language;
  timeZone: string;
}

export type PortalCreateStoreResponse = PortalGetProjectResponse;

export interface PortalUpdateStoreBody extends DB_Record {
  projectId: string;
  title: MultilingualString;
  icon: string | null;
  settings: StoreSettings;
}

export type PortalUpdateStoreResponse = PortalGetProjectResponse;

export interface PortalGetStoreBody extends DB_Record {}

export interface PortalRemoveStoreBody {
  projectId: string;
  storeId: string;
}

export type PortalRemoveStoreResponse = PortalGetProjectResponse;

export interface PortalStoreResponse extends DB_StoreDocument {}

export enum StorePermission {
  OWNER = "OWNER",
  READ = "READ",
  WRITE = "WRITE",
  RENAME = "RENAME",
  CONFIG = "CONFIG",
  WITHDRAW = "WITHDRAW",
  PAYMENT_DETAILS = "PAYMENT_DETAILS",
  DELETE = "DELETE",
}

export interface DB_StoreDocument extends AllowedUsers<StorePermission>, DB_Record {
  projectId: string;
  type: string;
  title: MultilingualString;
  icon: string | null;
  settings: StoreSettings;
  entities: StoreEntities;
}

export type ServerConfigurationType = null | ServerDetailsRCON;

export enum ServerConnectionStatus {
  PENDING = "PENDING",
  CONNECTED = "CONNECTED",
  DISCONNECTED = "DISCONNECTED",
}

export interface ServerConfiguration<T extends ServerConfigurationType> {
  details: T;
  connectionStatus: ServerConnectionStatus;
  type: ConnectionType;
}

export interface ServerDetailsRCON {
  host: string | null;
  port: number | null;
  password: string | null;
  encoding: SupportedEncoding;
}

export interface StorePageStyles {
  backgroundURL: string;
  logoURL: string;
}

export interface StoreCheckoutSettings {
  customerSupportEmail: string | null;
  testMode: boolean; // true by default
}

export interface StoreInformationalPage extends DB_Record {
  path: string;
  isEnabled: boolean;
  title: string | null;
  subtitle: string | null;
  content: string;
}

export interface StoreSettings {
  isPublic: boolean;
  style: StorePageStyles | null;
  subdomain: string;
  customDomain: string | null;
  serverConfiguration: ServerConfiguration<ServerConfigurationType>;
  checkoutSettings: StoreCheckoutSettings;
  pages: StoreInformationalPage[];
  supportedLanguages: Language[];
  fallbackLanguage: Language;
  timeZone: string;
}

export interface StoreEntities {
  categories: DB_StoreCategoryDocument[];
  tags: DB_StoreTagDocument[];
  products: DB_StoreProductDocument[];
}

export interface PortalCheckSubdomainBody {
  storeId: string | null;
  storeTypeKey: string;
  subdomain: string;
}

export interface PortalCheckSubdomainResponse {
  isAvailable: boolean;
}

export interface PortalGetDnsRecordsBody {
  url: string;
}

export interface PortalGetDnsRecordsResponse {
  records: IDnsRecordsMap;
}
