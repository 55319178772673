import { Box, DialogContent, DialogContentText } from "@mui/material";
interface FormBodyProps {
  subtitle: string;
}
const FormBody = ({ subtitle }: FormBodyProps) => {
  return (
    <DialogContent dividers>
      <Box sx={{ pb: "14px" }}>
        <DialogContentText>{subtitle}</DialogContentText>
      </Box>
    </DialogContent>
  );
};

export default FormBody;
