import { DialogContent, IconButton, TextField } from "@mui/material";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useCallback, useState } from "react";

const FormBody = () => {
  const [t] = useTranslation();
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const togglePasswordVisibility = useCallback(() => {
    setPasswordVisible(!passwordVisible);
  }, [passwordVisible, setPasswordVisible]);
  return (
    <>
      <DialogContent>
        <TextField
          autoFocus
          required
          margin="dense"
          key={Translations.MODAL_SIGN_IN_FIELD_EMAIL}
          name="email"
          label={t(Translations.MODAL_SIGN_IN_FIELD_EMAIL)}
          type="email"
          fullWidth
          variant="standard"
        />
        <TextField
          autoFocus
          required
          margin="dense"
          key={Translations.MODAL_SIGN_IN_FIELD_PASSWORD}
          name="password"
          label={t(Translations.MODAL_SIGN_IN_FIELD_PASSWORD)}
          type={passwordVisible ? "text" : "password"}
          fullWidth
          variant="standard"
          inputProps={{
            minLength: 8,
            maxLength: 20,
          }}
          InputProps={{
            endAdornment: (
              <IconButton onClick={togglePasswordVisibility}>
                {passwordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            ),
          }}
        />
      </DialogContent>
    </>
  );
};

export default FormBody;
