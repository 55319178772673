import { useCallback, useContext, useMemo } from "react";
import StoreModalContext from "../../../context/StoreModalContext";
import { Grid, TextField, CircularProgress } from "@mui/material";
import { Translations } from "constants/translations";
import { t } from "i18next";
import useStoreTypes from "pages/Dashboard/hooks/useStoreTypes";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { dispatch, useAppSelector } from "store/hooks";
import { storeMiddleware, storeSelector } from "store/slices/store";
import { LoadingButton } from "@mui/lab";
import { getEnvironmentVariables } from "utils/getEnvironmentVariables";

const subdomainRegex = /^(?!-)[A-Za-z0-9-]{1,63}(?<!-)$/;

const { BASE_DOMAIN } = getEnvironmentVariables();

const SubDomainField = () => {
  const subdomainCheckResult = useAppSelector(storeSelector.subdomainCheckResult);
  const subdomainCheckResultLoading = useAppSelector(storeSelector.subdomainCheckResultLoading);

  const isChecked = subdomainCheckResult !== null;

  const { getStoreTypeById } = useStoreTypes();

  const { storeType, subdomain, setSubdomain } = useContext(StoreModalContext);

  const regexError = useMemo(() => subdomain && !subdomainRegex.test(subdomain), [subdomain]);

  const checkIcon = useMemo(() => {
    if (subdomain) {
      if (subdomainCheckResultLoading) {
        return <CircularProgress />;
      }
      if (!isChecked) {
        return <WarningIcon color="warning" aria-label="Warning" />;
      }
      if (!subdomainCheckResult) {
        return <ErrorIcon color="error" aria-label="Error" />;
      }
      return <CheckCircleIcon color="success" />;
    } else {
      return null;
    }
  }, [subdomain, subdomainCheckResultLoading, isChecked, subdomainCheckResult]);

  const subdomainHelperText = useMemo(() => {
    if (isChecked && !subdomainCheckResult) {
      return t(Translations.MODAL_CREATE_STORE_FORM_FIELD_SUBDOMAIN_ERROR);
    } else if (regexError) {
      return t(Translations.MODAL_CREATE_STORE_FORM_FIELD_SUBDOMAIN_ERROR);
    }
    return null;
  }, [regexError, isChecked, subdomainCheckResult]);

  const storeTypeDetails = getStoreTypeById(storeType);

  const subdomainPreview = useMemo(() => {
    const sub = subdomain ?? "*";
    return `${sub}.${storeTypeDetails?.key}.${BASE_DOMAIN}`;
  }, [storeTypeDetails, subdomain]);

  const onSubdomainCheck = useCallback(() => {
    if (!subdomain || !storeTypeDetails) {
      return;
    }
    dispatch(
      storeMiddleware.checkSubdomain({
        storeId: null,
        subdomain,
        storeTypeKey: storeTypeDetails.key,
      }),
    );
  }, [subdomain, storeTypeDetails]);

  const resetSubdomainCheck = useCallback(() => {
    if (subdomainCheckResult === null) {
      return;
    }
    dispatch(storeMiddleware.resetSubdomainCheck());
  }, [subdomainCheckResult]);

  return (
    <Grid item container xs={12} alignItems="center" spacing={1}>
      <Grid item xs={5}>
        <TextField
          required
          margin="dense"
          key={Translations.MODAL_CREATE_STORE_FORM_FIELD_SUBDOMAIN}
          name="subdomain"
          label={t(Translations.MODAL_CREATE_STORE_FORM_FIELD_SUBDOMAIN)}
          type="text"
          fullWidth
          variant="outlined"
          defaultValue={subdomain?.toLowerCase()}
          helperText={subdomainHelperText}
          error={!!subdomainHelperText}
          inputProps={{
            maxLength: 16,
          }}
          InputProps={{
            endAdornment: checkIcon,
          }}
          onChange={(_) => {
            resetSubdomainCheck();
            setSubdomain(_.target.value.toLowerCase());
          }}
        />
      </Grid>
      <Grid item xs={5}>
        <TextField
          margin="dense"
          key={Translations.MODAL_CREATE_STORE_FORM_FIELD_SUBDOMAIN_PREVIEW}
          label={t(Translations.MODAL_CREATE_STORE_FORM_FIELD_SUBDOMAIN_PREVIEW)}
          type="text"
          fullWidth
          variant="outlined"
          disabled
          value={subdomainPreview}
        />
      </Grid>
      <Grid item container xs={2} alignItems="center">
        <LoadingButton
          variant="text"
          size="large"
          onClick={onSubdomainCheck}
          color="primary"
          disabled={!subdomain}
          loading={subdomainCheckResultLoading}
        >
          {t(Translations.MODAL_CREATE_STORE_FORM_BUTTON_CHECK)}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default SubDomainField;
