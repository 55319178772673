import { dispatch } from "store/hooks";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";
import BootstrapDialog from "../../common/BootstrapDialog";
import FormBody from "./FormBody";
import FormActions from "./FormActions";
import FormHeader from "./FormHeader";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Translations } from "constants/translations";

export interface SelectProductModalProps {
  selectionCallback: (selectedProductIds: string[]) => void;
  preselectedProductIds: string[];
  purpose?: string;
  multiple: boolean;
}

const SelectProductModal = ({
  preselectedProductIds,
  selectionCallback,
  multiple,
  purpose,
}: SelectProductModalProps) => {
  const [t] = useTranslation();
  const [selectedItems, setSelectedItems] = useState<string[]>(preselectedProductIds ?? []);

  const handleClose = useCallback(() => {
    dispatch(viewsMiddleware.closeModal(ModalName.SelectProductModal));
  }, []);

  const onSubmit = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();
      selectionCallback(selectedItems);
      handleClose();
    },
    [selectedItems, selectionCallback, handleClose],
  );

  const onItemClick = useCallback(
    (productId: string) => {
      if (multiple) {
        if (!selectedItems.includes(productId)) {
          const newItemsList = [...selectedItems, productId];
          setSelectedItems(newItemsList);
        } else {
          const newItemsList = selectedItems.filter((id) => id !== productId);
          setSelectedItems(newItemsList);
        }
      } else {
        if (selectedItems.length) {
          setSelectedItems(productId === selectedItems[0] ? [] : [productId]);
        } else {
          setSelectedItems([productId]);
        }
      }
    },
    [multiple, selectedItems, setSelectedItems],
  );

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby={t(Translations.MODAL_SELECT_STORE_PRODUCT_TITLE)}
      PaperProps={{
        component: "form",
        onSubmit,
        sx: { minWidth: "50%" },
      }}
      open
    >
      <FormHeader onClose={handleClose} purpose={purpose} />
      <FormBody selectedItems={selectedItems} onItemClick={onItemClick} />
      <FormActions onClose={handleClose} />
    </BootstrapDialog>
  );
};

export default SelectProductModal;
