import useProject from "pages/Dashboard/hooks/useProject";
import { useCallback, useContext, useMemo } from "react";
import StoreModalContext from "../../../context/StoreModalContext";
import { dispatch, useAppSelector } from "store/hooks";
import { storeMiddleware, storeSelector } from "store/slices/store";
import { type PortalCreateStoreBody } from "dataLayer/types/storeEndpoints";
import { getMultilingualContent } from "utils/language";

const useStoreModalHooks = (termsAgreed: boolean) => {
  const { PROJECT } = useProject();
  const subdomainCheckResult = useAppSelector(storeSelector.subdomainCheckResult);
  const context = useContext(StoreModalContext);

  const createStore = useCallback(() => {
    if (!PROJECT) {
      return;
    }
    const body: PortalCreateStoreBody = {
      title: context.storeTitle,
      customDomain: context.url,
      storeType: context.storeType!,
      subdomain: context.subdomain!,
      icon: context.icon ?? null,
      projectId: PROJECT.id,
      supportedLanguages: context.supportedLanguages,
      fallbackLanguage: context.fallbackLanguage!,
      timeZone: context.timeZone,
    };
    dispatch(storeMiddleware.createStore(body));
  }, [PROJECT, context]);

  const isSubmitEnabled = useMemo(
    () =>
      !!context.subdomain &&
      !!subdomainCheckResult &&
      termsAgreed &&
      context.supportedLanguages?.length > 0 &&
      !!context.fallbackLanguage &&
      !!getMultilingualContent(context.fallbackLanguage, context.storeTitle),
    [context, termsAgreed, subdomainCheckResult],
  );

  return { onSubmit: createStore, isSubmitEnabled };
};
export default useStoreModalHooks;
