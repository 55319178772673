import { useContext, useMemo } from "react";
import StoreProductContext from "../../context/StoreProductContext";
import { TextField } from "@mui/material";
import { Translations } from "constants/translations";
import { t } from "i18next";
import MultilingualFieldContext from "components/common/MultilingualFieldWrapper/context/MultilingualFieldContext";
import { getMultilingualContentValue } from "utils/language";

const ProductTitleField = () => {
  const { title, setTitle } = useContext(StoreProductContext);
  const { language } = useContext(MultilingualFieldContext);

  const value = useMemo(() => getMultilingualContentValue(language, title), [title, language]);

  return (
    <TextField
      autoFocus
      required
      margin="dense"
      key={Translations.MODAL_CREATE_PRODUCT_FIELD_NAME}
      name={Translations.MODAL_CREATE_PRODUCT_FIELD_IMAGE}
      label={t(Translations.MODAL_CREATE_PRODUCT_FIELD_NAME)}
      type="text"
      fullWidth
      variant="outlined"
      value={value ?? ""}
      onChange={(_) => {
        setTitle({ language, value: _.target.value });
      }}
    />
  );
};

export default ProductTitleField;
