import { useState, type PropsWithChildren } from "react";
import { type TabulatedItemProps } from "./types";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";

interface TabulatedContentProps {
  differentiator: string;
  items: TabulatedItemProps[];
}

interface ContentProps extends PropsWithChildren {
  tabLabel: string;
  index: number;
  value: number;
}
const Content = ({ tabLabel, value, index, children }: ContentProps) => {
  return (
    <div role="tabpanel" hidden={value !== index} id={`${tabLabel}-${index}`} aria-labelledby={`${tabLabel}-${index}`}>
      {value === index ? (
        <Box sx={{ p: 3 }}>
          <Grid container item xs={12}>
            {children}
          </Grid>
        </Box>
      ) : null}
    </div>
  );
};

const TabulatedContent = ({ differentiator, items }: TabulatedContentProps) => {
  const [value, setValue] = useState<number>(0);
  const [t] = useTranslation();

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={(_, value) => {
            setValue(value);
          }}
        >
          {items.map((item) => (
            <Tab key={`${item.label}-tab`} label={t(item.label)} />
          ))}
        </Tabs>
      </Box>
      {items.map((item, index) => (
        <Content key={`${differentiator}-${item.label}-content`} value={value} index={index} tabLabel={differentiator}>
          {item.children}
        </Content>
      ))}
    </Box>
  );
};

export default TabulatedContent;
