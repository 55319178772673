import { Grid, Paper, Typography } from "@mui/material";
import useProject from "pages/Dashboard/hooks/useProject";
import ProjectStoreCard from "../ProjectStoreCard";
import { useTranslation } from "react-i18next";
import { Translations } from "constants/translations";

const ProjectOverview = () => {
  const [t] = useTranslation();
  const { PROJECT } = useProject();

  return (
    <>
      <Paper sx={{ p: "10px", mb: "10px" }}>
        <Typography variant="h6">{t(Translations.PAGE_PROJECT_STORE_TITLE)}</Typography>
      </Paper>
      {PROJECT ? (
        <Paper sx={{ p: "10px" }}>
          <Grid item container xs={12}>
            {PROJECT.stores.map((storeData) => (
              <ProjectStoreCard key={storeData.id} storeData={storeData} />
            ))}
            <ProjectStoreCard storeData={null} />
          </Grid>
        </Paper>
      ) : null}
    </>
  );
};

export default ProjectOverview;
