import FieldPaper from "../../../../common/FieldPaper";
import ProductGiftingField from "../fields/ProductGiftingField";

const ProductGiftingSection = () => {
  return (
    <FieldPaper>
      <ProductGiftingField />
    </FieldPaper>
  );
};

export default ProductGiftingSection;
