import { Grid } from "@mui/material";
import { Translations } from "constants/translations";
import { useContext } from "react";
import StoreProductContext from "../../context/StoreProductContext";
import ProductLimitFields from "../fields/ProductLimitFields";

const ProductLimitsSection = () => {
  const { globalLimit, setGlobalLimit, userLimit, setUserLimit } = useContext(StoreProductContext);

  const globalLabel = Translations.MODAL_CREATE_PRODUCT_TAB_LIMITS_SECTION_GLOBAL;
  const userLabel = Translations.MODAL_CREATE_PRODUCT_TAB_LIMITS_SECTION_USER;

  return (
    <Grid container item xs={12} direction={"column"} spacing={2}>
      <Grid item>
        <ProductLimitFields label={globalLabel} state={globalLimit} setState={setGlobalLimit} />
      </Grid>
      <Grid item>
        <ProductLimitFields label={userLabel} state={userLimit} setState={setUserLimit} />
      </Grid>
    </Grid>
  );
};

export default ProductLimitsSection;
