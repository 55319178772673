import { Grid } from "@mui/material";
import FieldToggleWrapper from "components/modals/common/FieldToggleWrapper";
import { useContext, useEffect } from "react";
import StoreCategoryContext from "../../context/StoreCategoryContext";
import { type CategoryVisibilityConfig } from "dataLayer/types/storeCategoryEndpoints";
import { Translations } from "constants/translations";
import VisibilityConfigFields from "../fields/VisibilityConfigFields";

const enabledVisibilityConfig: CategoryVisibilityConfig = {
  startDate: new Date(),
  endDate: new Date(),
};
const CategoryVisibilitySection = () => {
  const { setIsVisible, visibilityConfig, setVisibilityConfig } = useContext(StoreCategoryContext);

  useEffect(() => {
    setIsVisible(!visibilityConfig);
  }, [visibilityConfig, setIsVisible]);

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <FieldToggleWrapper
          state={visibilityConfig}
          setState={setVisibilityConfig}
          defaultEnabledState={enabledVisibilityConfig}
          defaultDisabledState={null}
          label={Translations.MODAL_CREATE_CATEGORY_FIELD_VISIBILITY_CONFIG}
        >
          <VisibilityConfigFields />
        </FieldToggleWrapper>
      </Grid>
    </Grid>
  );
};

export default CategoryVisibilitySection;
