import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";

const ProjectSettingsButton = () => {
  return (
    <IconButton>
      <SettingsIcon />
    </IconButton>
  );
};

export default ProjectSettingsButton;
