import { Card, CardActionArea, CardMedia, styled } from "@mui/material";
import { type StoreTypeCardProps } from "./StoreTypeCard";
import useLanguage from "hooks/useLanguage";
import { getMultilingualContent } from "utils/language";

interface StyledCardProps {}

const StyledCard = styled(Card)((props: StyledCardProps) => ({
  opacity: 0.3,
}));

const StoreTypeSoonCard = ({ config }: StoreTypeCardProps) => {
  const { language } = useLanguage();
  return (
    <StyledCard>
      <CardActionArea disabled>
        <CardMedia
          component="img"
          image={config.backgroundImageURL ?? ""}
          alt={getMultilingualContent(language, config.title)}
        />
      </CardActionArea>
    </StyledCard>
  );
};

export default StoreTypeSoonCard;
