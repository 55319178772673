import { DialogContent, Grid } from "@mui/material";

import TagTitleField from "./components/fields/TagTitleField";
import TagProductsField from "./components/fields/TagProductsField";
import MultilingualFieldWrapper from "components/common/MultilingualFieldWrapper";
import useStoreHook from "hooks/dashboardDataHooks/useStoreHook";

const FormBody = () => {
  const { store } = useStoreHook();
  return (
    <DialogContent dividers>
      <MultilingualFieldWrapper supportedLanguages={store!.settings.supportedLanguages}>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={12}>
            <TagTitleField />
          </Grid>
          <Grid item xs={12}>
            <TagProductsField />
          </Grid>
        </Grid>
      </MultilingualFieldWrapper>
    </DialogContent>
  );
};

export default FormBody;
