import FieldPaper from "../../../../common/FieldPaper";
import ProductPriceField from "../fields/ProductPriceField";

const ProductPricingSection = () => {
  return (
    <FieldPaper>
      <ProductPriceField />
    </FieldPaper>
  );
};

export default ProductPricingSection;
