import { Translations } from "constants/translations";
import { useMemo } from "react";
import useProject from "./useProject";
import useProjectStore from "./useProjectStore";

const useDashboardNavigationTitle = () => {
  const { PROJECT } = useProject();
  const { STORE } = useProjectStore();

  const navigationTitle = useMemo(() => {
    if (!PROJECT) {
      return Translations.LAYOUT_DASHBOARD_TITLE;
    }
    if (!STORE) {
      return Translations.LAYOUT_DASHBOARD_PROJECT_NAVIGATION_TITLE;
    }
    return Translations.LAYOUT_DASHBOARD_STORE_NAVIGATION_TITLE;
  }, [PROJECT, STORE]);

  return navigationTitle;
};

export default useDashboardNavigationTitle;
