import StoreProductContextProvider from "./context/StoreProductContextProvider";
import CreateStoreProductModalDialog from "./Dialog";
import { convertStoreProductToContext } from "utils/convertingUtils";
import useProductsHook from "hooks/dashboardDataHooks/useProductsHook";
import useCategoriesHook from "hooks/dashboardDataHooks/useCategoriesHook";

export interface StoreProductModalProps {
  storeId: string;
  productId: string | null;
}

const StoreProductModal = ({ storeId, productId }: StoreProductModalProps) => {
  const { getProductById } = useProductsHook();
  const { getProductCategories } = useCategoriesHook();
  const targetProduct = productId ? getProductById(productId) : null;
  const productCategories = targetProduct ? getProductCategories(targetProduct.id) : [];
  const categoryIds = productCategories.map((category) => category.id);

  const defaultValues = targetProduct ? convertStoreProductToContext(targetProduct, categoryIds) : null;

  return (
    <StoreProductContextProvider productId={productId} defaultValues={defaultValues}>
      <CreateStoreProductModalDialog storeId={storeId} />
    </StoreProductContextProvider>
  );
};

export default StoreProductModal;
