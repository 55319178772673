import { TextField } from "@mui/material";
import StoreSettingsContext from "components/Dashboard/ProjectStore/SettingsOverview/context/StoreSettingsContext";
import { Translations } from "constants/translations";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

const RconPasswordField = () => {
  const [t] = useTranslation();
  const { serverDetails, setServerDetails } = useContext(StoreSettingsContext);

  const setPassword = useCallback(
    (value: string) => {
      setServerDetails({ property: "password", value });
    },
    [setServerDetails],
  );

  return (
    <TextField
      required
      margin="dense"
      key={Translations.PAGE_PROJECT_STORE_SETTINGS_RCON_PASSWORD}
      name="server-password"
      label={t(Translations.PAGE_PROJECT_STORE_SETTINGS_RCON_PASSWORD)}
      type="password"
      fullWidth
      variant="outlined"
      value={serverDetails.password}
      onChange={(_) => {
        setPassword(_.target.value);
      }}
    />
  );
};

export default RconPasswordField;
