import { Avatar } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Translations } from "constants/translations";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { useTranslation } from "react-i18next";
import { dispatch, useAppSelector } from "store/hooks";
import { coreSelector } from "store/slices/core";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";

const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

const pickTextColor = (bgColor: string) => {
  const color = bgColor.substring(1); // strip #
  const rgb = parseInt(color, 16); // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff; // extract red
  const g = (rgb >> 8) & 0xff; // extract green
  const b = (rgb >> 0) & 0xff; // extract blue

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

  const isDark = luma < 40;

  return isDark ? "#ffffff" : "#000000";
};

const stringAvatar = (name: string) => {
  const bgColor: string = stringToColor(name);
  const textColor: string = pickTextColor(bgColor);
  return {
    sx: {
      bgColor,
      color: textColor,
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
};

const AppBarAvatar = () => {
  const [t] = useTranslation();
  const { user } = useAppSelector(coreSelector.authentication);
  const onAccountClick = (popupCallback: () => void) => {
    popupCallback();
  };
  const onProfileClick = (popupCallback: () => void) => {
    popupCallback();
    dispatch(viewsMiddleware.openModal({ name: ModalName.ProfileDetails, props: null }));
  };
  const onSignOutClick = (popupCallback: () => void) => {
    dispatch(viewsMiddleware.openModal({ name: ModalName.SignOutModal, props: null }));
    popupCallback();
  };
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <>
          <Avatar {...stringAvatar(`${user?.firstName} ${user?.lastName}`)} {...bindTrigger(popupState)} />
          <Menu {...bindMenu(popupState)}>
            <MenuItem
              onClick={() => {
                onProfileClick(popupState.close);
              }}
            >
              {t(Translations.AVATAR_CONTEXT_MENU_BUTTON_PROFILE)}
            </MenuItem>
            <MenuItem
              onClick={() => {
                onAccountClick(popupState.close);
              }}
            >
              {t(Translations.AVATAR_CONTEXT_MENU_BUTTON_ACCOUNT)}
            </MenuItem>
            <MenuItem
              onClick={() => {
                onSignOutClick(popupState.close);
              }}
            >
              {t(Translations.AVATAR_CONTEXT_MENU_BUTTON_SIGN_OUT)}
            </MenuItem>
          </Menu>
        </>
      )}
    </PopupState>
  );
};

export default AppBarAvatar;
