import { DialogContent } from "@mui/material";
import { useContext, useMemo } from "react";
import GameSelectionStep from "./steps/StoreSelectionStep";
import StoreModalContext from "./context/StoreModalContext";
import FormFillStep from "./steps/FormFillStep";

const FormBody = () => {
  const { step } = useContext(StoreModalContext);

  const currentStep = useMemo(() => {
    switch (step) {
      case 0:
        return <GameSelectionStep />;
      case 1:
        return <FormFillStep />;

      default:
        return null;
    }
  }, [step]);
  return (
    <>
      <DialogContent>{currentStep}</DialogContent>
    </>
  );
};

export default FormBody;
