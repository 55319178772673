import { Grid } from "@mui/material";
import RconHostField from "./fields/RconHostField";
import RconPortField from "./fields/RconPortField";
import RconPasswordField from "./fields/RconPasswordField";
import RconEncodingField from "./fields/RconEncodingField";
import StoreServerConnection from "./components/StoreServerConnection";

const StoreServerConfigurationSettings = () => {
  return (
    <Grid container sx={{ padding: "10px" }} spacing={2}>
      <StoreServerConnection />
      <Grid item xs={12}>
        <RconHostField />
      </Grid>
      <Grid item xs={12}>
        <RconPortField />
      </Grid>
      <Grid item xs={12}>
        <RconPasswordField />
      </Grid>
      <Grid item xs={12}>
        <RconEncodingField />
      </Grid>
    </Grid>
  );
};

export default StoreServerConfigurationSettings;
