import { type ISelectOption } from "components/common/types";
import { type IStoreCategoryContextFields } from "components/modals/Store/StoreCategoryModal/context/StoreCategoryContext";
import { type IStoreProductContextFields } from "components/modals/Store/StoreProductModal/context/StoreProductContext";
import { Translations } from "constants/translations";
import { type Language, type DB_Record } from "dataLayer/types";
import { type DB_StoreCategoryDocument } from "dataLayer/types/storeCategoryEndpoints";
import { type DB_StoreProductDocument } from "dataLayer/types/storeProductEndpoints";
import { convertToIso } from "./date";
import { getMultilingualContent } from "./language";
import { type DB_StoreTagDocument } from "dataLayer/types/storeTagEndpoints";

export const representBooleanAsString = (value: boolean) => {
  return value ? Translations.COMMON_BOOLEAN_TRUE : Translations.COMMON_BOOLEAN_FALSE;
};

export const getOptionById = <T extends DB_Record>(options: T[], id: string) => {
  return options.find((option) => option.id === id)!;
};

export const convertCategoriesIntoOptions = (
  language: Language,
  items: readonly DB_StoreCategoryDocument[],
): ISelectOption[] => {
  return items.map((item) => ({
    id: item.id,
    title: getMultilingualContent(language, item.title),
  }));
};

export const convertTagsIntoOptions = (language: Language, items: readonly DB_StoreTagDocument[]): ISelectOption[] => {
  return items.map((item) => ({
    id: item.id,
    title: getMultilingualContent(language, item.title),
  }));
};

export const convertProductsIntoOptions = (
  language: Language,
  items: readonly DB_StoreProductDocument[],
  currentItemId: string | null,
): ISelectOption[] => {
  const filteredItems = currentItemId ? items.filter((item) => item.id !== currentItemId) : items;
  return filteredItems.map((item) => ({
    id: item.id,
    title: getMultilingualContent(language, item.title),
  }));
};
export const convertStringToDbRecords = (items: string[]): DB_Record[] => {
  return items.map((id) => ({ id }));
};

export const convertStoreProductToContext = (
  product: DB_StoreProductDocument,
  categories: string[],
): IStoreProductContextFields => {
  return {
    title: product.title,
    description: product.description,
    image: product.image,
    subItems: product.subItems,
    commands: product.commands,
    categories,
    price: product.price,
    visibility: product.visibility,
    globalLimit: product.limits.global,
    userLimit: product.limits.user,
    gifting: product.giftingConfig.enabled,
    alternativeProducts: product.upSellConfig.alternativeProducts,
    additionalProduct: product.upSellConfig.additionalProduct,
  };
};

export const convertStoreCategoryToContext = (category: DB_StoreCategoryDocument): IStoreCategoryContextFields => {
  return {
    title: category.title,
    isEnabled: category.isEnabled,
    items: category.items,
    isVisible: category.visibility.isVisible,
    visibilityConfig: category.visibility.visibilityConfig
      ? {
          startDate: convertToIso(category.visibility.visibilityConfig.startDate as Date),
          endDate: convertToIso(category.visibility.visibilityConfig.endDate as Date),
        }
      : null,
  };
};
