import { type SliceCaseReducers } from "@reduxjs/toolkit";
import { type IAction } from "store/store";
import { type IProjectMeta, type IDashboardSliceState } from "store/types/dashboard";

const createReducer = <T extends SliceCaseReducers<IDashboardSliceState>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setIsProjectCreateLoading(state, action: IAction<boolean>) {
    state.userProjects.isCreateLoading = action.payload;
  },
  setUserProjects(state, action: IAction<IProjectMeta[] | null>) {
    state.userProjects = {
      ...state.userProjects,
      list: action.payload,
    };
  },
  updateUserSingleProject(state, action: IAction<IProjectMeta>) {
    if (!state.userProjects.list) {
      return;
    }
    const updatedProject = action.payload;
    const index = state.userProjects.list.findIndex((projectMeta) => projectMeta.id === updatedProject.id);
    state.userProjects.list[index] = updatedProject;
  },
  deleteProject(state, action: IAction<string>) {
    if (!state.userProjects.list) {
      return;
    }
    const projectId = action.payload;
    state.userProjects.list = state.userProjects.list.filter((projectMeta) => projectMeta.id !== projectId);
  },
  updateUserProjects(state, action: IAction<IProjectMeta[]>) {
    const currentList: IProjectMeta[] = state.userProjects.list ?? [];
    const payloadList = action.payload ?? [];
    const mixed = [...currentList, ...payloadList];
    const map = new Map<string, IProjectMeta>();
    mixed.forEach((projectData) => map.set(projectData.id, projectData));
    const uniqueList = Array.from(map.values());
    state.userProjects = {
      ...state.userProjects,
      list: uniqueList,
    };
  },
});

export default reducers;
