import { useCallback } from "react";

const useDocumentTitle = () => {
  const getDocumentTitle = useCallback(() => document.title, []);
  const setDocumentTitle = useCallback((title: string) => {
    document.title = title;
  }, []);

  return { getDocumentTitle, setDocumentTitle };
};

export default useDocumentTitle;
