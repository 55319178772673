import { contextSetterImitator } from "constants/context";
import { type MultilingualString } from "dataLayer/types";
import { type Dispatch, type SetStateAction, createContext } from "react";
import { getEmptyMultilingualString } from "utils/language";
import { type ISetMultilingualContentPayload } from "utils/multilingualUtils";

export interface IStoreTagContextFields {
  title: MultilingualString;
  items: string[];
}
export interface IStoreTagContext extends IStoreTagContextFields {
  storeId: string;
  tagId: string | null;
  setTitle: Dispatch<ISetMultilingualContentPayload>;
  setItems: Dispatch<SetStateAction<string[]>>;
}

const StoreTagContext = createContext<IStoreTagContext>({
  storeId: "",
  tagId: null,
  title: getEmptyMultilingualString(),
  items: [],
  setTitle: contextSetterImitator,
  setItems: contextSetterImitator,
});

export default StoreTagContext;
