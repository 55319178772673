import { forwardRef, useContext } from "react";
import StoreProductContext from "../../context/StoreProductContext";

const ProductImagePreview = forwardRef<HTMLImageElement>((_, ref) => {
  const { image } = useContext(StoreProductContext);

  return <img ref={ref} src={image ?? ""} style={{ maxWidth: "100%" }} />;
});

ProductImagePreview.displayName = "ProductImagePreview";

export default ProductImagePreview;
