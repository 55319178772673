import { type IViewsSliceState } from "store/types/views";

export const getInitialState = (): IViewsSliceState => ({
  redirection: {
    path: "/",
    params: "",
    apply: false,
  },
  modals: [],
  toastNotificationPopUp: {
    open: false,
    props: null,
  },
});
