import { LoadingButton } from "@mui/lab";
import { Grid, TextField } from "@mui/material";
import MultilingualFieldWrapper from "components/common/MultilingualFieldWrapper";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";
import StoreSettingsContext from "../../../context/StoreSettingsContext";
import { useCallback, useContext } from "react";
import { type ShowDnsRecordsModalProps } from "components/modals/Store/ShowDnsRecordsModal";
import { dispatch } from "store/hooks";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";
import SettingsStoreTitleField from "./fields/SettingsStoreTitleField";
import SubDomainField from "./fields/SubDomainField";
import useStoreHook from "hooks/dashboardDataHooks/useStoreHook";

const subdomainRegex = /^(?!-)[a-z0-9-]{1,63}(?<!-)$/i;
const domainRegex = /^(?!-)[a-z0-9-]{1,63}(?<!-)$/i;
const zoneRegex = /^[a-z]{2,63}$/i;

const validateUrl = (url?: string | null): boolean => {
  if (!url) return false;

  const parts = url.split(".");
  if (parts.length < 2) return false;

  const zone = parts.pop();
  const domain = parts.pop();
  const subdomains = parts;

  if (!zone || !zoneRegex.test(zone)) return false;
  if (!domain || !domainRegex.test(domain)) return false;

  for (const subdomain of subdomains) {
    if (!subdomainRegex.test(subdomain)) return false;
  }

  return true;
};

const StoreGeneralSettings = () => {
  const [t] = useTranslation();
  const context = useContext(StoreSettingsContext);
  const { store } = useStoreHook();
  const { icon, setIcon, url, setUrl } = context;

  const openShowDnsRecordsModal = useCallback(() => {
    if (!url) {
      return;
    }
    const props: ShowDnsRecordsModalProps = {
      url,
    };
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.ShowDnsRecordsModal,
        props,
      }),
    );
  }, [url]);

  return (
    <Grid container sx={{ padding: "10px" }}>
      <Grid item xs={12}>
        <MultilingualFieldWrapper supportedLanguages={store?.settings.supportedLanguages}>
          <SettingsStoreTitleField />
        </MultilingualFieldWrapper>
      </Grid>
      <Grid item xs={12}>
        <SubDomainField />
      </Grid>
      <Grid container item xs={12} justifyContent={"space-between"} spacing={1}>
        <Grid item xs={10}>
          <TextField
            margin="dense"
            key={Translations.MODAL_CREATE_STORE_FORM_FIELD_CUSTOM_DOMAIN}
            name="customURL"
            label={t(Translations.MODAL_CREATE_STORE_FORM_FIELD_CUSTOM_DOMAIN)}
            type="text"
            fullWidth
            variant="outlined"
            defaultValue={url}
            onChange={(_) => {
              setUrl(_.target.value);
            }}
          />
        </Grid>
        <Grid item xs={2} alignContent={"center"}>
          <LoadingButton color={"success"} disabled={!validateUrl(url)} onClick={openShowDnsRecordsModal}>
            {t(Translations.PAGE_PROJECT_STORE_SETTINGS_BUTTON_DNS)}
          </LoadingButton>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextField
          margin="dense"
          key={Translations.MODAL_CREATE_STORE_FORM_FIELD_ICON}
          name="icon"
          label={t(Translations.MODAL_CREATE_STORE_FORM_FIELD_ICON)}
          type="text"
          fullWidth
          variant="outlined"
          defaultValue={icon}
          onChange={(_) => {
            setIcon(_.target.value);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default StoreGeneralSettings;
