import { contextSetterImitator } from "constants/context";
import { type SupportedEncoding, type Language, type MultilingualString } from "dataLayer/types";
import { type Dispatch, type SetStateAction, createContext } from "react";
import { getCurrentTimeZone } from "utils/date";
import { getEmptyMultilingualString } from "utils/language";
import { type ISetMultilingualContentPayload } from "utils/multilingualUtils";

export interface ISetServerDetailsPayload {
  property: string;
  value: string | number | null;
}

export interface IStoreGeneralSettingsContextFields {
  storeTitle: MultilingualString;
  url: string | null;
  icon: string | null;
  subdomain: string | null;
}

export interface IStoreLocalizationSettingsContextFields {
  supportedLanguages: Language[];
  fallbackLanguage: Language | null;
  timeZone: string;
}

export interface IStoreServerRconSettingsContextFields {
  host: string | null;
  port: number | null;
  password: string | null;
  encoding: SupportedEncoding;
}

export type ServerDetails = IStoreServerRconSettingsContextFields;

export interface IStoreServerSettingsContextFields {
  serverDetails: ServerDetails;
}

export interface IStoreSettingsContextFields
  extends IStoreGeneralSettingsContextFields,
    IStoreLocalizationSettingsContextFields,
    IStoreServerSettingsContextFields {
  storeId: string;
}
export interface IStoreSettingsContext extends IStoreSettingsContextFields {
  storeType: string | null;
  setStoreTitle: Dispatch<ISetMultilingualContentPayload>;
  setUrl: Dispatch<SetStateAction<string | null>>;
  setIcon: Dispatch<SetStateAction<string | null>>;
  setSubdomain: Dispatch<SetStateAction<string | null>>;
  setSupportedLanguages: Dispatch<SetStateAction<Language[]>>;
  setFallbackLanguage: Dispatch<SetStateAction<Language | null>>;
  setTimeZone: Dispatch<SetStateAction<string>>;
  setServerDetails: Dispatch<ISetServerDetailsPayload>;
}

const StoreSettingsContext = createContext<IStoreSettingsContext>({
  storeId: "",
  storeType: null,
  storeTitle: getEmptyMultilingualString(),
  url: null,
  icon: null,
  subdomain: null,
  fallbackLanguage: null,
  serverDetails: {
    host: null,
    port: null,
    password: null,
    encoding: "utf8",
  },
  timeZone: getCurrentTimeZone(),
  supportedLanguages: [],
  setStoreTitle: contextSetterImitator,
  setUrl: contextSetterImitator,
  setIcon: contextSetterImitator,
  setSubdomain: contextSetterImitator,
  setSupportedLanguages: contextSetterImitator,
  setFallbackLanguage: contextSetterImitator,
  setTimeZone: contextSetterImitator,
  setServerDetails: contextSetterImitator,
});

export default StoreSettingsContext;
