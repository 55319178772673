import GridViewIcon from "@mui/icons-material/GridView";
import ViewListIcon from "@mui/icons-material/ViewList";
import { Button, Divider, Grid, IconButton, Paper } from "@mui/material";
import { useContext, type PropsWithChildren, useCallback } from "react";
import ProductsOverviewContext, { DisplayMode } from "../context/ProductsOverviewContext";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";
import { dispatch } from "store/hooks";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";
import useProjectStore from "pages/Dashboard/hooks/useProjectStore";

const ProductsWrapper = ({ children }: PropsWithChildren) => {
  const [t] = useTranslation();
  const { storeId } = useProjectStore();
  const { displayMode, setDisplayMode } = useContext(ProductsOverviewContext);

  const isListMode = displayMode === DisplayMode.LIST;

  const onGridIconClick = () => {
    setDisplayMode(DisplayMode.GRID);
  };

  const onListIconClick = () => {
    setDisplayMode(DisplayMode.LIST);
  };

  const onCreateClick = useCallback(() => {
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.StoreProductModal,
        props: {
          storeId,
        },
      }),
    );
  }, [storeId]);

  return (
    <Paper sx={{ p: "10px", mb: "10px" }}>
      <Grid container justifyContent={"space-between"}>
        <Grid item container justifyContent={"flex-start"} rowSpacing={2} xs={6}>
          <Grid item>
            <IconButton onClick={onListIconClick}>
              <ViewListIcon color={isListMode ? "primary" : "disabled"} />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton onClick={onGridIconClick}>
              <GridViewIcon color={isListMode ? "disabled" : "primary"} />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container item xs={6} justifyContent={"flex-end"}>
          <Button onClick={onCreateClick}>{t(Translations.PAGE_PROJECT_STORE_PRODUCTS_BUTTON_CREATE)}</Button>
        </Grid>
      </Grid>
      <Divider sx={{ p: "10px" }} />
      <Grid container>{children}</Grid>
    </Paper>
  );
};

export default ProductsWrapper;
