import { DialogContent, Grid, TextField, Typography } from "@mui/material";
import useCategoriesHook from "hooks/dashboardDataHooks/useCategoriesHook";
import useProductsHook from "hooks/dashboardDataHooks/useProductsHook";
import ProductSelectionFilter from "./components/fields/ProductSelectionFilter";
import { useCallback, useMemo, useState } from "react";
import { type DB_StoreCategoryDocument } from "dataLayer/types/storeCategoryEndpoints";
import ProductSelectionCard from "./components/ProductSelectionCard";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";
import { getMultilingualContent } from "utils/language";
import useLanguage from "hooks/useLanguage";

interface FormBodyProps {
  selectedItems: string[];
  onItemClick: (productId: string) => void;
}

const FormBody = ({ selectedItems, onItemClick }: FormBodyProps) => {
  const [t] = useTranslation();
  const { language } = useLanguage();
  const [selectedCategory, setSelectedCategory] = useState<DB_StoreCategoryDocument | null>(null);
  const { products, getProductsByIds } = useProductsHook();
  const { getCategoryById } = useCategoriesHook();
  const [searchValue, setSearchValue] = useState<string | null>(null);

  const onFilterChange = useCallback(
    (categoryId: string | null) => {
      setSelectedCategory(categoryId ? getCategoryById(categoryId) : null);
    },
    [getCategoryById],
  );

  const categorizedProducts = useMemo(() => {
    if (selectedCategory) {
      return getProductsByIds(selectedCategory.items);
    } else {
      return products;
    }
  }, [selectedCategory, getProductsByIds, products]);

  const filteredProducts = useMemo(() => {
    if (searchValue) {
      return categorizedProducts.filter((productItem) =>
        getMultilingualContent(language, productItem.title).toLowerCase().includes(searchValue.toLowerCase()),
      );
    }
    return categorizedProducts;
  }, [language, categorizedProducts, searchValue]);

  const selectedProducts = useMemo(() => getProductsByIds(selectedItems), [getProductsByIds, selectedItems]);

  return (
    <DialogContent dividers>
      <Grid container>
        <Grid item container xs={12} columnSpacing={1}>
          <Grid item xs={6} sx={{ marginBottom: "10px" }}>
            <ProductSelectionFilter categoryCallback={onFilterChange} />
          </Grid>
          <Grid item xs={6} sx={{ marginBottom: "10px" }}>
            <TextField
              fullWidth
              label={t(Translations.MODAL_SELECT_STORE_PRODUCT_FIELD_SEARCH)}
              onChange={(e) => {
                setSearchValue(e.target.value ?? null);
              }}
            />
          </Grid>
        </Grid>
        <Grid item container xs={6} rowSpacing={1} columnSpacing={1}>
          <Grid item xs={12}>
            <Typography>Selected</Typography>
          </Grid>
          {selectedProducts.map((productItem) => (
            <Grid item xs={2} key={productItem.id}>
              <ProductSelectionCard data={productItem} onClick={onItemClick} />
            </Grid>
          ))}
        </Grid>
        <Grid container item xs={6} rowSpacing={1} columnSpacing={1}>
          <Grid item xs={12}>
            <Typography>Products</Typography>
          </Grid>
          {filteredProducts.map((productItem) => (
            <Grid item xs={2} key={productItem.id}>
              <ProductSelectionCard
                data={productItem}
                onClick={onItemClick}
                isSelected={selectedItems.includes(productItem.id)}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default FormBody;
