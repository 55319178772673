import { createSelector } from "@reduxjs/toolkit";
import { type RootState } from "store/store";

const selector = (state: RootState) => state.dashboard;

const userProjects = createSelector([selector], (state) => state.userProjects);

export default {
  userProjects,
};
