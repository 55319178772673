import { useContext } from "react";
import StoreProductContext from "../../context/StoreProductContext";
import { Grid, Switch, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Translations } from "constants/translations";
import FieldPaper from "../../../../common/FieldPaper";

const ProductVisibilitySection = () => {
  const [t] = useTranslation();
  const { visibility, setVisibility } = useContext(StoreProductContext);
  return (
    <FieldPaper>
      <Grid container item xs={12} alignItems={"center"}>
        <Grid item xs={11}>
          <Typography>{t(Translations.MODAL_CREATE_PRODUCT_FIELD_VISIBLE)}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Switch
            value={visibility}
            onChange={(_, checked) => {
              setVisibility(checked);
            }}
          />
        </Grid>
      </Grid>
    </FieldPaper>
  );
};

export default ProductVisibilitySection;
