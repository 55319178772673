import { type GridProps, styled } from "@mui/material";

const EditorWrapper = styled("form")<GridProps>(({ theme, style }) => ({
  "& .quill": {
    borderRadius: 6,
    "& .ql-fill": {
      fill: theme.palette.common.white,
    },
    "& .ql-stroke": {
      stroke: theme.palette.common.white,
    },
    "& .ql-picker-label": {
      color: theme.palette.common.white,
    },
    "& .ql-picker-options": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.background.paper,
      borderColor: theme.palette.background.paper,
    },
    "& .ql-picker-options & .ql-selected": {
      color: theme.palette.warning.dark,
    },
    "& .ql-toolbar": {
      backgroundColor: theme.palette.background.paper,
      borderColor: theme.palette.background.paper,
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
    },
    "& .ql-container": {
      backgroundColor: theme.palette.grey.A700,
      borderColor: theme.palette.background.paper,
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 6,
      "& .ql-editor": {
        minHeight: 125,
        ...style,
        blockquote: {
          background: theme.palette.background.paper,
        },
      },
    },
    "& .ql-tooltip": {
      transform: "translateX(35%)",
    },
  },
}));

export default EditorWrapper;
