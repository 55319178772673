import { type TabulatedItemProps } from "components/common/TabulatedContent/types";
import { Translations } from "constants/translations";
import ProductPricingSection from "../form-sections/ProductPricingSection";
import TabulatedContent from "components/common/TabulatedContent";
import ProductLimitsSection from "../form-sections/ProductLimitsSection";
import ProductVisibilitySection from "../form-sections/ProductVisibilitySection";
import { Paper } from "@mui/material";
import ProductGiftingSection from "../form-sections/ProductGiftingSection";
import ProductUpSellingSection from "../form-sections/ProductUpSellingSection";
import ProductCommandsSection from "../form-sections/ProductCommandsSection";

const configs: TabulatedItemProps[] = [
  {
    label: Translations.MODAL_CREATE_PRODUCT_TAB_PRICING,
    children: <ProductPricingSection />,
  },
  {
    label: Translations.MODAL_CREATE_PRODUCT_TAB_COMMANDS,
    children: <ProductCommandsSection />,
  },
  {
    label: Translations.MODAL_CREATE_PRODUCT_TAB_LIMITS,
    children: <ProductLimitsSection />,
  },
  {
    label: Translations.MODAL_CREATE_PRODUCT_TAB_VISIBILITY,
    children: <ProductVisibilitySection />,
  },
  {
    label: Translations.MODAL_CREATE_PRODUCT_TAB_GIFTING,
    children: <ProductGiftingSection />,
  },
  {
    label: Translations.MODAL_CREATE_PRODUCT_TAB_UP_SELLING,
    children: <ProductUpSellingSection />,
  },
];

const StoreProductTabs = () => {
  return (
    <Paper>
      <TabulatedContent items={configs} differentiator={Translations.MODAL_CREATE_PRODUCT_TAB_DIFFERENTIATOR} />
    </Paper>
  );
};

export default StoreProductTabs;
