import PageAppBar from "components/Dashboard/PageAppBar";
import { useContext, useEffect, useMemo } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { dispatch, useAppSelector } from "store/hooks";
import { coreSelector } from "store/slices/core";
import ProtectedRoute from "components/common/ProtectedRoute";
import GlobalLoading from "components/GlobalLoading";
import DashboardDrawer from "components/Dashboard/DashboardDrawer";
import { Grid } from "@mui/material";
import useDashboardNavigationTitle from "pages/Dashboard/hooks/useDashboardNavigationTitle";
import DrawerContextProvider from "components/Dashboard/DashboardDrawer/context/DrawerContextProvider";
import { storeMiddleware, storeSelector } from "store/slices/store";
import DrawerContext from "components/Dashboard/DashboardDrawer/context/DrawerContext";

const DashboardContent = () => {
  const dashboardNavigationTitle = useDashboardNavigationTitle();
  const { drawerOpen } = useContext(DrawerContext);

  const difXS = useMemo(() => (drawerOpen ? 2 : 0), [drawerOpen]);

  return (
    <>
      <PageAppBar title={dashboardNavigationTitle} hasMenu={true} />
      <Grid container item xs={12}>
        <Grid item xs={1 + difXS}>
          <DashboardDrawer />
        </Grid>
        <Grid item xs={11 - difXS}>
          <Outlet />
        </Grid>
      </Grid>
    </>
  );
};

const DashboardLayout = () => {
  const { user, initialCheckComplete } = useAppSelector(coreSelector.authentication);
  const storeTypes = useAppSelector(storeSelector.storeTypes);

  const navigate = useNavigate();

  useEffect(() => {
    if (initialCheckComplete && !user?.id) {
      navigate("/home");
    }
  }, [user, initialCheckComplete, navigate]);

  useEffect(() => {
    if (!storeTypes) {
      dispatch(storeMiddleware.getStoreTypes());
    }
  }, [storeTypes]);

  return initialCheckComplete && user ? (
    <>
      <ProtectedRoute user={user} permissions={[]}>
        <DrawerContextProvider>
          <DashboardContent />
        </DrawerContextProvider>
      </ProtectedRoute>
    </>
  ) : (
    <>
      <GlobalLoading show />
    </>
  );
};

export default DashboardLayout;
