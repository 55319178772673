import { useCallback, useContext, useMemo } from "react";
import StoreProductContext from "../../context/StoreProductContext";
import { Translations } from "constants/translations";
import { t } from "i18next";
import { useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { convertProductsIntoOptions } from "utils/convertingUtils";
import PercentIcon from "@mui/icons-material/Percent";
import MultilingualFieldContext from "components/common/MultilingualFieldWrapper/context/MultilingualFieldContext";

const ProductAdditionalProductFields = () => {
  const { language } = useContext(MultilingualFieldContext);
  const { additionalProduct, setAdditionalProduct, productId } = useContext(StoreProductContext);
  const items = useAppSelector(storeSelector.products);

  const itemsArray = useMemo(() => items ?? [], [items]);

  const options = useMemo(
    () => convertProductsIntoOptions(language, itemsArray, productId),
    [language, itemsArray, productId],
  );

  const selectedOption = useMemo(
    () => options.find((option) => option.id === additionalProduct?.id) ?? null,
    [options, additionalProduct],
  );

  const updateAdditionalProduct = useCallback(
    (productId: string) => {
      setAdditionalProduct({ ...additionalProduct!, id: productId });
    },
    [additionalProduct, setAdditionalProduct],
  );

  const updateAdditionalProductDiscount = useCallback(
    (discount: string) => {
      setAdditionalProduct({ ...additionalProduct!, discount: +discount });
    },
    [additionalProduct, setAdditionalProduct],
  );

  return (
    <Grid container item xs={12}>
      <Grid item xs={6}>
        <Autocomplete
          options={options}
          getOptionLabel={(option) => option.title}
          sx={{ mt: 1 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t(Translations.MODAL_CREATE_PRODUCT_FIELD_ADDITIONAL_PRODUCT_SELECT)}
              variant="outlined"
            />
          )}
          value={selectedOption ?? null}
          onChange={(_, value) => {
            if (value) {
              updateAdditionalProduct(value.id);
            }
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          required
          margin="dense"
          key={Translations.MODAL_CREATE_PRODUCT_FIELD_ADDITIONAL_PRODUCT_DISCOUNT}
          name={Translations.MODAL_CREATE_PRODUCT_FIELD_ADDITIONAL_PRODUCT_DISCOUNT}
          label={t(Translations.MODAL_CREATE_PRODUCT_FIELD_ADDITIONAL_PRODUCT_DISCOUNT)}
          type="number"
          fullWidth
          variant="outlined"
          value={`${additionalProduct?.discount ?? 0}`}
          inputProps={{ step: 0.01 }}
          InputProps={{
            endAdornment: <PercentIcon />,
          }}
          sx={{
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
              display: "none",
            },
            "& input[type=number]": {
              MozAppearance: "textfield",
            },
          }}
          onChange={(_) => {
            updateAdditionalProductDiscount(_.target.value);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ProductAdditionalProductFields;
