import { contextSetterImitator } from "constants/context";
import { type Dispatch, type SetStateAction, createContext } from "react";

interface IDrawerContext {
  drawerOpen: boolean;
  fixedAsOpen: boolean;
  mouseOver: boolean;
  setFixedAsOpen: Dispatch<SetStateAction<boolean>>;
  setMouseOver: Dispatch<SetStateAction<boolean>>;
  toggleDrawer: Dispatch<SetStateAction<void>>;
}

const DrawerContext = createContext<IDrawerContext>({
  drawerOpen: true,
  fixedAsOpen: true,
  mouseOver: false,
  setFixedAsOpen: contextSetterImitator,
  setMouseOver: contextSetterImitator,
  toggleDrawer: contextSetterImitator,
});

export default DrawerContext;
