import { StorageKeys } from "constants/storageKeys";
import { type Language } from "dataLayer/types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getLocalStorageItem, setLocalStorageItem } from "utils/localStorage";

const useLanguage = () => {
  const { i18n } = useTranslation();
  const storageLanguage = getLocalStorageItem(StorageKeys.Language, "en");

  const language: Language = i18n.language as Language;

  useEffect(() => {
    if (storageLanguage && storageLanguage !== language) {
      i18n.changeLanguage(storageLanguage);
    }
  }, [storageLanguage, language, i18n]);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setLocalStorageItem(StorageKeys.Language, lng);
  };

  return {
    language,
    changeLanguage,
  };
};

export default useLanguage;
