import { Card, CardActions, Button, Grid, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { dispatch } from "store/hooks";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";

const ProjectStoreCardAdd = () => {
  const onAddStoreClick = () => {
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.StoreModal,
        props: null,
      }),
    );
  };

  return (
    <Card sx={{ maxWidth: 345, height: 180 }} variant="outlined">
      <CardActions>
        <Button sx={{ width: "100%", height: "100%" }} variant="text" onClick={onAddStoreClick}>
          <Grid container sx={{ py: "40px" }} justifyContent="center" alignContent="center">
            <Grid item sx={{ width: "100%" }}>
              <AddCircleOutlineIcon fontSize="large" />
            </Grid>
            <Grid item sx={{ width: "100%" }}>
              <Typography>Create Store</Typography>
            </Grid>
          </Grid>
        </Button>
      </CardActions>
    </Card>
  );
};

export default ProjectStoreCardAdd;
