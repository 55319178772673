import { LoadingButton } from "@mui/lab";
import { DialogActions } from "@mui/material";
import { Translations } from "constants/translations";
import { t } from "i18next";

interface FormActionsProps {
  onClose: () => void;
}
const FormActions = ({ onClose }: FormActionsProps) => {
  return (
    <DialogActions>
      <LoadingButton onClick={onClose}>{t(Translations.MODAL_PROFILE_DETAILS_BUTTON_CANCEL)}</LoadingButton>
      <LoadingButton type="submit">{t(Translations.MODAL_PROFILE_DETAILS_BUTTON_SAVE)}</LoadingButton>
    </DialogActions>
  );
};

export default FormActions;
