import { LoadingButton } from "@mui/lab";
import { DialogActions } from "@mui/material";
import { Translations } from "constants/translations";
import { useContext } from "react";
import { useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";
import StoreTagContext from "./context/StoreTagContext";
import { useTranslation } from "react-i18next";

interface FormActionsProps {
  onClose: () => void;
}
const FormActions = ({ onClose }: FormActionsProps) => {
  const [t] = useTranslation();
  const isLoading = useAppSelector(storeSelector.isTagLoading);
  const { tagId } = useContext(StoreTagContext);

  const submitLabel = tagId ? Translations.MODAL_EDIT_TAG_BUTTON_SUBMIT : Translations.MODAL_CREATE_TAG_BUTTON_SUBMIT;

  return (
    <DialogActions>
      <LoadingButton onClick={onClose} loading={isLoading}>
        {t(Translations.MODAL_CREATE_TAG_BUTTON_CANCEL)}
      </LoadingButton>
      <LoadingButton type="submit" loading={isLoading}>
        {t(submitLabel)}
      </LoadingButton>
    </DialogActions>
  );
};

export default FormActions;
