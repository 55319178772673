import { useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";

const useStoreHook = () => {
  const store = useAppSelector(storeSelector.store);

  return { store };
};

export default useStoreHook;
