import { type IStoreMeta, type IProjectMeta } from "store/types/dashboard";

export interface PortalProjectStoreResponse extends IStoreMeta {}

export interface PortalCreateProjectBody {
  title: string;
  icon: string | null;
}

export interface PortalGetProjectResponse extends IProjectMeta {
  id: string;
  title: string;
  icon: string | null;
  stores: PortalProjectStoreResponse[];
}

export enum ProjectPermission {
  OWNER = "OWNER",
  READ = "READ",
  EDIT = "EDIT",
  RENAME = "RENAME",
  DELETE = "DELETE",
  NONE = "NONE",
}
