import { Grid, Typography, Button } from "@mui/material";
import { Translations } from "constants/translations";
import { type Dispatch, type PropsWithChildren, type SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import FieldPaper from "./FieldPaper";
import CloseIcon from "@mui/icons-material/Close";

interface FieldToggleWrapperProps<T, N = null> extends PropsWithChildren {
  label: string;
  state: T | N;
  setState: Dispatch<SetStateAction<T | N>>;
  defaultEnabledState: T;
  defaultDisabledState: N;
}

const FieldToggleWrapper = <T, N = null>({
  state,
  setState,
  defaultEnabledState,
  defaultDisabledState,
  label,
  children,
}: FieldToggleWrapperProps<T, N>) => {
  const [t] = useTranslation();

  const onEnable = () => {
    setState(defaultEnabledState);
  };

  const onDisable = () => {
    setState(defaultDisabledState);
  };

  return (
    <FieldPaper>
      {state !== defaultDisabledState ? (
        <Grid container item xs={12} alignContent={"center"} justifyContent={"space-between"}>
          <Grid container item xs={11}>
            {children}
          </Grid>
          <Grid container item xs={1} justifyContent={"flex-end"}>
            <Button onClick={onDisable} variant={"outlined"} color="error">
              <CloseIcon color="error" />
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid container item xs={12} direction={"row"} rowSpacing={1} alignItems={"center"}>
          <Grid item xs={8} sx={{ pl: 1 }}>
            <Typography variant={"body1"}>{t(label)}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Button variant="outlined" onClick={onEnable} sx={{ width: "100%" }} color="success">
              {t(Translations.COMMON_NULLABLE_FIELD_WRAPPER_BUTTON_ENABLE)}
            </Button>
          </Grid>
        </Grid>
      )}
    </FieldPaper>
  );
};

export default FieldToggleWrapper;
