import { Box, Paper, Skeleton } from "@mui/material";

const StoreSettingsSkeleton = () => {
  return (
    <Box sx={{ mt: "10px" }}>
      <Paper sx={{ p: "10px" }}>
        <Box>
          <Skeleton variant="text" animation="wave" />
        </Box>
        <Box sx={{ mt: "10px" }}>
          <Skeleton variant="rectangular" animation="wave" height={"100px"} />
        </Box>
      </Paper>
    </Box>
  );
};

export default StoreSettingsSkeleton;
