import { useContext, useMemo } from "react";
import StoreProductContext from "../../context/StoreProductContext";
import { Translations } from "constants/translations";
import { t } from "i18next";
import { useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";
import { Autocomplete, TextField } from "@mui/material";
import { convertCategoriesIntoOptions } from "utils/convertingUtils";
import MultilingualFieldContext from "components/common/MultilingualFieldWrapper/context/MultilingualFieldContext";

const ProductCategoriesField = () => {
  const { language } = useContext(MultilingualFieldContext);
  const { categories, setCategories } = useContext(StoreProductContext);
  const items = useAppSelector(storeSelector.categories);

  const itemsArray = useMemo(() => items ?? [], [items]);

  const options = useMemo(() => convertCategoriesIntoOptions(language, itemsArray), [language, itemsArray]);

  const selectedOptions = useMemo(
    () => options.filter((option) => categories.includes(option.id)),
    [options, categories],
  );

  return (
    <Autocomplete
      multiple
      options={options}
      getOptionLabel={(option) => option.title}
      renderInput={(params) => (
        <TextField {...params} label={t(Translations.MODAL_CREATE_PRODUCT_FIELD_CATEGORIES)} variant="outlined" />
      )}
      value={selectedOptions}
      onChange={(_, values) => {
        setCategories(values.map((value) => value.id));
      }}
    />
  );
};

export default ProductCategoriesField;
