import { contextSetterImitator } from "constants/context";
import { type Dispatch, type SetStateAction, createContext } from "react";

export enum DisplayMode {
  LIST = "list",
  GRID = "grid",
}

interface IProductsOverviewContext {
  displayMode: DisplayMode;
  setDisplayMode: Dispatch<SetStateAction<DisplayMode>>;
}

const ProductsOverviewContext = createContext<IProductsOverviewContext>({
  displayMode: DisplayMode.LIST,
  setDisplayMode: contextSetterImitator,
});

export default ProductsOverviewContext;
