import React, { useState } from "react";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { CircularProgress, Grid, IconButton, Menu, MenuItem, type SxProps } from "@mui/material";
import { type IContextActionBinding, useContextMenuAction } from "hooks/useContextMenuAction";

interface ContextMenuProps {
  actionBindings: IContextActionBinding[];
  dataCy?: string;
  menuDataCy?: string;
  isLoading?: boolean;
  disabled?: boolean;
  sx?: SxProps;
}

export const ContextMenu = ({ actionBindings, isLoading, dataCy, menuDataCy, disabled, sx }: ContextMenuProps) => {
  const [anchorElement, setAnchorElement] = useState<Element | (() => Element) | null>(null);
  const cypressId = dataCy;
  const handleClick = (event: React.MouseEvent) => {
    setAnchorElement(event.currentTarget);
  };
  const handleClose = () => {
    if (!isLoading) {
      setAnchorElement(null);
    }
  };

  const actionCallback = useContextMenuAction(actionBindings);

  return (
    <Grid item>
      <IconButton
        data-cy={cypressId}
        size="small"
        onClick={handleClick}
        disabled={!actionBindings.length || disabled}
        sx={{
          ...(disabled && {
            opacity: 0.5,
          }),
        }}
      >
        <MoreVertOutlinedIcon aria-controls="menu-friend-card" aria-haspopup="true" sx={sx} />
      </IconButton>
      <Menu
        id="menu-simple-card"
        anchorEl={anchorElement}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleClose}
        variant="selectedMenu"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={sx}
        data-cy={menuDataCy}
      >
        {isLoading ? (
          <Grid item xs={1} p={12}>
            <CircularProgress size={20} />
          </Grid>
        ) : (
          actionBindings?.map((actionItem) => (
            <MenuItem
              onClick={() => {
                handleClose();
                actionCallback(actionItem.id);
              }}
              key={actionItem.id}
            >
              {actionItem.title}
            </MenuItem>
          ))
        )}
      </Menu>
    </Grid>
  );
};
