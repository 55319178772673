import { useMemo } from "react";
import DrawerStoreType from "../components/DrawerStoreType";
import useProject from "pages/Dashboard/hooks/useProject";
import useProjectStore from "pages/Dashboard/hooks/useProjectStore";
import NavigationListWrapper from "./NavigationListWrapper";
import { Divider } from "@mui/material";
import { useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";

const ProjectNavigation = () => {
  const { PROJECT } = useProject();
  const { STORE } = useProjectStore();

  const storeTypes = useAppSelector(storeSelector.storeTypes);

  const stores = useMemo(() => {
    if (!PROJECT) {
      return [];
    }
    return PROJECT.stores;
  }, [PROJECT]);

  const storeTypesKeys = useMemo(() => {
    return Array.from(new Set(stores.map((store) => store.storeType)));
  }, [stores]);

  const storeTypesToRender = storeTypes?.filter((storeType) => storeTypesKeys.includes(storeType.key)) ?? [];

  return PROJECT && !STORE ? (
    <NavigationListWrapper>
      <Divider />
      {storeTypesToRender.map((storeType) => (
        <DrawerStoreType
          key={storeType.id}
          storeType={storeType}
          stores={stores.filter((store) => store.storeType === storeType.key)}
        />
      ))}
    </NavigationListWrapper>
  ) : null;
};

export default ProjectNavigation;
