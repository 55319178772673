import { Box, Tabs, Tab, useTheme } from "@mui/material";
import { type PropsWithChildren, type SyntheticEvent, useContext, useMemo } from "react";
import MultilingualFieldContext from "./context/MultilingualFieldContext";
import { type Language, type IUnique } from "dataLayer/types";
import { useTranslation } from "react-i18next";

interface MultilingualFieldWrapperTabsProps extends PropsWithChildren {
  tabs: IUnique[];
}

const MultilingualFieldWrapperTabs = ({ children, tabs }: MultilingualFieldWrapperTabsProps) => {
  const [t] = useTranslation();
  const { language, setLanguage } = useContext(MultilingualFieldContext);
  const theme = useTheme();
  const languageExists = useMemo(() => tabs.some((tab) => tab.id === language), [tabs, language]);

  return (
    <Box
      sx={{
        background: theme.palette.background.paper,
        borderRadius: "4px",
        mt: "10px",
      }}
    >
      <Tabs
        value={languageExists ? language : false}
        onChange={(event: SyntheticEvent, newValue: Language) => {
          setLanguage(newValue);
        }}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="language tabs"
      >
        {tabs.map((tab) => (
          <Tab label={t(tab.title)} value={tab.id} key={tab.id} />
        ))}
      </Tabs>
      <Box sx={{ p: "10px" }}>{children}</Box>
    </Box>
  );
};

export default MultilingualFieldWrapperTabs;
